import { Maybe } from "../../utils/__generated__/graphql"

interface OutstandingOrdersProps {
  displayedUnit: string
  quantity: Maybe<number> | undefined
  isPromo: boolean
}

const OutstandingOrders = ({
  displayedUnit,
  quantity,
  isPromo,
}: OutstandingOrdersProps) => {
  return (
    <p
      className={`lg:w-11 flex flex-col gap-1 ${isPromo ? "text-custom-cyan uppercase" : "text-zinc-500"}`}
    >
      <span className="text-xxs font-normal whitespace-nowrap">
        {isPromo ? "PROMO" : "En cours"}
      </span>
      <span className="text-xs font-black flex gap-x-0.5 justify-center lg:justify-start">
        <span>+</span>
        <span>
          {new Intl.NumberFormat("fr-FR", { maximumFractionDigits: 1 }).format(
            (quantity ?? 0) > 0 ? (quantity ?? 0) : 0,
          )}
        </span>
        <span className="lowercase inline">{displayedUnit}</span>
      </span>
    </p>
  )
}

export default OutstandingOrders
