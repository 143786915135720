import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { DispatchActionType, StateType } from "@/types"
import { useResetOrderQuantityMutation } from "@/utils/__generated__/graphql"
import { getOrderQuantity } from "@/utils/getOrderQuantity"
import { captureException } from "@sentry/react"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "sonner"

interface UseResetPredictionParams {
  reference: MercurialReducerState["mercurialAndStoreInventories"][number]
  disabled?: boolean
}

interface UseResetPredictionReturn {
  isDisabled: boolean
  reset: () => Promise<void>
}

export function useResetPrediction({
  reference,
  disabled,
}: UseResetPredictionParams): UseResetPredictionReturn {
  const dispatch = useDispatch<DispatchActionType>()
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId === reference.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [resetOrderQuantityMutation] = useResetOrderQuantityMutation()

  const isOrderValid = useMemo(() => {
    const orderQuantity = getOrderQuantity({
      backQuantity: reference?.back_inventory_qty ?? 0,
      floorQuantity: reference?.floor_inventory_qty ?? 0,
      predictedQuantityArray: reference?.quantity_predicted_array ?? [],
    })

    const hasValidQuantity = orderQuantity >= 0
    const isQuantityDifferent = reference?.quantity_actual !== orderQuantity
    const hasPredictedArray = reference?.quantity_predicted_array !== null

    return hasValidQuantity && isQuantityDifferent && hasPredictedArray
  }, [
    reference?.back_inventory_qty,
    reference?.floor_inventory_qty,
    reference?.quantity_actual,
    reference?.quantity_predicted_array,
  ])

  const isDisabled = useMemo(() => {
    const hasMercurialeId = typeof reference?.mercuriale_id === "string"
    const hasDimOrderRequestId = typeof dimOrderRequestId === "string"
    if (!hasMercurialeId || !hasDimOrderRequestId || !online || disabled) {
      return true
    }
    if (isOrderValid) {
      return false
    }
    return true
  }, [
    dimOrderRequestId,
    disabled,
    isOrderValid,
    online,
    reference?.mercuriale_id,
  ])

  const reset = useCallback(async (): Promise<void> => {
    if (
      typeof reference?.mercuriale_id !== "string" ||
      typeof dimOrderRequestId !== "string" ||
      typeof storeId !== "string"
    )
      return
    const orderQuantity = getOrderQuantity({
      backQuantity: reference?.back_inventory_qty ?? 0,
      floorQuantity: reference?.floor_inventory_qty ?? 0,
      predictedQuantityArray: reference?.quantity_predicted_array ?? [],
    })

    const updatedReferencePayload = {
      mercurialeId: reference.mercuriale_id,
      orderInventoryQuantity: orderQuantity,
      isOrderInventoryQuantityUpdated: false,
    }

    dispatch({
      type: "updateReference",
      payload: updatedReferencePayload,
    })
    dispatch({
      type: "setSelectedInventory",
      payload: {
        type: undefined,
        mercurialeId: undefined,
      },
    })

    if (enable) return

    try {
      const resetOrderQuantityResult = await resetOrderQuantityMutation({
        variables: {
          input: {
            dim_order_request_id: dimOrderRequestId,
            store_id: storeId,
            mercuriale_id: reference.mercuriale_id,
          },
        },
      })
      if (resetOrderQuantityResult.data?.resetOrderQuantity?.error !== null) {
        captureException(
          resetOrderQuantityResult.data?.resetOrderQuantity?.error,
        )
        return
      }
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }, [
    reference?.back_inventory_qty,
    reference?.floor_inventory_qty,
    reference.mercuriale_id,
    reference?.quantity_predicted_array,
    dimOrderRequestId,
    dispatch,
    enable,
    resetOrderQuantityMutation,
    storeId,
  ])

  return {
    isDisabled,
    reset,
  }
}
