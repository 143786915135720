import { Dialog, DialogContent } from "@/components/ui/dialog"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"

import { CheckoutModalContent } from "./CheckoutModal/CheckoutModal"

interface OrderCorrectionModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  handleConfirm: () => void
  tooLowProducts: AllMercurialInfo[]
  excessiveProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
  boxProducts: AllMercurialInfo[]
}

export function OrderCorrectionModal({
  open,
  setOpen,
  handleConfirm,
  tooLowProducts,
  excessiveProducts,
  allItems,
  boxProducts,
}: OrderCorrectionModalProps) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex flex-col h-screen overflow-hidden md:max-w-screen-xl md:h-[90vh]">
        <CheckoutModalContent
          allItems={allItems}
          boxProducts={boxProducts}
          tooLowProducts={tooLowProducts}
          excessiveProducts={excessiveProducts}
          handleClose={() => {
            setOpen(false)
          }}
          handleConfirm={handleConfirm}
        />
      </DialogContent>
    </Dialog>
  )
}
