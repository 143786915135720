import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { DispatchActionType, StateType } from "@/types"
import { useGetLastWeeksPerformancesQuery } from "@/utils/__generated__/graphql"
import { isNewWeek } from "@/utils/isNewWeek"
import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LastWeeksPerformances } from "./LastWeeksPerformances"
import { X } from "lucide-react"

interface LastWeeksPerformancesModalProps {
  isLoading: boolean
}

export function LastWeeksPerformancesModal({
  isLoading,
}: LastWeeksPerformancesModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const lastPerformancesModalCloseDate = useSelector(
    (state: StateType) => state.mercurialReducer.lastPerformancesModalCloseDate,
  )

  const { data, loading } = useGetLastWeeksPerformancesQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })

  const hasData = useMemo(() => {
    const hasBreakagesData =
      (data?.lastBreakages.one_week_ago ?? 0) > 0 ||
      (data?.lastBreakages.two_weeks_ago ?? 0) > 0 ||
      (data?.lastBreakages.one_year_ago ?? 0) > 0

    const hasRevenueData =
      (data?.lastRevenue.one_week_ago ?? 0) > 0 ||
      (data?.lastRevenue.two_weeks_ago ?? 0) > 0 ||
      (data?.lastRevenue.one_year_ago ?? 0) > 0

    return hasBreakagesData && hasRevenueData
  }, [
    data?.lastBreakages.one_week_ago,
    data?.lastBreakages.one_year_ago,
    data?.lastBreakages.two_weeks_ago,
    data?.lastRevenue.one_week_ago,
    data?.lastRevenue.one_year_ago,
    data?.lastRevenue.two_weeks_ago,
  ])

  const isModalOpen = useMemo(() => {
    const hasValidDate =
      lastPerformancesModalCloseDate === undefined ||
      isNewWeek(new Date(lastPerformancesModalCloseDate))

    return !isLoading && !loading && hasValidDate && hasData
  }, [hasData, isLoading, lastPerformancesModalCloseDate, loading])

  const [checkboxesState, setCheckboxesState] = useState<boolean[]>([
    false,
    false,
  ])

  return (
    <AlertDialog open={isModalOpen}>
      <AlertDialogContent className="bg-gray-100 flex flex-col gap-4 p-4 overflow-y-auto h-fit max-h-full">
        <button
          className="self-end"
          onClick={() =>
            dispatch({
              type: "closeLastPerformancesModal",
              payload: new Date(),
            })
          }
        >
          <X className="size-5" />
        </button>
        <AlertDialogTitle hidden>Performances passées</AlertDialogTitle>
        <AlertDialogDescription hidden>
          Démarque et chiffre d&apos;affaires de la semaine passée.
        </AlertDialogDescription>
        <LastWeeksPerformances
          title="Démarque"
          description="La démarque"
          oneWeekAgo={data?.lastBreakages.one_week_ago ?? 0}
          twoWeeksAgo={data?.lastBreakages.two_weeks_ago ?? 0}
          oneYearAgo={data?.lastBreakages.one_year_ago ?? 0}
          currency={storeCurrency ?? "EUR"}
          checked={checkboxesState[0]}
          onCheckedChange={(value) => {
            setCheckboxesState((prev) => [value, prev[1]])
          }}
        />
        <LastWeeksPerformances
          title="Chiffre d'affaires"
          description="Le chiffre d'affaires"
          oneWeekAgo={data?.lastRevenue.one_week_ago ?? 0}
          twoWeeksAgo={data?.lastRevenue.two_weeks_ago ?? 0}
          oneYearAgo={data?.lastRevenue.one_year_ago ?? 0}
          currency={storeCurrency ?? "EUR"}
          checked={checkboxesState[1]}
          onCheckedChange={(value) => {
            setCheckboxesState((prev) => [prev[0], value])
          }}
          invertColors
        />
        <AlertDialogFooter>
          <AlertDialogAction
            className="w-full"
            disabled={checkboxesState.some((checkbox) => checkbox === false)}
            onClick={() => {
              dispatch({
                type: "closeLastPerformancesModal",
                payload: new Date(),
              })
            }}
          >
            Cochez pour continuer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
