import { GetQuarterValueResult } from "@/utils/getQuarterValue"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreReducerState } from "@/reducers/storeReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import { InventoryInput } from "./InventoryInput"
import { InventoryType } from "@/utils/__generated__/graphql"
import { useMemo } from "react"
import { ResetPredictionPopover } from "../ResetPrediction/ResetPredictionPopover"
import { usePermissions } from "@/hooks/usePermissions"

interface InventoriesInputsMobileLayoutProps {
  shelfFloorSizeQuarterValue: GetQuarterValueResult
  reference: Partial<AllMercurialInfo>
  referenceIndex: number
  storeSettings: StoreReducerState["storeSettings"]
  isOrderDisabled: boolean
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  backInventoryEquivalent: number
  floorInventoryEquivalent: number
  shelfFloorSizeEquivalent: number
  hasBigBreakage: boolean
  isOnline: boolean
}

export function InventoriesInputsMobileLayout({
  shelfFloorSizeQuarterValue,
  reference,
  referenceIndex,
  storeSettings,
  backInventoryEquivalent,
  floorInventoryEquivalent,
  updateInventory,
  shelfFloorSizeEquivalent,
  isOrderDisabled,
  hasBigBreakage,
  isOnline,
}: InventoriesInputsMobileLayoutProps) {
  const backInventoryQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? backInventoryEquivalent / (reference.colisage ?? 1)
        : (reference.back_inventory_qty ?? 0),
    [
      backInventoryEquivalent,
      reference.back_inventory_qty,
      reference.colisage,
      referenceIndex,
    ],
  )
  const floorInventoryQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? floorInventoryEquivalent / (reference.colisage ?? 1)
        : (reference.floor_inventory_qty ?? 0),
    [
      floorInventoryEquivalent,
      reference.floor_inventory_qty,
      reference.colisage,
      referenceIndex,
    ],
  )
  const shelfFloorSizeQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? shelfFloorSizeEquivalent / (reference.colisage ?? 1)
        : (reference.shelf_floor_size ?? null),
    [
      shelfFloorSizeEquivalent,
      reference.shelf_floor_size,
      reference.colisage,
      referenceIndex,
    ],
  )

  const { canUpdateOrderQuantity } = usePermissions()
  const hasPredictions =
    reference.quantity_predicted_array &&
    reference.quantity_predicted_array!.length > 0

  const isUpdateOrderDisabled =
    (isOrderDisabled || (hasPredictions && !canUpdateOrderQuantity)) ?? false

  return (
    <div className="flex flex-col">
      <div className="flex">
        <p className="flex-1 text-xs text-zinc-500 font-bold text-center">
          RÉSERVE
        </p>
        <p className="flex-1 text-xs text-zinc-500 font-bold flex justify-center items-center">
          CAPA.RAYON
          <span className="ml-1 bg-gray-900 text-white rounded-full h-4 px-1 text-xxs flex justify-center items-center">
            {((shelfFloorSizeQuarterValue.value ?? 0) > 0 ||
              shelfFloorSizeQuarterValue.icon === undefined) &&
              shelfFloorSizeQuarterValue.value}
            {shelfFloorSizeQuarterValue.icon}
          </span>
        </p>
        <div className="flex-1">
          <ResetPredictionPopover
            reference={reference}
            disabled={isOrderDisabled || !isOnline}
            storeSettings={storeSettings}
            hasBigBreakage={hasBigBreakage}
          />
          <p className="text-xs text-zinc-500 font-bold text-center">
            COMMANDE
          </p>
        </div>
      </div>
      <div className="p-2 flex items-start gap-2 md:border md:bg-gray-100 rounded relative">
        <InventoryInput
          name={InventoryType.Back}
          stock_too_high_flag={reference.stock_too_high_flag}
          stock_too_low_flag={reference.stock_too_low_flag}
          stock_to_verify_flag={reference.stock_to_verify_flag}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
          promoWaitedQuantityOrdered={
            reference.promo_waited_quantity_ordered ?? 0
          }
          value={backInventoryQuantity}
          floorInventoryQuantity={floorInventoryQuantity}
          updateInventory={updateInventory}
        />
        <InventoryInput
          name={InventoryType.Shelf}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          isNewReference={reference.new_reference ?? false}
          value={shelfFloorSizeQuantity}
          floorInventoryQuantity={floorInventoryQuantity}
          updateInventory={updateInventory}
        />
        <InventoryInput
          name={InventoryType.Order}
          prediction_uncertain={reference.prediction_uncertain}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          value={reference?.quantity_actual ?? 0}
          updateInventory={updateInventory}
          disabled={isUpdateOrderDisabled}
          isPrecommande={reference.is_precommande ?? false}
          waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
          promoWaitedQuantityOrdered={
            reference.promo_waited_quantity_ordered ?? 0
          }
        />
      </div>
    </div>
  )
}
