import { Navigate, createBrowserRouter } from "react-router-dom"
import Root from "./Root"
import NotFound from "../pages/NotFound"
import InventoryPage from "../pages/InventoryPage"
import CalendarPage from "../pages/CalendarPage"
import RecapPage from "../pages/recapPage/RecapPage"
import SettingsPage from "../pages/SettingsPage"
import Account from "../pages/Account"
import { Admin } from "../pages/admin"
import { StoresPage } from "../pages/admin/StoresPage"
import { CompaniesPage } from "../pages/admin/CompaniesPage"
import { RedirectToStorePage } from "../pages/admin/RedirectToStorePage"
import PermissionsProtectedRoute from "./PermissionsProtectedRoute"
import CallbackRoute from "./CallbackRoute"
import { LogoutPage } from "../pages/LogoutPage"
import { CategoriesPage } from "../pages/CategoriesPage"
import { InventoryRoot } from "../components/inventory/InventoryRoot"
import { RedirectToPage } from "../pages/RedirectToPage"
import { MatchingPage } from "../pages/MatchingPage"
import { StoreScheduler } from "../pages/admin/StoreScheduler"
import UserPage from "@/pages/UserPage"
import { EngagementPromoPage } from "@/pages/engagementPromoPage/EngagementPromoPage"
import { PerformancesPage } from "@/pages/admin/PerformancesPage/PerformancesPage"

const router = createBrowserRouter([
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "callback",
    element: <CallbackRoute />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <RedirectToPage to="/inventory" />,
      },
      {
        path: "/recap",
        element: <RedirectToPage to="/inventory/recap" />,
      },
      {
        path: "inventory",
        element: <InventoryRoot />,
        children: [
          {
            path: "",
            element: <InventoryPage />,
          },
          {
            path: "recap",
            element: <RecapPage />,
          },
        ],
      },
      {
        path: "matching",
        element: <MatchingPage />,
      },
      {
        path: "engagement-promo",
        element: <EngagementPromoPage />,
      },
      {
        path: "users",
        element: <UserPage />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            path: "",
            element: <Navigate to="calendar" />,
          },
          {
            path: "calendar",
            element: <CalendarPage />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
          {
            path: "categories",
            element: <CategoriesPage />,
          },
          {
            path: "admin",
            element: (
              <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
                <Admin />
              </PermissionsProtectedRoute>
            ),
            children: [
              {
                path: "",
                element: <Navigate to="companies" />,
              },
              {
                path: "companies",
                children: [
                  {
                    path: ":companyId",
                    element: <StoresPage />,
                  },
                  {
                    path: ":companyId/store-scheduler",
                    element: <StoreScheduler />,
                  },
                  {
                    path: ":companyId/performances",
                    element: <PerformancesPage />,
                  },
                  {
                    path: "",
                    element: <CompaniesPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "stores/:storeId",
        element: (
          <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
            <RedirectToStorePage />
          </PermissionsProtectedRoute>
        ),
      },
    ],
  },
])

export default router
