import { WifiAlertIcon } from "@/ui/AlertIcons"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"

interface OfflineDataModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  isValidOrderDate: boolean
}

export function OfflineDataModal({
  isOpen,
  setIsOpen,
  isValidOrderDate,
}: OfflineDataModalProps) {
  return (
    <Modal
      icon={<WifiAlertIcon />}
      title="Vous n'êtes pas connecté à internet."
      open={isOpen}
      onClose={() => {
        if (!isValidOrderDate) return
        setIsOpen(false)
      }}
      hideCloseButton
    >
      <p className="text-[#667085] text-sm font-normal text-center">
        Veuillez vous connecter à internet pour mettre à jour les données de
        l&apos;application. Si vous gardez cette fenêtre ouverte, les données
        seront récupérées automatiquement une fois la connexion rétablie.
      </p>
      {isValidOrderDate && (
        <Button
          onClick={() => setIsOpen(false)}
          className="w-full h-11 text-base mt-4 md:mt-8"
          color="ghost"
        >
          Annuler
        </Button>
      )}
    </Modal>
  )
}
