interface SelectorLabelProps {
  text?: string
  value?: number
  icon?: JSX.Element
  unit?: string
  displayZero?: boolean
}

export function SelectorLabel({
  text,
  value,
  icon,
  unit,
  displayZero = false,
}: SelectorLabelProps) {
  const displayedValue =
    value !== undefined && (value >= 1 || displayZero) && value

  return (
    <span className="flex items-center">
      {text}{" "}
      <span className="ml-1 font-medium inline-flex">
        {displayedValue}
        {icon} {unit}
      </span>
    </span>
  )
}
