import { useDispatch, useSelector } from "react-redux"
import TotalDisplay from "./totalIsland"
import { GroupedVirtuoso } from "react-virtuoso"
import { useMemo, useRef, useState } from "react"
import { HeaderGroup } from "./headerGroup"
import { TableRow } from "@/components/ui/table"
import { ArrowLeftIcon } from "@/ui/icons/ArrowLeftIcon"
import { useWindowSize } from "@/hooks/useWindowSize"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { GetOrderItem, StoreSettings } from "@/utils/__generated__/graphql"
import { DispatchActionType, StateType } from "@/types"
import { RecapSortOption } from "@/reducers/userInterfaceReducer"
import { Button } from "@/components/ui/button"
import { alphabeticalSort, numericalSort } from "@/utils/sort"
import SearchBar from "./searchBar"
import SortIcon from "./sortIcon"
import { captureException } from "logrocket"
import {
  InventoryType,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { computeModificationObject } from "@/utils/sendLogsModificationUpdate"
import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { DesktopTableRow } from "./desktopTableRow"
import { MobileTableRow } from "./mobileTableRow"

export interface GroupedReferences {
  supplierName: string
  supplierId: string
  products: (AllMercurialInfo | GetOrderItem)[]
}

interface TableProps {
  references: (AllMercurialInfo | GetOrderItem)[]
  excessiveProducts: (AllMercurialInfo | GetOrderItem)[]
  tooLowProducts: (AllMercurialInfo | GetOrderItem)[]
  handleValidateOrderPreview: () => void
  isOrderConfirmed: boolean
  orderId: string | null
  enable: boolean
}

const Table = ({
  references,
  excessiveProducts,
  tooLowProducts,
  handleValidateOrderPreview,
  isOrderConfirmed,
  orderId,
  enable,
}: TableProps) => {
  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const [deletedProducts, setDeletedProducts] = useState<string[]>([])
  const dispatch = useDispatch<DispatchActionType>()

  const { storeCurrency, storeSettings, storeStoreSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { sortOption, sortOrder, searchTerm } = useSelector(
    (state: StateType) => state.userInterfaceReducer.recapPage,
  )

  const oldSortState = useRef<{
    list: string[]
    option: RecapSortOption
    order: "asc" | "desc"
  } | null>(null)

  const columns = useMemo(() => {
    return [
      {
        id: RecapSortOption.Colisage,
        name: "Colisage",
        nameInMobile: "Colisage",
      },
      {
        id: RecapSortOption.PV,
        name: "Prix de vente (TTC)",
        nameInMobile: "PV",
      },
      {
        id: RecapSortOption.QuantityActual,
        name: "Colis commandés",
        nameInMobile: "Colis",
      },
      {
        id: RecapSortOption.PA,
        name: "Coût total (HT)",
        nameInMobile: "PA",
      },
      {
        id: RecapSortOption.PotentialRevenue,
        name: "CA potentiel (TTC)",
        nameInMobile: "CA",
      },
    ]
  }, [])

  const handleSortChange = (columnName: (typeof columns)[number]["id"]) => {
    if (columnName === sortOption) {
      dispatch({
        type: "setSortOption",
        payload: {
          page: "recapPage",
          sortOption: columnName,
          sortOrder: sortOrder === "asc" ? "desc" : "asc",
        },
      })
      return
    }
    dispatch({
      type: "setSortOption",
      payload: {
        page: "recapPage",
        sortOption: columnName,
        sortOrder: "asc",
      },
    })
  }

  const FilterButton = ({
    id,
    name,
    nameInMobile,
  }: (typeof columns)[number]) => {
    const hideInMobile = ["pv"].includes(id)

    return (
      <Button
        onClick={() => handleSortChange(id)}
        variant="outline"
        className={`${
          hideInMobile ? "hidden lg:block" : ""
        } w-full border-m border-solid text-xs xl:text-sm font-medium text-gray-500 uppercase tracking-wider h-11 ${
          sortOption === id ? "border-green-500" : "border-gray-300"
        }`}
      >
        <div className="flex justify-center items-center h-full md:gap lg:gap-4">
          <span className="text-center">
            <p className="hidden md:block truncate text-xxs">{name}</p>
            <p className="block md:hidden truncate text-xxs">{nameInMobile}</p>
          </span>

          <span className="cursor-pointer lg:block">
            <SortIcon
              column={id}
              sortedColumn={sortOption}
              sortOrder={sortOrder}
            />
          </span>
        </div>
      </Button>
    )
  }

  // Filter and sort logic
  const filteredReferences = useMemo<Partial<AllMercurialInfo>[]>(() => {
    const _references = [...references]

    // Filter out deleted products
    const activeReferences = _references.filter(
      (reference) => !deletedProducts.includes(reference.mercuriale_id ?? ""),
    )

    const computeQuantity = (item: Partial<AllMercurialInfo>) =>
      storeSettings?.use_kg_pce === true
        ? (item.quantity_actual ?? 0) / (item.colisage ?? 1)
        : (item.quantity_actual ?? 0)

    const getSortValue = (
      item: Partial<AllMercurialInfo>,
      option: RecapSortOption | null,
    ) => {
      if (!option) return 0
      switch (option) {
        case RecapSortOption.MercurialeName:
          return item.mercuriale_name ?? ""
        case RecapSortOption.Colisage:
          return item.colisage ?? 1
        case RecapSortOption.PV:
          return item.pv ?? 0
        case RecapSortOption.QuantityActual:
          return computeQuantity(item)
        case RecapSortOption.PA:
          return computeQuantity(item) * (item.pa ?? 0)
        case RecapSortOption.PotentialRevenue:
          return computeQuantity(item) * (item.pv ?? 0)
        default:
          return computeQuantity(item)
      }
    }

    if (
      oldSortState.current?.option === sortOption &&
      oldSortState.current?.order === sortOrder &&
      oldSortState.current?.list
    ) {
      return oldSortState.current.list
        .map((mercurialeId) =>
          activeReferences.find((item) => item.mercuriale_id === mercurialeId),
        )
        .filter(
          (item): item is AllMercurialInfo | GetOrderItem => item !== undefined,
        )
        .filter(
          (item) =>
            searchTerm === "" ||
            item.mercuriale_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()),
        )
    }

    if (activeReferences.length > 0) {
      const sortedReferences = activeReferences.sort((a, b) => {
        const valueA = getSortValue(a, sortOption)
        const valueB = getSortValue(b, sortOption)

        if (typeof valueA === "string" && typeof valueB === "string") {
          return alphabeticalSort(valueA, valueB, sortOrder)
        }
        return numericalSort(valueA as number, valueB as number, sortOrder)
      })

      oldSortState.current = {
        option: sortOption ?? RecapSortOption.QuantityActual,
        order: sortOrder,
        list: sortedReferences.map((item) => item.mercuriale_id ?? ""),
      }

      return sortedReferences.filter(
        (item) =>
          searchTerm === "" ||
          item.mercuriale_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()),
      )
    }

    return []
  }, [
    references,
    deletedProducts,
    searchTerm,
    sortOption,
    sortOrder,
    storeSettings?.use_kg_pce,
  ])

  const suppliersData = useMemo(() => {
    return filteredReferences.reduce<GroupedReferences[]>(
      (suppliersData, reference) => {
        const supplierDataIndex = suppliersData.findIndex(
          (supplierData) =>
            supplierData.supplierName === reference.supplier_name,
        )
        if (supplierDataIndex === -1) {
          suppliersData.push({
            supplierName: reference.supplier_name ?? "",
            supplierId: reference.supplier_id ?? "",
            products: [reference],
          })
          return suppliersData
        }
        suppliersData[supplierDataIndex].products.push(reference)
        return suppliersData
      },
      [],
    )
  }, [filteredReferences])

  const groupCounts = useMemo(() => {
    return suppliersData.map(({ products }) => products.length)
  }, [suppliersData])

  const getLocalIndex = (
    index: number,
    groupIndex: number,
    groupCounts: number[],
  ) => {
    return index - groupCounts.slice(0, groupIndex).reduce((a, c) => a + c, 0)
  }

  const calculateQuantityActualInUnit = (
    product: AllMercurialInfo | GetOrderItem,
    storeSettings: StoreSettings | null,
  ) => {
    return storeSettings?.use_kg_pce
      ? (product.quantity_actual ?? 0)
      : (product.quantity_actual ?? 0) * (product.colisage ?? 1)
  }

  const handleBack = () => {
    window.history.back()
  }

  const { isMD } = useWindowSize()
  const timeouts: Record<string, NodeJS.Timeout> = {}

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const dimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) => dimMercuriale.dimMercurialeId === dimMercurialeId,
  )?.dimOrderRequestId

  const setQuantityTo0 = async (product: AllMercurialInfo | GetOrderItem) => {
    if (!("mercuriale_id" in product) || !("order_id" in product)) return
    if (typeof product.mercuriale_id !== "string") return

    const newQuantity = 0

    dispatch({
      type: "updateReference",
      payload: {
        mercurialeId: product.mercuriale_id ?? "",
        orderInventoryQuantity: newQuantity,
        isOrderInventoryQuantityUpdated: true,
      },
    })

    clearTimeout(timeouts[product.mercuriale_id ?? ""])

    timeouts[product.mercuriale_id ?? ""] = setTimeout(async () => {
      const modification = computeModificationObject({
        inventoryType: InventoryType.Order,
        mercurialeInfo: product,
        modifiedValue: newQuantity,
      })

      try {
        const batchUpdateResult = await batchUpdateMutation({
          variables: {
            input: {
              batch_data: [
                {
                  order_id: product.order_id,
                  mercuriale_id: product.mercuriale_id!,
                  quantity_actual: newQuantity,
                  back_inventory: null,
                  floor_inventory: null,
                  shelf_floor_size: null,
                  colisage: product.colisage,
                  dim_mercuriale_id: dimMercurialeId,
                  sale_id: null,
                },
              ],
              dim_order_request_id: dimOrderRequestId,
              store_id: storeId ?? "",
              inventory_type: InventoryType.Order,
            },
          },
        })
        if (batchUpdateResult.data?.batchUpdate?.error) {
          captureException(
            new Error(batchUpdateResult.data?.batchUpdate?.error.message),
          )
          return
        }
        if (
          batchUpdateResult.data?.batchUpdate?.dim_order_request_id !== null &&
          typeof batchUpdateResult.data?.batchUpdate.dim_order_request_id ===
            "string"
        ) {
          dispatch({
            type: "setDimOrderRequestId",
            payload: {
              dimOrderRequestId:
                batchUpdateResult.data?.batchUpdate.dim_order_request_id,
              dimMercurialeId: product.mercuriale_id ?? "",
            },
          })
        }

        const logsModificationResult = await logsModificationUpdate({
          variables: {
            input: {
              modifications_logs_items: [modification],
              store_id: storeId!,
            },
          },
        })

        if (
          logsModificationResult.data?.logsModificationUpdate?.error !== null
        ) {
          throw logsModificationResult.data?.logsModificationUpdate?.error
        }
      } catch (error) {
        console.error(error)
        const errorMessage =
          error instanceof Error ? error.message : "Données non sauvegardées"
        captureException(new Error(errorMessage))
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.FAILURE,
        })
        dispatch({
          type: "addModification",
          payload: modification,
        })
      }

      delete timeouts[product.mercuriale_id ?? ""]
    }, 1000)
  }
  const handleDeleteProduct = (product: AllMercurialInfo | GetOrderItem) => {
    setQuantityTo0(product)
    setDeletedProducts((prev) => [...prev, product.mercuriale_id ?? ""])
  }

  return (
    <div className="rounded h-full flex flex-col">
      <div className={`flex items-center py-2 ${!isMD ? "px-2" : ""}`}>
        <Button variant="ghost" className="pl-4 " onClick={handleBack}>
          <ArrowLeftIcon />
        </Button>
        {isMD ? (
          <div className="flex flex-row items-center gap-2">
            <p className="text-xl font-bold ">Résumé de commande</p>
            <p className="block">
              Un dernier coup d&apos;oeil ? Ajustez vos quantités si besoin.
            </p>
          </div>
        ) : (
          <SearchBar />
        )}
      </div>
      <div
        className={`sticky top-0 z-10 bg-white backdrop-blur backdrop-filter ${!isMD ? "px-2" : ""}`}
      >
        <div className="hidden md:flex flex-row gap-1 my-2">
          <SearchBar />
          {columns.map((column) => (
            <FilterButton
              key={column.id}
              id={column.id}
              name={column.name}
              nameInMobile={column.nameInMobile}
            />
          ))}
        </div>
      </div>
      <GroupedVirtuoso
        className="h-[88%] lg:h-[92%] w-full rounded-md pb-96"
        groupCounts={groupCounts}
        groupContent={(groupIndex) => (
          <HeaderGroup
            supplierData={suppliersData[groupIndex]}
            storeCurrency={storeCurrency}
            storeStoreSuppliers={storeStoreSuppliers}
          />
        )}
        itemContent={(index, groupIndex) => {
          const supplier = suppliersData[groupIndex]
          if (!supplier) return null

          const localIndex = getLocalIndex(index, groupIndex, groupCounts)
          const product = supplier.products[localIndex]
          if (!product) return null

          const quantityActualInUnit = calculateQuantityActualInUnit(
            product,
            storeSettings,
          )
          const isQuantityExcessive = excessiveProducts.some(
            (ep) => ep.mercuriale_id === product.mercuriale_id,
          )
          const isQuantityTooLow = tooLowProducts.some(
            (tp) => tp.mercuriale_id === product.mercuriale_id,
          )

          const isLastItem =
            groupIndex === suppliersData.length - 1 &&
            localIndex === supplier.products.length - 1

          const DesktopRowStyle =
            "grid items-center h-full gap-1 lg:gap-4 p-2 text-base lg:text-lg w-full md:grid-cols-12 lg:grid-cols-9"

          const MobileRowStyle = "flex flex-col py-4 px-4"

          const quantityIssueClass =
            isQuantityExcessive || isQuantityTooLow
              ? "rounded my-1 bg-red-30"
              : ""

          return (
            <>
              <TableRow
                className={`${isMD ? DesktopRowStyle : MobileRowStyle} ${quantityIssueClass}`}
              >
                {isMD ? (
                  <DesktopTableRow
                    product={product}
                    quantityActualInUnit={quantityActualInUnit}
                    handleDeleteProduct={handleDeleteProduct}
                    online={online}
                    storeCurrency={storeCurrency}
                    orderId={orderId}
                    isOrderConfirmed={isOrderConfirmed}
                  />
                ) : (
                  <MobileTableRow
                    product={product}
                    quantityActualInUnit={quantityActualInUnit}
                    handleDeleteProduct={handleDeleteProduct}
                    online={online}
                    storeCurrency={storeCurrency}
                    orderId={orderId}
                    isOrderConfirmed={isOrderConfirmed}
                  />
                )}
              </TableRow>
              {isLastItem && <div className={isMD ? "h-32" : "h-24"} />}
            </>
          )
        }}
      />
      <TotalDisplay
        storeCurrency={storeCurrency}
        references={references}
        handleValidateOrderPreview={handleValidateOrderPreview}
        isOrderConfirmed={isOrderConfirmed}
        orderId={orderId}
        enable={enable}
      />
    </div>
  )
}

export default Table
