import { type IconType } from "./types"
import { twMerge } from "tailwind-merge"

export function ArrowLeftIcon({ className }: IconType): JSX.Element {
  const mergedClassName = twMerge("w-6 h-6", className)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={mergedClassName}
    >
      <path
        d="M19.5 12H4.5M4.5 12L11.25 18.75M4.5 12L11.25 5.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
