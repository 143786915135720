import { ArrowLeftIcon } from "lucide-react"
import { Link } from "react-router-dom"

interface EngagementPromoHeaderProps {
  isTestMode: boolean
}

export function EngagementPromoHeader({
  isTestMode,
}: EngagementPromoHeaderProps) {
  return (
    <div className="flex items-center gap-8 p-4 md:p-7 lg:p-14 bg-white">
      <Link to="/account/calendar">
        <ArrowLeftIcon className="size-6" />
      </Link>
      <p className="text-2xl text-black font-bold">Engagements promotionnels</p>
      {isTestMode && (
        <p className="bg-yellow-500 text-yellow-900 px-2 rounded">
          Les modifications ne sont pas sauvegardées
        </p>
      )}
    </div>
  )
}
