import { Badge } from "@/components/ui/badge"
import { TrendingDown, TrendingUp } from "lucide-react"

function GreaterContent({ value, text }: { value: number; text: string }) {
  return (
    <>
      <TrendingUp className="size-4 mr-1 text-green-800" />
      <p>
        {new Intl.NumberFormat("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(Math.abs(value))}
        <span className="text-gray-700 font-normal"> x &gt; {text}</span>
      </p>
    </>
  )
}

function LowerContent({ value, text }: { value: number; text: string }) {
  return (
    <>
      <TrendingDown className="size-4 mr-1 text-red-800" />
      <p>
        {new Intl.NumberFormat("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(Math.abs(value))}
        <span className="text-gray-700 font-normal"> x &lt; {text}</span>
      </p>
    </>
  )
}

export function DeltaBadge({ value, text }: { value: number; text: string }) {
  if (value === 1 || value === -1) return <></>

  return (
    <Badge variant="outline" className="w-fit text-nowrap">
      {value > 1 && <GreaterContent value={value} text={text} />}
      {value < 0 && <LowerContent value={value} text={text} />}
    </Badge>
  )
}
