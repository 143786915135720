export const dayNameToEnumValue = (dayName: string): string => {
  const mapping: Record<string, string> = {
    Lundi: "lundi",
    Mardi: "mardi",
    Mercredi: "mercredi",
    Jeudi: "jeudi",
    Vendredi: "vendredi",
    Samedi: "samedi",
    Dimanche: "dimanche",
  }
  return mapping[dayName]
}

export const enumValueToDayName = (enumValue: string): string => {
  const mapping: Record<string, string> = {
    lundi: "Lundi",
    mardi: "Mardi",
    mercredi: "Mercredi",
    jeudi: "Jeudi",
    vendredi: "Vendredi",
    samedi: "Samedi",
    dimanche: "Dimanche",
  }
  return mapping[enumValue]
}
