import { CSSProperties, ReactNode, useMemo } from "react"
import { useDispatch } from "react-redux"
import FilterButton from "../button/FilterButton"
import { type DispatchActionType } from "../../types"
import { type PageName } from "../../reducers/userInterfaceReducer"
import { useWindowSize } from "../../hooks/useWindowSize"
import { HomeModernIcon, Squares2X2Icon } from "@heroicons/react/24/solid"
import {
  HomeModernIcon as OutlineHomeModernIcon,
  Squares2X2Icon as OutlineSquares2X2Icon,
} from "@heroicons/react/24/outline"

interface FLBarProps {
  className?: string
  style?: CSSProperties
  parentCategory?: string
  categories: {
    label?: string
    value: string
    extra?: ReactNode
  }[]
  activeCategories?: string[]
  field: "supplierId" | "familyName" | "subFamilyName"
  page: PageName
  singleBar?: boolean
  companyName: string | null
}

export default function FLBar({
  parentCategory,
  categories,
  activeCategories,
  field,
  className,
  singleBar = false,
  companyName,
}: FLBarProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const { isMD } = useWindowSize()

  const handleCategory = (category?: string): void => {
    if (field === "familyName") {
      dispatch({
        type: "setFilters",
        payload: {
          page: "inventoryPage",
          [field]: category,
          subFamilyName: undefined,
        },
      })
      return
    }
    dispatch({
      type: "setFilters",
      payload: { page: "inventoryPage", [field]: category },
    })
  }

  const allCategoryText = useMemo(() => {
    switch (field) {
      case "supplierId":
        return {
          label: isMD ? "TOUS LES FOURNISSEURS" : "FOURNISSEURS",
          extra: !isMD ? (
            activeCategories === undefined ? (
              <HomeModernIcon className="ml-1 w-4 h-4" />
            ) : (
              <OutlineHomeModernIcon className="ml-1 w-4 h-4" />
            )
          ) : undefined,
        }
      case "subFamilyName":
        return {
          label: isMD
            ? singleBar
              ? "TOUS LES PRODUITS"
              : `TOUS ${parentCategory ?? ""}`
            : "TOUS",
        }
      default:
        return {
          label: isMD ? "TOUS LES PRODUITS" : "PRODUITS",
          extra: !isMD ? (
            activeCategories === undefined ? (
              <Squares2X2Icon className="ml-1 w-4 h-4" />
            ) : (
              <OutlineSquares2X2Icon className="ml-1 w-4 h-4" />
            )
          ) : undefined,
        }
    }
  }, [activeCategories, field, isMD, parentCategory, singleBar])

  return (
    <div
      className={
        "flex justify-between items-center overflow-y-hidden overflow-x-auto whitespace-nowrap w-full mx-auto"
      }
    >
      <div
        className={`flex justify-center lg:gap-6 text-center font-normal items-center ${className ?? ""}`}
      >
        {(field !== "supplierId" || companyName === "biomonde") && (
          <>
            <FilterButton
              key="sans-filtres"
              category={allCategoryText.label}
              onClick={() => {
                handleCategory()
              }}
              active={activeCategories === undefined} // Revert back to the original condition
              sideElement={allCategoryText.extra}
              first
            />
            <div className="w-[1px] h-6 bg-neutral-200 mx-2" />
          </>
        )}
        {categories.map((category) => (
          <FilterButton
            key={category.value}
            category={category.label ?? category.value}
            onClick={() => {
              handleCategory(category.value)
            }}
            active={activeCategories?.includes(category.value) ?? false}
            sideElement={category.extra}
            disabled={field === "supplierId" && companyName !== "biomonde"}
          />
        ))}
      </div>
    </div>
  )
}
