import { EventType, InventoryType } from "./__generated__/graphql"

export function convertInventoryTypeToEventType(
  inventoryType: InventoryType,
): EventType {
  switch (inventoryType) {
    case InventoryType.Back:
      return EventType.BackInventory
    case InventoryType.Floor:
      return EventType.FloorInventory
    case InventoryType.Order:
      return EventType.Prediction
    case InventoryType.Shelf:
      return EventType.ShelfFloor
  }
}
