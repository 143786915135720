import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { ShieldAlert } from "lucide-react"
import { ReactNode } from "react"

interface ProductAlertSectionProps {
  title: string
  message: string
  isAllFixed: boolean
  children: ReactNode
}

export const ProductAlertSection = ({
  title,
  message,
  isAllFixed,
  children,
}: ProductAlertSectionProps) => {
  const borderClass = isAllFixed ? "border-l-green-400" : "border-l-red-400"

  return (
    <div className="flex flex-col gap-4 w-full bg-sky-10 rounded-lg">
      <Alert
        className={`border-2 bg-gray-100 border-gray-100 border-l-4 ${borderClass} text-left `}
      >
        <div className="flex items-center gap-2">
          <ShieldAlert size={20} />
          <AlertTitle className="text-lg">{title}</AlertTitle>
        </div>
        <AlertDescription className="text-lg text-gray-600">
          {message}
        </AlertDescription>
      </Alert>
      <div className="flex w-full flex-wrap gap-2">{children}</div>
    </div>
  )
}
