interface getTotalStockParams {
  backQuantity: number
  floorQuantity: number
}

export function getTotalStock({
  backQuantity,
  floorQuantity,
}: getTotalStockParams): number {
  const positiveStockQuantity = Math.max(backQuantity + floorQuantity, 0)
  return positiveStockQuantity
}
