import { ExclamationTriangleIcon, WifiIcon } from "@heroicons/react/24/outline"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import OrderButton from "../button/OrderButton"
import { useSelector } from "react-redux"
import { StateType } from "../../types"
import { useAuth0 } from "@auth0/auth0-react"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { Dispatch, SetStateAction } from "react"

interface MobileInventoryRecapProps {
  mercurialeDate: string | undefined
  isLoading: boolean
  hide: boolean
  setSaveDataModalState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      backToHome: boolean
    }>
  >
}

export function MobileInventoryRecap({
  mercurialeDate,
  isLoading,
  hide,
  setSaveDataModalState,
}: MobileInventoryRecapProps) {
  const storeCurrency = useSelector(
    (state: StateType) => state.storeReducer.storeCurrency,
  )
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const { online, dataSynchronizationStatus } = useSelector(
    (state: StateType) => state.connectionReducer,
  )

  const {
    orderValuedStock,
    valuedStock,
    totalOrders,
    potentialCommandRevenue,
    potentialOrderRevenue,
  } = usePotentialRevenue()
  const { user } = useAuth0()

  return (
    <div
      className={`pointer-events-auto flex flex-col gap-3 w-full bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.11)] px-6 pt-2 ${user?.["arolya/user_metadata"]?.store_id !== storeId ? "pb-12" : "pb-3"}`}
    >
      <div
        className={`text-neutral-500 flex gap-1 text-xs overflow-hidden ${hide ? "max-h-0" : "max-h-96"} transition-all duration-500`}
      >
        <div className="flex-1 flex flex-col gap-1">
          <p className="font-bold text-zinc-800">Valorisation</p>
          <p>Stock</p>
          <p>Commande</p>
        </div>
        <div className="flex-1 flex flex-col gap-1 border-r pr-3">
          <p className="text-right">En prix d&apos;achat</p>
          {orderValuedStock !== undefined && !isLoading ? (
            <p className="font-bold text-zinc-800 text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(orderValuedStock)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
          {potentialOrderRevenue !== undefined && !isLoading ? (
            <p className="font-bold text-zinc-800 text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(potentialOrderRevenue)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-right">En prix de vente</p>
          {valuedStock !== undefined && !isLoading ? (
            <p className="font-bold text-zinc-800 text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(valuedStock)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
          {potentialCommandRevenue !== undefined && !isLoading ? (
            <p className="font-bold text-zinc-800 text-right">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(potentialCommandRevenue)}
            </p>
          ) : (
            <div className="flex-1 w-10 h-3 bg-gray-200 rounded-full animate-pulse" />
          )}
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="flex-1 flex items-center gap-2">
          <WifiIcon
            className={`w-5 h-5 ${online ? "text-green-200" : "text-red-200"}`}
          />
          {dataSynchronizationStatus === DataSynchronizationStatus.FAILURE && (
            <button
              className="flex items-center"
              onClick={() =>
                setSaveDataModalState({ isOpen: true, backToHome: false })
              }
            >
              <ExclamationTriangleIcon
                className={`w-5 h-5 lg:w-6 lg:h-6 text-orange-400`}
              />
            </button>
          )}
        </div>
        <div className="flex-2">
          <OrderButton
            isLoading={isLoading}
            className="text-sm font-black py-2 px-6 w-fit uppercase"
            totalOrders={totalOrders}
          />
        </div>
        <div className="flex-1 flex justify-end">
          {mercurialeDate !== undefined && (
            <p className="text-xs text-zinc-400">
              {new Intl.DateTimeFormat("fr-FR", {
                day: "2-digit",
                month: "2-digit",
              }).format(new Date())}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
