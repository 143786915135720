import { type ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { usePermissions } from "../hooks/usePermissions"

interface PermissionsProtectedRouteProps {
  permissions: string[]
  children: ReactNode
}

const PermissionsProtectedRoute = ({
  permissions,
  children,
}: PermissionsProtectedRouteProps): JSX.Element => {
  const { hasPermissions } = usePermissions()

  return (
    <>{hasPermissions(permissions) ? children : <Navigate to="/account" />}</>
  )
}

export default PermissionsProtectedRoute
