import { useCallback, useEffect, useMemo, useState } from "react"
import { Outlet, Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import type { DispatchActionType, StateType } from "../types"
import type { ReactElement } from "react"
import LogRocket from "logrocket"

import { useGetStoreLazyQuery } from "../utils/__generated__/graphql"
import { Spinner } from "../ui/Spinner"
import { useAuth0 } from "@auth0/auth0-react"
import { useFiefAuth, useFiefIsAuthenticated } from "@fief/fief/react"
import { persistor } from "../store"
import { captureException } from "@sentry/react"
import { usePermissions } from "../hooks/usePermissions"
import { toast } from "sonner"

const Root = (): ReactElement => {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const { loginWithRedirect, isAuthenticated, user } = useAuth0()
  const isAuthenticatedWithFief = useFiefIsAuthenticated()
  const fiefAuth = useFiefAuth()
  const { isReadOnly } = usePermissions()

  const authReducer = useSelector((state: StateType) => state.authReducer)
  const { storeId, storeName, companyId, selectedStoreId } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const [isInfosReady, setIsinfosReady] = useState(false)

  const [getStoreQuery, { loading: isStoreQueryLoading }] =
    useGetStoreLazyQuery()

  const getStore = useCallback(async () => {
    try {
      if (typeof user?.["arolya/user_metadata"]?.store_id !== "string") {
        navigate("/logout")
        console.error("User doesn't have store id")
        return
      }

      const resultStore = await getStoreQuery({
        variables: {
          input: {
            store_id: selectedStoreId ?? user["arolya/user_metadata"].store_id,
          },
        },
      })
      if (resultStore.data?.getStore?.error !== null) {
        throw new Error("Une erreur est survenue")
      }

      dispatch({
        type: "setStore",
        payload: {
          storeId: resultStore.data?.getStore?.store?.id,
          storeName: resultStore.data?.getStore?.store?.store_name,
          storeCity: resultStore.data?.getStore?.store?.city,
          companyId: resultStore.data?.getStore?.store?.company_id,
          storeCountry: resultStore.data?.getStore?.store?.country,
          storeSettings: resultStore.data?.getStore?.store?.settings,
          storeSuppliers: resultStore.data?.getStore?.suppliers,
          storeStoreSuppliers: resultStore.data.getStore.storeSuppliers,
          storeCurrency: resultStore.data.getStore.store?.currency,
          companyName: resultStore.data.getStore.store?.company_name,
          storeFranchise: resultStore.data.getStore.store?.franchise,
          storeCode: resultStore.data.getStore.store?.store_code,
        },
      })
      if (isReadOnly) {
        dispatch({
          type: "setTrainingMode",
          payload: true,
        })
      }
      setIsinfosReady(true)
    } catch (e) {
      captureException(e)
      console.error(e)
    }
  }, [dispatch, getStoreQuery, isReadOnly, navigate, selectedStoreId, user])

  useEffect(() => {
    async function handleFiefLogout() {
      dispatch({ type: "resetState" })
      await persistor.flush()
      await persistor.purge()
      fiefAuth.logout(`${window.location.protocol}//${window.location.host}`)
    }
    if (isAuthenticatedWithFief) {
      handleFiefLogout()
      return
    }
    if (!isAuthenticated && !authReducer.isAuthenticated) {
      const path = window.location.pathname + window.location.search
      dispatch({ type: "setRedirectPath", payload: path })
      loginWithRedirect()
      return
    }
    if (!isAuthenticated) return
    getStore()
  }, [
    authReducer.isAuthenticated,
    dispatch,
    getStore,
    isAuthenticated,
    loginWithRedirect,
    navigate,
    isAuthenticatedWithFief,
    fiefAuth,
  ])

  const isLoading = useMemo(() => {
    if (
      isStoreQueryLoading ||
      !isInfosReady ||
      !isAuthenticated ||
      typeof storeId !== "string"
    )
      return true

    return false
  }, [isAuthenticated, isInfosReady, isStoreQueryLoading, storeId])

  useEffect(() => {
    if (
      isLoading ||
      user === undefined ||
      typeof storeId !== "string" ||
      typeof storeName !== "string" ||
      typeof companyId !== "string"
    )
      return

    LogRocket.identify(user.sub!, {
      email: user.email!,
      storeId: storeId,
      storeName: storeName,
      companyId: companyId,
    })
  }, [companyId, isLoading, storeId, storeName, user])

  useEffect(() => {
    const handleOnline = (): void => {
      dispatch({ type: "setOnline", payload: true })
      toast.success("Connexion internet rétablie")
    }

    const handleOffline = (): void => {
      dispatch({ type: "setOnline", payload: false })
      toast.error("Aucune connexion internet")
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [dispatch])

  if (isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner invertColors className="w-10 h-10" />
      </div>
    )
  }

  return (
    <>
      {user !== undefined &&
        typeof storeId === "string" &&
        user?.["arolya/user_metadata"]?.store_id !== storeId && (
          <div className="fixed bottom-0 left-0 w-full p-1 pb-5 md:pb-1 bg-yellow-500 text-yellow-900 z-10 text-xs justify-center flex gap-2">
            <p>
              <span className="hidden md:inline">
                Vous êtes connecté sur le magasin{" "}
              </span>
              {storeName}
              <span className="hidden md:inline"> !</span>
            </p>
            <Link
              className="underline"
              to={`/stores/${user?.["arolya/user_metadata"]?.store_id}`}
            >
              Revenir
              <span className="hidden md:inline">
                {" "}
                au magasin d&apos;origine
              </span>
            </Link>
          </div>
        )}
      <Outlet />
    </>
  )
}

export default Root
