import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreReducerState } from "@/reducers/storeReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import { GetQuarterValueResult, getQuarterValue } from "@/utils/getQuarterValue"
import { getUnit } from "@/utils/getUnit"
import { InventoryInput } from "./InventoryInput"
import OutstandingOrders from "../OustandingOrders"
import { InventoryType } from "@/utils/__generated__/graphql"
import { EquivalentInventory } from "./EquivalentInventory"
import { ResetPredictionButton } from "../ResetPrediction/ResetPredictionButton"
import { ResetPredictionPopover } from "../ResetPrediction/ResetPredictionPopover"
import { usePermissions } from "@/hooks/usePermissions"

interface InventoriesInputsDesktopLayoutProps {
  shelfFloorSizeQuarterValue: GetQuarterValueResult
  reference: Partial<AllMercurialInfo>
  firstReference: AllMercurialInfo
  referenceIndex: number
  storeSettings: StoreReducerState["storeSettings"]
  isOrderDisabled: boolean
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  isOnline: boolean
  backInventoryEquivalent: number
  floorInventoryEquivalent: number
  shelfFloorSizeEquivalent: number
  hasBigBreakage: boolean
}

export function InventoriesInputsDesktopLayout({
  reference,
  referenceIndex,
  storeSettings,
  isOrderDisabled,
  updateInventory,
  backInventoryEquivalent,
  floorInventoryEquivalent,
  shelfFloorSizeEquivalent,
  firstReference,
  isOnline,
  hasBigBreakage,
}: InventoriesInputsDesktopLayoutProps) {
  const totalInventoryQuantity =
    (reference.back_inventory_qty ?? 0) + (reference.floor_inventory_qty ?? 0)
  const totalInventoryQuantityQuarter = getQuarterValue(
    totalInventoryQuantity,
    "size-4",
  )
  const displayedUnit =
    storeSettings?.use_kg_pce === true ? getUnit(reference.unit) : "cs"

  const { canUpdateOrderQuantity } = usePermissions()
  const hasPredictions =
    reference.quantity_predicted_array &&
    reference.quantity_predicted_array!.length > 0

  const isUpdateOrderDisabled =
    (isOrderDisabled || (hasPredictions && !canUpdateOrderQuantity)) ?? false

  return (
    <div className="flex flex-col gap-2 self-center lg:self-end">
      <div className="flex items-end">
        <div className="p-2 flex items-end gap-2 border bg-gray-50 rounded rounded-br-none relative">
          <p className="block absolute top-1 -right-2 bg-[#6f6f6f] font-bold py-0.5 px-2 rounded text-white text-xs">
            {displayedUnit}
          </p>
          <InventoryInput
            name={InventoryType.Back}
            stock_too_high_flag={reference.stock_too_high_flag}
            stock_too_low_flag={reference.stock_too_low_flag}
            stock_to_verify_flag={reference.stock_to_verify_flag}
            mercurialeId={reference.mercuriale_id!}
            unit={reference.unit ?? ""}
            waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
            promoWaitedQuantityOrdered={
              reference.promo_waited_quantity_ordered ?? 0
            }
            value={
              referenceIndex > 0
                ? backInventoryEquivalent / (reference.colisage ?? 1)
                : (reference.back_inventory_qty ?? 0)
            }
            floorInventoryQuantity={
              referenceIndex > 0
                ? floorInventoryEquivalent / (reference.colisage ?? 1)
                : (reference.floor_inventory_qty ?? 0)
            }
            updateInventory={updateInventory}
          />
          <p className="flex items-center text-sm font-bold h-[56px]">+</p>
          <InventoryInput
            name={InventoryType.Shelf}
            mercurialeId={reference.mercuriale_id!}
            unit={reference.unit ?? ""}
            isNewReference={reference.new_reference ?? false}
            value={
              referenceIndex > 0
                ? shelfFloorSizeEquivalent / (reference.colisage ?? 1)
                : (reference.shelf_floor_size ?? null)
            }
            floorInventoryQuantity={
              referenceIndex > 0
                ? floorInventoryEquivalent / (reference.colisage ?? 1)
                : (reference.floor_inventory_qty ?? 0)
            }
            updateInventory={updateInventory}
          />
          <p className="flex items-center text-sm font-bold h-[56px]">=</p>
          <p className="flex items-center font-bold h-[56px] w-11">
            {totalInventoryQuantityQuarter.value}
            {totalInventoryQuantityQuarter.icon}
          </p>
        </div>
        <div className="w-14">
          {((reference.waited_quantity_ordered ?? 0) > 0 ||
            (reference.promo_waited_quantity_ordered ?? 0) > 0) && (
            <div className="border border-l-0 bg-gray-50 rounded-r py-2 pl-2 pr-1">
              {(reference.waited_quantity_ordered ?? 0) > 0 && (
                <OutstandingOrders
                  isPromo={false}
                  quantity={reference.waited_quantity_ordered}
                  displayedUnit={displayedUnit}
                />
              )}
              {(reference.promo_waited_quantity_ordered ?? 0) > 0 && (
                <OutstandingOrders
                  isPromo={true}
                  quantity={reference.promo_waited_quantity_ordered}
                  displayedUnit={displayedUnit}
                />
              )}
            </div>
          )}
        </div>
        <div className="relative ml-2">
          <ResetPredictionPopover
            reference={reference}
            disabled={isOrderDisabled || !isOnline}
            storeSettings={storeSettings}
            hasBigBreakage={hasBigBreakage}
          />
          <div className="relative z-10 py-2">
            <InventoryInput
              name={InventoryType.Order}
              prediction_uncertain={reference.prediction_uncertain}
              mercurialeId={reference.mercuriale_id!}
              unit={reference.unit ?? ""}
              value={reference?.quantity_actual ?? 0}
              updateInventory={updateInventory}
              disabled={isUpdateOrderDisabled}
              isPrecommande={reference.is_precommande ?? false}
              waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
              promoWaitedQuantityOrdered={
                reference.promo_waited_quantity_ordered ?? 0
              }
            />
          </div>
          <div className="flex h-full absolute left-full top-0 justify-center items-center">
            <ResetPredictionButton
              reference={reference}
              disabled={isUpdateOrderDisabled || !isOnline}
            />
          </div>
        </div>
      </div>
      {referenceIndex === 0 && (
        <div className="flex items-center border border-zinc-100 rounded pl-2 py-0.5">
          <EquivalentInventory
            value={firstReference.back_inventory_qty ?? 0}
            colisage={firstReference.colisage ?? 1}
            unit={firstReference.unit ?? ""}
            useKgPce={storeSettings?.use_kg_pce === true}
            toVerify={firstReference.stock_to_verify_flag === true}
            className=" w-[62px] flex items-center justify-center"
          />
          <div className="w-[25px]" />
          <EquivalentInventory
            value={firstReference.floor_inventory_qty ?? 0}
            colisage={firstReference.colisage ?? 1}
            unit={firstReference.unit ?? ""}
            useKgPce={storeSettings?.use_kg_pce === true}
            className="w-[86px] flex items-center justify-center"
          />
          <div className="w-[16px]" />
          <EquivalentInventory
            value={totalInventoryQuantity}
            colisage={firstReference.colisage ?? 1}
            unit={firstReference.unit ?? ""}
            useKgPce={storeSettings?.use_kg_pce === true}
            className="w-[78px] flex items-center pl-3"
          />
          <div className="w-[48px]" />
          <EquivalentInventory
            value={reference.quantity_actual ?? 0}
            colisage={reference.colisage ?? 1}
            unit={reference.unit ?? ""}
            useKgPce={storeSettings?.use_kg_pce === true}
            className="w-[62px] flex items-center justify-center"
          />
        </div>
      )}
    </div>
  )
}
