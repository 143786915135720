import { type IconType } from "./types"

export function HomeIcon({ className }: IconType): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      fill="none"
      width="24"
      className={className}
    >
      <path
        d="M 9 22 V 12 H 15 V 22 M 3 9 L 12 2 L 21 9 V 20 C 21 20.53 20.789 21.039 20.414 21.414 C 20.039 21.789 19.53 22 19 22 H 5 C 4.47 22 3.961 21.789 3.586 21.414 C 3.211 21.039 3 20.53 3 20 V 9 Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
