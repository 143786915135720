import { Auth0ClientOptions } from "@auth0/auth0-spa-js"

const auth0Config: Auth0ClientOptions = {
  domain: import.meta.env.VITE_AUTH_DOMAIN,
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/callback`,
    scope: "openid email profile offline_access",
    audience: import.meta.env.VITE_AUTH_AUDIENCE,
  },
}

export { auth0Config }
