import { TableBody, TableCell, TableRow } from "@/components/ui/table"
import { DeltaBadge } from "./DeltaBadge"
import { Badge } from "@/components/ui/badge"
import { Performance } from "@/utils/__generated__/graphql"

function compareValues(value1: number, value2: number): number {
  const ratio = value1 / (value2 > 0 ? value2 : 1)

  const roundedRatio = (ratio >= 1 ? ratio : -(1 / ratio)).toFixed(2)

  return parseFloat(roundedRatio)
}

interface PerformancesTableBodyProps {
  performances: Performance[] | undefined | null
  storeCurrency: string | null
}

export function PerformancesTableBody({
  performances,
  storeCurrency,
}: PerformancesTableBodyProps) {
  return (
    <TableBody>
      {performances?.map((performance) => {
        const orderedAmount = performance?.ordered_amount ?? 0
        const salesAmount = performance?.sales_amount ?? 0
        const prePredictedAmount = performance?.pre_predicted_amount ?? 0
        const predictedAmount = performance?.predicted_amount ?? 0
        const finalPredictedAmount = performance?.final_predicted_amount ?? 0
        const breakagesAmount = performance?.breakages_amount ?? 0

        const salesOrderedAmountDelta = compareValues(
          orderedAmount,
          salesAmount,
        )
        const finalPredictedOrderedAmountDelta = compareValues(
          orderedAmount,
          finalPredictedAmount,
        )
        const salesPredictedAmountDelta = compareValues(
          predictedAmount,
          salesAmount,
        )

        const updatePercentage =
          performance?.update_count / (performance?.references_count ?? 1)

        return (
          <TableRow key={performance?.store_id}>
            <TableCell className="font-medium align-top">
              {performance?.store_name}
            </TableCell>
            <TableCell className="flex flex-col gap-1 font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(orderedAmount)}
              <DeltaBadge value={salesOrderedAmountDelta} text="Ventes" />
              <DeltaBadge
                value={finalPredictedOrderedAmountDelta}
                text="Préds post recalage"
              />
            </TableCell>
            <TableCell className="font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(prePredictedAmount)}
            </TableCell>
            <TableCell className="font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(finalPredictedAmount)}
            </TableCell>
            <TableCell className="flex flex-col gap-1 font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(predictedAmount)}
              <DeltaBadge value={salesPredictedAmountDelta} text="Ventes" />
            </TableCell>
            <TableCell className="font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(salesAmount)}
            </TableCell>
            <TableCell className="font-medium align-top">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(breakagesAmount)}
            </TableCell>
            <TableCell className="font-medium align-top flex flex-col gap-1">
              <p>
                {new Intl.NumberFormat("fr-FR", {}).format(
                  performance.update_count,
                )}
                <span className="text-gray-500 text-xs">
                  {" "}
                  /{" "}
                  {new Intl.NumberFormat("fr-FR", {}).format(
                    performance.references_count,
                  )}{" "}
                  références
                </span>
              </p>
              <Badge
                className="w-fit"
                variant={updatePercentage > 0.2 ? "destructive" : "secondary"}
              >
                {new Intl.NumberFormat("fr-FR", {
                  style: "percent",
                }).format(updatePercentage)}
              </Badge>
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
