import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, type StateType } from "../../types"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"

import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Spinner } from "@/ui/Spinner"
import { RotateCcw } from "lucide-react"

// Importez ou créez votre composant Drawer ici
import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer"
import { useWindowSize } from "@/hooks/useWindowSize"
import { toast } from "sonner"

interface ConfirmRecapModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  isLoading: boolean
  handleConfirm: (isWaitingSend: boolean) => void
  isWaitingSend: boolean
  setIsWaitingSend: (isWaitingSend: boolean) => void
}

const ConfirmRecapModal = ({
  setOpen,
  open,
  isLoading,
  handleConfirm,
  isWaitingSend,
  setIsWaitingSend,
}: ConfirmRecapModalProps): JSX.Element => {
  const { isMD } = useWindowSize()
  const dispatch = useDispatch<DispatchActionType>()
  const [isHealthLoading, setIsHealthLoading] = useState(false)

  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { totalOrders, orderPrice } = usePotentialRevenue()

  async function retryConnection() {
    try {
      setIsHealthLoading(true)
      const response = await fetch(
        import.meta.env.VITE_API_URL.replace("/graphql/", "/health"),
      )
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }
      const data = (await response.json()) as { status: string }
      if (data.status !== "ok") {
        throw new Error(`Response status: ${data.status}`)
      }
      dispatch({
        type: "setOnline",
        payload: true,
      })
      toast.success("Connexion internet rétablie")
    } catch (error) {
      console.error(error)
      toast.error("Aucune connexion internet")
    } finally {
      setIsHealthLoading(false)
    }
  }

  function handleClose() {
    if (isLoading || isHealthLoading) return

    setTimeout(() => {
      setIsWaitingSend(false)
    }, 250)
    setOpen(false)
  }

  const title = isWaitingSend
    ? "Vous n'êtes pas connecté à internet."
    : "Confirmez-vous l'envoi de cette commande ?"

  const renderContent = () => (
    <div className="flex flex-col h-full p-2 gap-3">
      <div className="flex flex-col gap-4 md:gap-4 h-full ">
        <div className="flex flex-row md:flex-row items-center gap-4 pl-1">
          {isMD ? (
            <DialogTitle className="text-black text-xl font-bold">
              {title}
            </DialogTitle>
          ) : (
            <DrawerTitle className="text-black text-xl font-bold">
              {title}
            </DrawerTitle>
          )}
        </div>
        {isWaitingSend ? (
          <>
            <p className="text-gray-600 text-lg md:text-base mt-4">
              La commande sera envoyée automatiquement une fois la connexion
              rétablie.
            </p>
            <div className="flex flex-col md:flex-row md:self-end gap-3">
              {!isLoading && !isHealthLoading ? (
                <div className="flex flex-col md:flex-row gap-4">
                  <div
                    onClick={retryConnection}
                    className="flex flex-row justify-center items-center gap-2"
                  >
                    <RotateCcw className="w-5 h-5" />
                    <p>Réessayer de se connecter</p>
                  </div>
                  <Button onClick={handleClose} color="ghost">
                    Annuler
                  </Button>
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-2">
              <p className="text-xl md:text-lg text-gray-600 pl-1">
                Coût total :
              </p>
              <p className="text-lg md:text-lg">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(orderPrice ?? 0)}
              </p>
            </div>
            <div className="flex flex-row items-center gap-2 pl-1">
              <p className="text-xl md:text-lg text-gray-600">
                Nombre de colis :
              </p>
              <p className="text-lg md:text-lg">
                {new Intl.NumberFormat("fr-FR", {
                  maximumFractionDigits: 0,
                }).format(totalOrders ?? 0)}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row md:self-end gap-3">
        <Button
          onClick={handleClose}
          className="w-full h-[44px] text-[16px]"
          color="ghost"
          variant="outline"
        >
          Annuler
        </Button>
        {!isWaitingSend && (
          <Button
            color={enable ? "yellow" : "green"}
            onClick={() => {
              void handleConfirm(!online)
            }}
            className="w-full h-[44px] text-[16px] bg-green-800 hover:bg-green-900"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Envoyer"}
          </Button>
        )}
      </div>
    </div>
  )

  return (
    <>
      {isMD ? (
        <Dialog open={open} onOpenChange={setOpen} aria-describedby={undefined}>
          <DialogContent className="max-w-screen-lg overflow-y-scroll min-h-screen max-h-screen md:max-w-screen-sm md:min-h-min md:max-h-min">
            {renderContent()}
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer open={open} onOpenChange={setOpen}>
          <DrawerContent aria-describedby={undefined}>
            <div className="max-w-screen h-full overflow-y-scroll">
              {renderContent()}
            </div>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

export default ConfirmRecapModal
