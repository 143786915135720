import { useSearchParams } from "react-router-dom"
import { formatValue } from "./utils"
import { GroupedReferences } from "."
import { usePotentialRevenue } from "@/hooks/usePotentialRevenue"
import { StoreReducerState } from "@/reducers/storeReducer"
import { TableRow } from "@/components/ui/table"

interface GroupContentProps {
  storeStoreSuppliers: StoreReducerState["storeStoreSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  supplierData: GroupedReferences
}

export const HeaderGroup = ({
  supplierData,
  storeCurrency,
  storeStoreSuppliers,
}: GroupContentProps) => {
  const [searchParams] = useSearchParams()
  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references: supplierData.products })

  const orderId = searchParams.get("orderId")
  const currentWeekday = Intl.DateTimeFormat("fr-FR", {
    weekday: "long",
  }).format(new Date())

  const minimalOrderValue =
    storeStoreSuppliers?.find(
      (storeSupplier) =>
        storeSupplier.supplier_id === supplierData.supplierId &&
        storeSupplier.order_day === currentWeekday,
    )?.minimal_order_value ?? undefined

  const isOrderValueHighlighted =
    minimalOrderValue !== undefined &&
    Math.floor(orderPrice ?? 0) < minimalOrderValue &&
    (typeof orderId !== "string" || orderId === "")

  return (
    <TableRow className="py-2 bg-gray-200 w-full grid md:text-lg lg:text-xl hover:bg-gray-200 md:grid-cols-12 lg:grid-cols-9">
      <p className="text-l font-bold whitespace-nowrap ml-6 col-span-4 md:col-span-7 lg:col-span-5">
        {supplierData.supplierName.toUpperCase()}
        {minimalOrderValue !== undefined &&
          minimalOrderValue > 0 &&
          (typeof orderId !== "string" || orderId === "") && (
            <span>
              {" | "}
              {formatValue(minimalOrderValue, storeCurrency)}
            </span>
          )}
      </p>

      <p className="hidden md:block font-semibold md:col-span-2 lg:col-span-1 text-gray-900 text-center">
        {totalOrders === undefined || isNaN(totalOrders)
          ? "-"
          : `${Math.floor(totalOrders)} cs`}
      </p>
      <p
        className={`hidden md:block text-lg font-semibold ${isOrderValueHighlighted ? "text-red-500" : "text-gray-900"} text-center`}
      >
        {orderPrice === undefined || isNaN(orderPrice)
          ? "-"
          : formatValue(Math.floor(orderPrice ?? 0), storeCurrency)}
      </p>
      <p className="hidden md:block font-semibold text-gray-900 text-center">
        {potentialCommandRevenue === undefined || isNaN(potentialCommandRevenue)
          ? "-"
          : formatValue(
              potentialCommandRevenue ? Math.floor(potentialCommandRevenue) : 0,
              storeCurrency,
            )}
      </p>
    </TableRow>
  )
}
