import {
  ChangeEvent,
  Fragment,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react"
import { getQuarterValue } from "../../utils/getQuarterValue"
import { RingColor, SelectorData, ringColors } from "./utils"
import { twJoin, twMerge } from "tailwind-merge"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

interface InventoryInputDesktopProps<T> {
  name?: string
  value: number
  onChange: (value: string) => void
  selectorData?: SelectorData<T>[]
  selectorValue?: SelectorData<T>["value"]
  onSelectorChange?: (value: SelectorData<T>["value"], index: number) => void
  disabled?: boolean
  onFocus?: () => void
  selected: boolean
  exponentNumber?: number
  rounded?: boolean
  topElement?: ReactNode
  ringColor?: RingColor
}

export function InventoryInputDesktop<T>({
  name,
  value,
  onChange,
  selectorData,
  selectorValue,
  onSelectorChange,
  disabled = false,
  onFocus,
  selected,
  exponentNumber,
  rounded,
  topElement,
  ringColor,
}: InventoryInputDesktopProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState<string>()

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      onChange("0")
      setLocalValue("0")
      return
    }

    const parsedValue = parseFloat(event.target.value)
    if (isNaN(parsedValue)) return

    const stringifiedValue = `${parsedValue}`

    if (event.target.value.endsWith(".") || event.target.value.endsWith(",")) {
      setLocalValue(stringifiedValue + ".")
      onChange(stringifiedValue)
      return
    }

    const integer = stringifiedValue.split(".")[0]

    setLocalValue(integer)
    onChange(stringifiedValue)
  }

  const displayedValue = getQuarterValue(value, "size-4")

  const selectorDataLabel = useMemo(() => {
    if (selectorData === undefined) return

    const selectedData = selectorData.find(
      (data) => data.value === selectorValue,
    )

    return selectedData?.selectedLabel ?? selectedData?.label ?? "-"
  }, [selectorData, selectorValue])

  return (
    <div className="flex flex-col justify-center items-center gap-1">
      {selectorData !== undefined && (
        <Menu>
          <MenuButton className="bg-white py-1 text-xs w-full flex justify-center items-center font-bold border rounded gap-1">
            {({ active }) => (
              <>
                {selectorDataLabel}
                <ChevronDownIcon
                  className={twJoin(
                    "size-4 transition-transform",
                    active ? "rotate-180" : "rotate-0",
                  )}
                />
              </>
            )}
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="bg-white rounded shadow p-2 w-fit text-neutral-500 text-xs font-bold"
          >
            {selectorData.map((data, i) => (
              <Fragment key={i}>
                <MenuItem data-active={false}>
                  <button
                    className="w-full data-[focus]:bg-neutral-500 data-[focus]:!text-white rounded py-1 px-2"
                    onClick={() => onSelectorChange?.(data.value, i)}
                  >
                    {data.label}
                  </button>
                </MenuItem>
                {i < selectorData.length - 1 && <hr />}
              </Fragment>
            ))}
          </MenuItems>
        </Menu>
      )}
      {topElement}
      <div
        className={twMerge(
          "bg-white h-[54px] p-1 flex justify-center items-center gap-1 border border-[#b7b7b7] rounded cursor-pointer",
          exponentNumber === undefined ? "w-[62px]" : "w-[86px]",
          rounded && "rounded-full h-[60px] w-[60px]",
          ringColor && `border-4 ${ringColors[ringColor]}`,
          selected && "border border-green-500 text-green-500",
          disabled && "cursor-auto border border-gray-300 text-gray-300",
        )}
        onClick={() => {
          if (disabled) return
          inputRef.current?.focus()
        }}
      >
        <input
          ref={inputRef}
          name={name}
          type=""
          inputMode="none"
          placeholder={value < 0 ? "-" : ""}
          value={localValue ?? (value < 0 ? "-" : (displayedValue.value ?? ""))}
          onChange={handleChange}
          min={0}
          disabled={disabled}
          onFocus={() => {
            onFocus?.()
            // This logic aims to use fresh value when user click on the input and user keyboard to update input
            setLocalValue("")
            if (inputRef.current === null) return
            inputRef.current.placeholder = inputRef.current?.value
          }}
          onBlur={() => {
            setLocalValue(undefined)
          }}
          className={twJoin(
            "appearance-none w-full flex-1 bg-transparent p-0 border-none ring-0 outline-0 focus:outline-none focus:ring-0 caret-transparent font-bold decoration-2 underline-offset-2 cursor-pointer disabled:cursor-auto selection:bg-transparent selection:text-inherit text-inherit placeholder:text-inherit",
            displayedValue.decimal === undefined &&
              (exponentNumber === undefined || exponentNumber <= 0)
              ? "text-center"
              : "text-right",
            !disabled && value >= 0 && "underline",
          )}
        />
        {displayedValue.icon !== undefined && (
          <div
            className={twJoin(
              "h-8 flex items-center",
              (exponentNumber === undefined || exponentNumber <= 0) && "flex-1",
            )}
          >
            {displayedValue.icon}
          </div>
        )}
        {exponentNumber !== undefined && exponentNumber > 0 && (
          <p className="flex-1 text-[#b7b7b7] font-bold text-xs flex items-center">
            /{getQuarterValue(exponentNumber).value}
            {getQuarterValue(exponentNumber, "size-3").icon}
          </p>
        )}
      </div>
    </div>
  )
}
