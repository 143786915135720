const NewLoading = (): JSX.Element => {
  return (
    <div
      role="status"
      className="flex flex-col px-2 xl:px-4 w-full gap-2 lg:gap-3 animate-pulse"
    >
      <div className="h-12" />
      {new Array(8).fill(null).map((_, i) => (
        <div
          key={i}
          className="flex justify-between bg-white shadow rounded p-4 gap-6 flex-col lg:flex-row"
        >
          <div className="flex flex-col gap-2">
            <div className="h-4 bg-gray-300 rounded-full w-40" />
            <div className="flex gap-2">
              <div className="h-5 bg-gray-200 rounded w-16" />
              <div className="h-5 bg-gray-200 rounded w-16" />
            </div>
            <div className="flex gap-2 items-center">
              <div className="h-11 w-9 bg-gray-300 rounded" />
              <div className="flex flex-col gap-2">
                <div className="w-24 h-3 bg-gray-200 rounded-full" />
                <div className="w-32 h-3 bg-gray-200 rounded-full" />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <div className="flex gap-8 justify-center">
              <div className="h-8 lg:h-[73px] w-20 lg:w-[73px] bg-gray-300 rounded-full" />
              <div className="h-8 lg:h-[73px] w-20 lg:w-[73px] bg-gray-300 rounded-full" />
              <div className="h-8 lg:h-[73px] w-20 lg:w-[73px] bg-gray-300 rounded-full" />
            </div>
            <div className="hidden lg:block h-6 w-full rounded bg-gray-200" />
          </div>
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default NewLoading
