import { useDispatch, useSelector } from "react-redux"
import { Button } from "@/components/ui/button"
import {
  GetMatchingReferencesQuery,
  useGetMatchingReferencesLazyQuery,
  useUpdateDimStoreArticleMutation,
  useUpdateDimStoreArticleCategoriesMutation,
  useGetMatchingCategoriesLazyQuery,
  GetMatchingCategoriesQuery,
  useResetDimStoreArticleCategoriesMutation,
} from "../utils/__generated__/graphql"
import { DispatchActionType, StateType } from "../types"
import { ArrowLeftIcon } from "../ui/icons/ArrowLeftIcon"
import { Virtuoso } from "react-virtuoso"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Input } from "@/components/ui/input"
import { Input as SearchInput } from "../ui/Input"
import { SearchIcon } from "../ui/icons/SearchIcon"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
} from "@heroicons/react/24/outline"
import { removeDuplicates } from "../utils/removeDuplicates"
import { Combobox, Transition } from "@headlessui/react"
import { captureException } from "@sentry/react"
import { getTimeAgo } from "../utils/getTimeAgo"
import { Link } from "react-router-dom"
import { Spinner } from "../ui/Spinner"
import { toast } from "sonner"

export function MatchingPage() {
  const dispatch = useDispatch<DispatchActionType>()

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [searchValue, setSearchValue] = useState("")
  const [selectedReference, setSelectedReference] = useState<{
    orderId: string
    value?: string
  }>()
  const [saleNameQueryValue, setSaleNameQueryValue] = useState("")
  const [matchingReferenceResult, setMatchingReferenceResult] =
    useState<GetMatchingReferencesQuery["getMatchingReferences"]>()
  const [matchingCategoriesResult, setMatchingCategoriesResult] =
    useState<GetMatchingCategoriesQuery["getMatchingCategories"]>()
  const [activeView, setActiveView] = useState<"articles" | "categories">(
    "articles",
  )
  const [expandedFamilies, setExpandedFamilies] = useState<Set<string>>(
    new Set(),
  )
  const [editingCategory, setEditingCategory] = useState<{
    dim_store_micro_category_id: string
    value: string
  } | null>(null)

  const [getMatchingReferences, { loading: matchingReferencesLoading }] =
    useGetMatchingReferencesLazyQuery({
      variables: {
        input: {
          store_id: storeId ?? "",
        },
      },
    })
  const [getMatchingCategories, { loading: matchingCategoriesLoading }] =
    useGetMatchingCategoriesLazyQuery({
      variables: {
        input: {
          store_id: storeId ?? "",
        },
      },
    })
  const [UpdateDimStoreArticleMutation] = useUpdateDimStoreArticleMutation()
  const [UpdateDimStoreArticleCategoriesMutation] =
    useUpdateDimStoreArticleCategoriesMutation()
  const [ResetDimStoreArticleCategoriesMutation] =
    useResetDimStoreArticleCategoriesMutation()

  const handleArticleUpdate = async (
    reference: Exclude<typeof filteredReferences, undefined>[number],
    updates: Partial<{
      article_name: string
      article_pv: number
      family_name: string
      sub_family_name: string
      sale_name_ida: string
    }>,
  ) => {
    if (isTestMode) return

    try {
      const result = await UpdateDimStoreArticleMutation({
        variables: {
          input: {
            order_id: reference.order_id,
            store_id: storeId!,
            ...updates,
          },
        },
      })

      if (result.data?.updateDimStoreArticle.error !== null) {
        throw new Error(result.data?.updateDimStoreArticle.error?.message)
      }

      setMatchingReferenceResult((_matchingReferenceResult) => {
        const updatedReferences = [
          ...(_matchingReferenceResult?.matching_references ?? []),
        ]
        const referenceIndex = updatedReferences.findIndex(
          (_reference) => _reference.order_id === reference.order_id,
        )
        updatedReferences[referenceIndex] = {
          ...updatedReferences[referenceIndex],
          ...updates,
        }

        return {
          ..._matchingReferenceResult,
          last_update: new Date().toISOString(),
          matching_references: updatedReferences,
        }
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }

  const handleFamilyUpdate = async (familyId: string, familyName: string) => {
    if (isTestMode) return

    try {
      console.log(`Family ID: ${familyId}`)
      console.log(`Updating family ${familyId} with name ${familyName}`)
      const result = await UpdateDimStoreArticleCategoriesMutation({
        variables: {
          input: {
            dim_store_family_id: familyId,
            family_name: familyName,
          },
        },
      })

      if (!result.data?.updateDimStoreArticleCategories) {
        throw new Error("Failed to update family")
      }

      setMatchingCategoriesResult((_matchingCategoriesResult) => {
        if (!_matchingCategoriesResult) return _matchingCategoriesResult

        const updatedCategories =
          _matchingCategoriesResult.matching_categories?.map((category) => {
            if (category.dim_store_family_id === familyId) {
              return {
                ...category,
                family_name: familyName,
              }
            }
            return category
          }) ?? []

        return {
          ..._matchingCategoriesResult,
          matching_categories: updatedCategories,
        }
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }

  const handleSubFamilyUpdate = async (
    microCategoryId: string,
    subFamilyName: string,
  ) => {
    if (isTestMode) return

    try {
      console.log(`Micro Category ID: ${microCategoryId}`)
      console.log(
        `Updating sub-family ${microCategoryId} with name ${subFamilyName}`,
      )
      const result = await UpdateDimStoreArticleCategoriesMutation({
        variables: {
          input: {
            dim_store_micro_category_id: microCategoryId,
            sub_family_name: subFamilyName,
          },
        },
      })

      if (!result.data?.updateDimStoreArticleCategories) {
        throw new Error("Failed to update sub-family")
      }

      setMatchingCategoriesResult((_matchingCategoriesResult) => {
        if (!_matchingCategoriesResult) return _matchingCategoriesResult

        const updatedCategories =
          _matchingCategoriesResult.matching_categories?.map((category) => {
            if (category.dim_store_micro_category_id === microCategoryId) {
              return {
                ...category,
                sub_family_name: subFamilyName,
              }
            }
            return category
          }) ?? []

        return {
          ..._matchingCategoriesResult,
          matching_categories: updatedCategories,
        }
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }

  const handleResetFamily = async (familyId: string) => {
    if (isTestMode) return

    try {
      const result = await ResetDimStoreArticleCategoriesMutation({
        variables: {
          input: {
            dim_store_family_id: familyId,
          },
        },
      })

      if (!result.data?.resetDimStoreArticleCategories) {
        throw new Error("Failed to reset family")
      }

      // Update the local state to reflect the reset
      setMatchingCategoriesResult((_matchingCategoriesResult) => {
        if (!_matchingCategoriesResult) return _matchingCategoriesResult

        const updatedCategories =
          _matchingCategoriesResult.matching_categories?.map((category) => {
            if (category.dim_store_family_id === familyId) {
              return {
                ...category,
                family_name:
                  result.data?.resetDimStoreArticleCategories
                    .initial_family_name ?? "",
              }
            }
            return category
          }) ?? []

        return {
          ..._matchingCategoriesResult,
          matching_categories: updatedCategories,
        }
      })

      toast.success("Famille réinitialisée avec succès")
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Échec de la réinitialisation de la famille")
    }
  }

  const handleResetSubFamily = async (microCategoryId: string) => {
    if (isTestMode) return

    try {
      const result = await ResetDimStoreArticleCategoriesMutation({
        variables: {
          input: {
            dim_store_micro_category_id: microCategoryId,
          },
        },
      })

      if (!result.data?.resetDimStoreArticleCategories) {
        throw new Error("Failed to reset sub-family")
      }

      // Update the local state to reflect the reset
      setMatchingCategoriesResult((_matchingCategoriesResult) => {
        if (!_matchingCategoriesResult) return _matchingCategoriesResult

        const updatedCategories =
          _matchingCategoriesResult.matching_categories?.map((category) => {
            if (category.dim_store_micro_category_id === microCategoryId) {
              return {
                ...category,
                sub_family_name:
                  result.data?.resetDimStoreArticleCategories
                    .initial_sub_family_name ?? "",
              }
            }
            return category
          }) ?? []

        return {
          ..._matchingCategoriesResult,
          matching_categories: updatedCategories,
        }
      })

      toast.success("Sous-famille réinitialisée avec succès")
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Échec de la réinitialisation de la sous-famille")
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getMatchingReferences()
        if (result.data?.getMatchingReferences.error !== null) {
          throw new Error(result.data?.getMatchingReferences.error?.message)
        }

        setMatchingReferenceResult(result?.data.getMatchingReferences)

        const categoriesResult = await getMatchingCategories()
        if (categoriesResult.data?.getMatchingCategories.error !== null) {
          throw new Error(
            categoriesResult.data?.getMatchingCategories.error?.message,
          )
        }

        setMatchingCategoriesResult(
          categoriesResult?.data.getMatchingCategories,
        )
      } catch (error) {
        console.error(error)
        captureException(error)
        toast.error("Aucune donnée")
      }
    }
    fetchData()
  }, [dispatch, getMatchingReferences, getMatchingCategories])

  const filteredReferences = useMemo(() => {
    return matchingReferenceResult?.matching_references
      ?.filter(
        (_reference) =>
          _reference.article_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          _reference.order_code?.includes(searchValue),
      )
      .sort((a, b) => {
        return a.article_name.localeCompare(b.article_name)
      })
  }, [matchingReferenceResult?.matching_references, searchValue])

  const categories = useMemo(() => {
    const groupedByFamily =
      matchingCategoriesResult?.matching_categories?.reduce(
        (acc, category) => {
          const familyId = category.dim_store_family_id
          const familyName = category.family_name ?? "Sans famille"

          if (!acc[familyId]) {
            acc[familyId] = {
              family_id: familyId,
              family_name: familyName,
              micro_categories: new Map(),
            }
          }

          const microCategoryId = category.dim_store_micro_category_id
          const subFamilyName = category.sub_family_name ?? "Sans sous-famille"

          acc[familyId].micro_categories.set(microCategoryId, {
            sub_family_name: subFamilyName,
            dim_store_micro_category_id: microCategoryId,
          })

          return acc
        },
        {} as Record<
          string,
          {
            family_id: string
            family_name: string
            micro_categories: Map<
              string,
              {
                sub_family_name: string
                dim_store_micro_category_id: string
              }
            >
          }
        >,
      )

    return Object.values(groupedByFamily ?? {})
      .map((family) => ({
        family_id: family.family_id,
        family_name: family.family_name,
        micro_categories: Array.from(family.micro_categories.values()),
      }))
      .sort((a, b) => a.family_name.localeCompare(b.family_name))
  }, [matchingCategoriesResult?.matching_categories])

  const saleNames = useMemo(() => {
    const _saleNames = matchingReferenceResult?.matching_references?.map(
      (_reference) => _reference.sale_name_ida,
    )
    if (saleNameQueryValue === "") return []
    return removeDuplicates(
      _saleNames?.filter((saleName) => saleName.includes(saleNameQueryValue)) ??
        [],
    )
  }, [matchingReferenceResult?.matching_references, saleNameQueryValue])

  const toggleFamilyExpansion = (familyName: string) => {
    setExpandedFamilies((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(familyName)) {
        newSet.delete(familyName)
      } else {
        newSet.add(familyName)
      }
      return newSet
    })
  }

  return (
    <div className="p-8 h-screen flex flex-col gap-2 text-zinc-800">
      <div className="flex items-center gap-8 mb-4">
        <Link to="/account/calendar">
          <ArrowLeftIcon className="w-6 h-6" />
        </Link>
        <p className="text-2xl text-black font-bold">
          Personnalisation des articles
        </p>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex gap-2">
          <Button
            onClick={() => setActiveView("articles")}
            variant={activeView === "articles" ? "default" : "outline"}
            className="bg-green-500 text-white hover:bg-green-600"
          >
            Articles
          </Button>
          <Button
            onClick={() => setActiveView("categories")}
            variant={activeView === "categories" ? "default" : "outline"}
            className="bg-green-500 text-white hover:bg-green-600"
          >
            Catégories
          </Button>
        </div>
        <SearchInput
          name="search"
          type="text"
          placeholder="Rechercher"
          value={searchValue}
          icon={<SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {typeof matchingReferenceResult?.last_update === "string" && (
          <p className="text-zinc-500 text-sm">
            Dernière modification :{" "}
            {getTimeAgo(new Date(matchingReferenceResult.last_update))}
          </p>
        )}
      </div>
      {activeView === "articles" ? (
        <>
          <div className="grid grid-cols-7 bg-zinc-100 p-2 rounded font-bold text-sm">
            <p>Nom</p>
            <p>Code de commande</p>
            <p>Fournisseur</p>
            <p>Famille</p>
            <p>Sous-famille</p>
            <p>PV</p>
            <p>Code de vente</p>
          </div>
          {(matchingCategoriesLoading || matchingReferencesLoading) && (
            <div className="flex justify-center">
              <Spinner invertColors className="w-6" />
            </div>
          )}
          <Virtuoso
            className="h-full"
            data={filteredReferences}
            itemContent={(_, reference) => (
              <div>
                <div className="grid grid-cols-7 items-center gap-4 py-2 px-4">
                  <Input
                    name="article_name"
                    type="text"
                    value={reference.article_name}
                    onChange={(e) => {
                      const newValue = e.target.value
                      setMatchingReferenceResult(
                        (_matchingReferenceResult) => ({
                          ..._matchingReferenceResult,
                          matching_references:
                            _matchingReferenceResult?.matching_references?.map(
                              (ref) =>
                                ref.order_id === reference.order_id
                                  ? { ...ref, article_name: newValue }
                                  : ref,
                            ),
                        }),
                      )
                      handleArticleUpdate(reference, { article_name: newValue })
                    }}
                    className="w-full p-1 text-sm"
                  />
                  <p>{reference.order_code}</p>
                  <p>{reference.supplier_name}</p>
                  <Input
                    name="family_name"
                    type="text"
                    value={reference.family_name ?? ""}
                    onChange={(e) => {
                      const newValue = e.target.value

                      setMatchingReferenceResult(
                        (_matchingReferenceResult) => ({
                          ..._matchingReferenceResult,
                          matching_references:
                            _matchingReferenceResult?.matching_references?.map(
                              (ref) =>
                                ref.order_id === reference.order_id
                                  ? { ...ref, family_name: newValue }
                                  : ref,
                            ),
                        }),
                      )
                      handleArticleUpdate(reference, { family_name: newValue })
                    }}
                    className="w-full p-1 text-sm"
                  />
                  <Input
                    name="sub_family_name"
                    type="text"
                    value={reference.sub_family_name ?? ""}
                    onChange={(e) => {
                      const newValue = e.target.value
                      setMatchingReferenceResult(
                        (_matchingReferenceResult) => ({
                          ..._matchingReferenceResult,
                          matching_references:
                            _matchingReferenceResult?.matching_references?.map(
                              (ref) =>
                                ref.order_id === reference.order_id
                                  ? { ...ref, sub_family_name: newValue }
                                  : ref,
                            ),
                        }),
                      )
                      handleArticleUpdate(reference, {
                        sub_family_name: newValue,
                      })
                    }}
                    className="w-full p-1 text-sm"
                  />
                  <Input
                    name="pv_matching"
                    type="number"
                    step="0.01"
                    value={reference.article_pv ?? 0}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value)
                      console.log(`Type in input : ${typeof newValue}`)
                      setMatchingReferenceResult(
                        (_matchingReferenceResult) => ({
                          ..._matchingReferenceResult,
                          matching_references:
                            _matchingReferenceResult?.matching_references?.map(
                              (ref) =>
                                ref.order_id === reference.order_id
                                  ? { ...ref, article_pv: newValue }
                                  : ref,
                            ),
                        }),
                      )
                      handleArticleUpdate(reference, { article_pv: newValue })
                    }}
                    className="w-full p-1 text-sm"
                  />
                  <Combobox
                    value={
                      selectedReference?.orderId === reference.order_id
                        ? selectedReference.value
                        : reference.sale_name_ida
                    }
                    onChange={async (value) => {
                      if (value === reference.sale_name_ida) return
                      setSelectedReference({
                        orderId: reference.order_id,
                        value: value ?? "",
                      })
                      setSaleNameQueryValue("")

                      if (isTestMode) return
                      await handleArticleUpdate(reference, {
                        sale_name_ida: value ?? "",
                      })
                    }}
                  >
                    <div className="relative">
                      <div className="relative w-full cursor-default overflow-hidden rounded text-left shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                          className="w-full bg-transparent rounded p-2 pr-10 border-2 border-zinc-400 focus:border-green-800 text-gray-900"
                          displayValue={(saleName) => saleName as string}
                          onChange={(event) =>
                            setSaleNameQueryValue(event.target.value)
                          }
                          placeholder="Aucun code de vente"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setSaleNameQueryValue("")}
                      >
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white p-4 shadow focus:outline-none">
                          <p className="text-zinc-500 font-medium px-2">
                            Sélectionnez un code
                          </p>
                          {saleNames.length === 0 &&
                          saleNameQueryValue !== "" ? (
                            <p className="relative cursor-default select-none px-2 py-1 text-gray-700">
                              Aucun résultat
                            </p>
                          ) : (
                            <>
                              <Combobox.Option
                                className={({ active }) =>
                                  `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                                }
                                value={reference.sale_name_ida}
                              >
                                <span className="block truncate w-fit border border-neutral-200 bg-zinc-100 px-2 rounded font-medium">
                                  {reference.sale_name_ida}
                                </span>
                              </Combobox.Option>
                              {saleNames
                                .filter(
                                  (saleName) =>
                                    saleName !== reference.sale_name_ida,
                                )
                                .slice(0, 4)
                                .map((saleName, i) => (
                                  <Combobox.Option
                                    key={i}
                                    className={({ active }) =>
                                      `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                                    }
                                    value={saleName}
                                  >
                                    <span className="block truncate w-fit font-medium">
                                      {saleName}
                                    </span>
                                  </Combobox.Option>
                                ))}
                            </>
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
                <hr />
              </div>
            )}
          />
        </>
      ) : (
        <>
          <div className="bg-zinc-100 p-2 rounded font-bold text-sm">
            <p>Catégories</p>
          </div>
          {(matchingCategoriesLoading || matchingReferencesLoading) && (
            <div className="flex justify-center">
              <Spinner invertColors className="w-6" />
            </div>
          )}
          <Virtuoso
            className="h-full"
            data={categories}
            itemContent={(_, category) => (
              <div className="py-2 px-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => toggleFamilyExpansion(category.family_name)}
                  >
                    {expandedFamilies.has(category.family_name) ? (
                      <ChevronDownIcon className="h-5 w-5" />
                    ) : (
                      <ChevronRightIcon className="h-5 w-5" />
                    )}
                  </button>
                  {editingCategory?.dim_store_micro_category_id ===
                  category.micro_categories[0].dim_store_micro_category_id ? (
                    <Input
                      type="text"
                      value={editingCategory.value}
                      onChange={(e) =>
                        setEditingCategory((prev) => ({
                          ...prev!,
                          value: e.target.value,
                        }))
                      }
                      onBlur={() => {
                        handleFamilyUpdate(
                          category.family_id,
                          editingCategory.value,
                        )
                        setEditingCategory(null)
                      }}
                      autoFocus
                      className="w-full p-1 text-sm"
                    />
                  ) : (
                    <>
                      <span>{category.family_name}</span>
                      <button
                        onClick={() =>
                          setEditingCategory({
                            dim_store_micro_category_id:
                              category.micro_categories[0]
                                .dim_store_micro_category_id,
                            value: category.family_name,
                          })
                        }
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                      <Button
                        onClick={() => handleResetFamily(category.family_id)}
                        variant="outline"
                        size="sm"
                        className="ml-2"
                      >
                        Réinitialiser
                      </Button>
                    </>
                  )}
                </div>
                {expandedFamilies.has(category.family_name) && (
                  <div className="ml-6 mt-2">
                    {category.micro_categories.map((microCategory) => (
                      <div
                        key={microCategory.dim_store_micro_category_id}
                        className="flex items-center gap-2 py-1"
                      >
                        {editingCategory?.dim_store_micro_category_id ===
                        microCategory.dim_store_micro_category_id ? (
                          <Input
                            type="text"
                            value={editingCategory.value}
                            onChange={(e) =>
                              setEditingCategory({
                                dim_store_micro_category_id:
                                  microCategory.dim_store_micro_category_id,
                                value: e.target.value,
                              })
                            }
                            onBlur={() => {
                              handleSubFamilyUpdate(
                                microCategory.dim_store_micro_category_id,
                                editingCategory.value,
                              )
                              setEditingCategory(null)
                            }}
                            autoFocus
                            className="w-full p-1 text-sm"
                          />
                        ) : (
                          <>
                            <span>{microCategory.sub_family_name}</span>
                            <button
                              onClick={() =>
                                setEditingCategory({
                                  dim_store_micro_category_id:
                                    microCategory.dim_store_micro_category_id,
                                  value: microCategory.sub_family_name,
                                })
                              }
                            >
                              <PencilIcon className="h-4 w-4" />
                            </button>
                            <Button
                              onClick={() =>
                                handleResetSubFamily(
                                  microCategory.dim_store_micro_category_id,
                                )
                              }
                              variant="outline"
                              size="sm"
                              className="ml-2"
                            >
                              Réinitialiser
                            </Button>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                <hr className="mt-2" />
              </div>
            )}
          />
        </>
      )}
    </div>
  )
}
