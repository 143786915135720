export enum DataSynchronizationStatus {
  SYNCHRONIZED = "synchronized",
  UNSYNCHRONIZED = "unsynchronized",
  FAILURE = "failure",
}

export interface ConnectionReducerState {
  online: boolean
  dataSynchronizationStatus: DataSynchronizationStatus
}

export const defaultConnectionReducerState: ConnectionReducerState = {
  online: true,
  dataSynchronizationStatus: DataSynchronizationStatus.SYNCHRONIZED,
}

interface SetOnlineAction {
  type: "setOnline"
  payload: ConnectionReducerState["online"]
}

interface SetDataSynchronizationStatusAction {
  type: "setDataSynchronizationStatus"
  payload: ConnectionReducerState["dataSynchronizationStatus"]
}

export type ConnectionReducerAction =
  | SetOnlineAction
  | SetDataSynchronizationStatusAction

const connectionReducer = (
  state: ConnectionReducerState = defaultConnectionReducerState,
  action: ConnectionReducerAction,
): ConnectionReducerState => {
  switch (action.type) {
    case "setOnline":
      return {
        ...state,
        online: action.payload,
      }
    case "setDataSynchronizationStatus":
      return {
        ...state,
        dataSynchronizationStatus: action.payload,
      }
    default:
      return state
  }
}

export default connectionReducer
