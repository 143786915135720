import { RecapSortOption } from "@/reducers/userInterfaceReducer"
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline"

interface SortIconProps {
  column: RecapSortOption
  sortedColumn: RecapSortOption | null
  sortOrder: "asc" | "desc"
}

const SortIcon = ({ column, sortedColumn, sortOrder }: SortIconProps) => {
  const isSortedColumn = column === sortedColumn
  const iconClass = `w-4 h-4 ${
    isSortedColumn ? "text-green-500" : "text-gray-500"
  }`

  return isSortedColumn && sortOrder === "asc" ? (
    <ArrowDownIcon className={iconClass} />
  ) : isSortedColumn && sortOrder === "desc" ? (
    <ArrowUpIcon className={iconClass} />
  ) : (
    <ArrowDownIcon className={iconClass} />
  )
}

export default SortIcon
