import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid"
import { Label } from "../../ui/Label"
import { ReactNode } from "react"

interface DropdownButtonProps {
  isOpen: boolean
  onClick: () => void
  icon: ReactNode
  text: string
}

export function DropdownButton({
  isOpen,
  onClick,
  icon,
  text,
}: DropdownButtonProps) {
  return (
    <button onClick={onClick} className="ml-auto">
      <Label
        color={isOpen ? "black" : "white"}
        variant={isOpen ? "filled" : "normal"}
        className="py-0.5 px-1 mx-1 rounded-[5px] shadow-sm flex items-center text-sm"
        rightIcon={
          isOpen ? (
            <ChevronUpIcon className="w-3.5 h-3.5" />
          ) : (
            <ChevronDownIcon className="w-3.5 h-3.5" />
          )
        }
        leftIcon={icon}
      >
        {text}
      </Label>
    </button>
  )
}
