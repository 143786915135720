import { Fragment, useEffect, useState } from "react"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"
import { Input } from "@/ui/Input"
import { useGetUsersQuery, User } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"
import { UserForm } from "@/components/Form/UserForm"
import { PlusIcon } from "@heroicons/react/24/outline"
import { SearchIcon } from "@/ui/icons/SearchIcon"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"

export default function UserPage(): JSX.Element {
  const [users, setUserList] = useState<User[]>([])
  const { data, loading } = useGetUsersQuery()
  const [searchTerm, setSearchTerm] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  useEffect(() => {
    setUserList(data?.getUsers?.users ?? [])
  }, [data?.getUsers?.users])

  const handleCreateUser = (user: User) => {
    setIsModalOpen(false)
    setUserList((prev) => [...prev, user])
  }

  const filteredUsers = users.filter((user) =>
    Object.values(user).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )
  const setRowGrey = (index: number) =>
    `${index % 2 !== 0 ? "bg-gray-100" : ""}`

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center gap-8 mb-4">
        <Link to="/account/calendar">
          <ArrowLeftIcon className="w-6 h-6" />
        </Link>
        <p className="text-2xl text-black font-bold">Users</p>
      </div>
      <div className="flex justify-between mb-4">
        <Button onClick={() => setIsModalOpen(true)}>
          <PlusIcon className="mr-2 h-4 w-4" />
          Add
        </Button>
        <a href="https://accounts.auth0.com/teams/team-np52dt0/">
          <Button>Auth dashboard</Button>
        </a>
        <Modal
          title="Create New User"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="grid gap-4 py-4">
            <UserForm handleFormSubmit={handleCreateUser} />
          </div>
        </Modal>
        <div className="relative">
          <Input
            placeholder="Search users..."
            className="flex h-9 rounded-md border border-input max-w-sm bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            name="search"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <SearchIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : (
        <div className="col-span-4 grid grid-cols-[1fr,1fr,1fr,1fr]">
          <div className="col-span-4 grid grid-cols-[1fr,1fr,1fr,1fr] font-bold bg-gray-200 p-2 rounded-t-md">
            <p>Email</p>
            <p>Store ID</p>
            <p>Company Name</p>
            <p>Store Name</p>
          </div>
          {filteredUsers.map((user, index) => (
            <Fragment key={user.email}>
              <p className={setRowGrey(index)}>{user.email}</p>
              <p className={setRowGrey(index)}>{user.store_id}</p>
              <p className={setRowGrey(index)}>{user.company_name}</p>
              <p className={setRowGrey(index)}>{user.store_name}</p>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
