import { twJoin } from "tailwind-merge"
import { getQuarterValue } from "../../utils/getQuarterValue"

interface SaleRowProps {
  saleHistoricalQuantity: number | undefined
  isPromoToday: boolean
}

export function SaleRow({
  saleHistoricalQuantity,
  // isPromoToday, TODO: Uncomment this line when promo will be ready
}: SaleRowProps) {
  return (
    <p
      className={twJoin(
        "w-full flex justify-center items-center gap-1",
        // isPromoToday && "text-cyan-800", TODO: Uncomment this line when promo will be ready
      )}
    >
      {saleHistoricalQuantity !== undefined ? (
        <>
          {(getQuarterValue(saleHistoricalQuantity).value ?? 0) > 0 ||
          getQuarterValue(saleHistoricalQuantity).decimal === undefined
            ? getQuarterValue(saleHistoricalQuantity).value
            : null}
          {getQuarterValue(saleHistoricalQuantity, "w-4 h-4").icon}
        </>
      ) : (
        "-"
      )}
    </p>
  )
}
