import { DispatchActionType, StateType } from "@/types"
import { RefreshAlertIcon } from "@/ui/AlertIcons"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"
import { useDispatch, useSelector } from "react-redux"

interface OutdatedDataModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  refreshApplication: () => void
  setIsOfflineRefreshModalOpen: (value: boolean) => void
}

export function OutdatedDataModal({
  isOpen,
  setIsOpen,
  refreshApplication,
  setIsOfflineRefreshModalOpen,
}: OutdatedDataModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  return (
    <Modal
      icon={<RefreshAlertIcon />}
      title="Vos données ne sont pas à jour."
      open={isOpen}
      hideCloseButton
    >
      <p className="text-[#667085] text-sm font-normal text-center">
        Les données de l&apos;application ne sont pas à jour. Veuillez cliquer
        sur le bouton ci-dessous pour les mettre à jour.
      </p>
      <Button
        onClick={() => {
          if (!online) {
            setIsOfflineRefreshModalOpen(true)
            setIsOpen(false)
            return
          }
          refreshApplication()
        }}
        className="w-full h-11 text-base mt-4 md:mt-8"
        color="green"
      >
        Mettre à jour
      </Button>
      <Button
        onClick={() => {
          dispatch({
            type: "setRefreshModalCloseDate",
            payload: new Date().toString(),
          })
          setIsOpen(false)
        }}
        className="w-full h-11 text-base"
        color="ghost"
      >
        Annuler
      </Button>
    </Modal>
  )
}
