import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { GroupedPromotion } from "../EngagementPromoPage"
import { Fragment } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Img } from "react-image"
import { toFixedNumber } from "@/utils/toFixedNumber"
import { getUnit } from "@/utils/getUnit"
import { EngagementPromoDrawer } from "./EngagementPromoDrawer"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

interface EngagementPromoContentProps {
  groupedPromotions: GroupedPromotion[]
}

export function EngagementPromoContent({
  groupedPromotions,
}: EngagementPromoContentProps) {
  const { storeId, companyId, storeSettings, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  return (
    <div className="h-full px-2 md:px-7 lg:px-14 flex flex-col gap-2 flex-1 overflow-y-auto">
      {groupedPromotions?.map((groupedPromotion, groupedPromotionIndex) => {
        const dates = new Array(
          getDiffBetweenDates(
            new Date(groupedPromotion.start_date),
            new Date(groupedPromotion.end_date),
          ),
        )
          .fill(0)
          .map((_, index) => {
            const date = new Date(groupedPromotion.start_date)
            date.setDate(date.getDate() + index)
            return date
          })

        return (
          <Fragment key={groupedPromotionIndex}>
            <Card id={`${groupedPromotionIndex}`}>
              <CardHeader>
                <CardTitle>{groupedPromotion.operation_name}</CardTitle>
                <CardDescription className="mt-0 pt-0">
                  Du{" "}
                  {Intl.DateTimeFormat("fr-FR").format(
                    new Date(groupedPromotion.start_date),
                  )}{" "}
                  au{" "}
                  {Intl.DateTimeFormat("fr-FR").format(
                    new Date(groupedPromotion.end_date),
                  )}
                </CardDescription>
              </CardHeader>
              <CardContent className="flex flex-col gap-4">
                {groupedPromotion.promotions.map((promotion) => {
                  const margin =
                    typeof promotion.pa === "number" &&
                    promotion.pa > 0 &&
                    typeof promotion.pv === "number" &&
                    promotion.pv > 0
                      ? (promotion.pv - promotion.pa) / promotion.pv
                      : undefined

                  const formattedMargin =
                    margin !== undefined
                      ? new Intl.NumberFormat("fr-FR", {
                          maximumFractionDigits: 0,
                          style: "percent",
                        }).format(margin)
                      : ""

                  return (
                    <div
                      key={promotion.id}
                      className="flex flex-col md:flex-row gap-4 justify-between items-center pb-4 border-b last:border-b-0"
                    >
                      <div className="flex items-center gap-4">
                        {typeof promotion.photo_id === "string" && (
                          <Img
                            src={`https://storage.googleapis.com/references_images/${companyId}/${promotion?.photo_id}.jpeg`}
                            className="size-20 rounded object-cover"
                          />
                        )}
                        <div className="flex flex-col gap-2">
                          <p>{promotion.order_name}</p>
                          <div className="flex gap-8">
                            <p>
                              Colis {toFixedNumber(promotion.colisage)}{" "}
                              {getUnit(promotion.unit)}
                            </p>
                            {storeSettings?.view_pa === true && (
                              <p>
                                PA :{" "}
                                {new Intl.NumberFormat("fr-FR", {
                                  maximumFractionDigits: 2,
                                  style: "currency",
                                  currency: storeCurrency ?? "EUR",
                                }).format(promotion.pa)}
                              </p>
                            )}
                            {storeSettings?.view_pv === true && (
                              <p>
                                PV :{" "}
                                {new Intl.NumberFormat("fr-FR", {
                                  maximumFractionDigits: 2,
                                  style: "currency",
                                  currency: storeCurrency ?? "EUR",
                                }).format(promotion.pv)}
                              </p>
                            )}
                            {formattedMargin && <p>{formattedMargin}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 items-center">
                        <p>Quantité totale engagée</p>
                        <p>
                          {promotion.engagements.reduce(
                            (acc, curr) => acc + curr.quantity,
                            0,
                          )}
                        </p>
                        <EngagementPromoDrawer
                          promotion={promotion}
                          dates={dates}
                          isTestMode={isTestMode}
                          storeId={storeId!}
                        />
                      </div>
                    </div>
                  )
                })}
              </CardContent>
            </Card>
            {/* Separator between groups */}
            {groupedPromotionIndex < groupedPromotions.length - 1 && (
              <div className="p-6">
                <hr />
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
