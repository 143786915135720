import { DateTime } from "luxon"

/**
 * Récupère tous les jours d'un mois à partir d'une date donnée, incluant les jours nécessaires du mois précédent
 * pour que le tableau commence par un lundi, et ajoutant les jours du mois suivant pour compléter la semaine en cours
 * à la fin du mois.
 *
 * @param currentDate Un objet DateTime représentant n'importe quel jour dans le mois ciblé.
 * @returns Un tableau contenant des objets DateTime pour chaque jour nécessaire pour aligner le début du mois avec le lundi,
 * les jours du mois actuel, et les jours nécessaires du mois suivant pour compléter la semaine.
 */
const getFullCalendarMonth = (currentDate: DateTime): DateTime[] => {
  const startOfMonth = currentDate.startOf("month")
  const endOfMonth = currentDate.endOf("month")

  const days: DateTime[] = []
  const startDayOfWeek = startOfMonth.weekday
  // Calcul de l'offset pour le début du mois
  const offsetStart = startDayOfWeek === 7 ? 6 : startDayOfWeek - 1

  // Ajouter les jours du mois précédent
  const daysFromPreviousMonth = startOfMonth.minus({ days: offsetStart })
  for (let i = 0; i < offsetStart; i++) {
    days.push(daysFromPreviousMonth.plus({ days: i }))
  }

  // Ajouter les jours du mois actuel
  let day = startOfMonth
  while (day <= endOfMonth) {
    days.push(day)
    day = day.plus({ days: 1 })
  }

  // Calculer et ajouter les jours nécessaires du mois suivant pour compléter la semaine
  const endDayOfWeek = endOfMonth.weekday
  const offsetEnd = endDayOfWeek === 7 ? 0 : 7 - endDayOfWeek
  for (let i = 1; i <= offsetEnd; i++) {
    days.push(endOfMonth.plus({ days: i }))
  }

  return days
}

export { getFullCalendarMonth }
