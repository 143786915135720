import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { SortOption } from "@/reducers/userInterfaceReducer"
import {
  alphabeticalSort,
  bestSellerSort,
  categoriesSort,
  customBooleanComparator,
  customNullLastComparator,
  numericalSort,
} from "@/utils/sort"
import {
  computeQuantityActual,
  computeStockQuantity,
} from "@/utils/computeInventoriesValues"
import { StoreReducerState } from "@/reducers/storeReducer"
import { MercurialeRanking } from "./__generated__/graphql"

export function filterInventory(
  inventory: Partial<AllMercurialInfo>[],
  familyName?: string,
  subFamilyName?: string,
  suppliersIds?: string[],
  companyName?: string | null,
  filteredReferences?: string[],
  storeTypologies?: string[] | null,
): Partial<AllMercurialInfo>[] {
  return inventory.filter(
    (item) =>
      // Filter by family
      (familyName === undefined ||
        familyName === "autres" ||
        item.family_name?.toLowerCase() === familyName) &&
      // Filter by subfamily
      (subFamilyName === undefined ||
        item.sub_family_name?.toLowerCase() === subFamilyName) &&
      // Filter by supplier
      (suppliersIds === undefined ||
        suppliersIds.length === 0 ||
        (suppliersIds.includes(item.supplier_id ?? "") &&
          companyName === "biomonde")) &&
      // Filter by references to verify
      ((filteredReferences ?? []).length === 0 ||
        filteredReferences?.includes(item.mercuriale_id ?? "")) &&
      // Filter by typology setting
      (storeTypologies === undefined ||
        storeTypologies === null ||
        storeTypologies.includes(item.typology ?? "")),
  )
}

export function sortInventory(
  inventory: Partial<AllMercurialInfo>[],
  sortOption: SortOption,
  storeSettings: StoreReducerState["storeSettings"],
  rankings?: MercurialeRanking[],
): Partial<AllMercurialInfo>[] {
  return inventory.sort((a, b) => {
    switch (sortOption) {
      case SortOption.Alphabetical:
        return alphabeticalSort(
          a.mercuriale_name ?? "",
          b.mercuriale_name ?? "",
        )
      case SortOption.BestSeller:
        return bestSellerSort(a, b, storeSettings?.must_see_sorting !== false)
      case SortOption.Breakage:
        return numericalSort(
          a.breakage_percentage ?? 0,
          b.breakage_percentage ?? 0,
          "desc",
        )
      case SortOption.Promotion:
        return customBooleanComparator(a.promotion, b.promotion)
      case SortOption.QuantityActual:
        return numericalSort(
          computeQuantityActual(a, storeSettings?.use_kg_pce) ?? 0,
          computeQuantityActual(b, storeSettings?.use_kg_pce) ?? 0,
          "desc",
        )
      case SortOption.StockQuantity:
        return numericalSort(
          computeStockQuantity(a, storeSettings?.use_kg_pce) ?? 0,
          computeStockQuantity(b, storeSettings?.use_kg_pce) ?? 0,
          "desc",
        )
      case SortOption.SortIndex: {
        const sortIndexA = rankings?.find(
          (ranking: MercurialeRanking) =>
            ranking.mercuriale_id === a.mercuriale_id,
        )?.ranking_filter
        const sortIndexB = rankings?.find(
          (ranking: MercurialeRanking) =>
            ranking.mercuriale_id === b.mercuriale_id,
        )?.ranking_filter
        return customNullLastComparator(sortIndexA, sortIndexB)
      }
      case SortOption.Typology:
        return alphabeticalSort(a.typology ?? "", b.typology ?? "")
      case SortOption.Categories:
        return categoriesSort(a, b, storeSettings?.categories_orders)
      default:
        return numericalSort(
          a.last_weeks_sales ?? 0,
          b.last_weeks_sales ?? 0,
          "desc",
        )
    }
  })
}
