export interface TrainingModeReducerState {
  enable: boolean
}

export const defaultTrainingModeReducerState: TrainingModeReducerState = {
  enable: false,
}

interface SetTrainingModeAction {
  type: "setTrainingMode"
  payload: TrainingModeReducerState["enable"]
}

export type TrainingModeReducerAction = SetTrainingModeAction

const trainingModeReducer = (
  state: TrainingModeReducerState = defaultTrainingModeReducerState,
  action: TrainingModeReducerAction,
): TrainingModeReducerState => {
  switch (action.type) {
    case "setTrainingMode":
      return {
        ...state,
        enable: action.payload,
      }
    default:
      return state
  }
}

export default trainingModeReducer
