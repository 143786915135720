interface ShelfFloorSizeExtraElementProps {
  value: number
  ringColor?: string
}

export function ShelfFloorSizeExtraElement({
  value,
  ringColor,
}: ShelfFloorSizeExtraElementProps) {
  if (ringColor === "yellow") {
    return (
      <p className="text-xxs md:text-xxxs text-center font-bold text-yellow-500 md:text-white md:bg-yellow-500 md:px-1 md:py-1 md:rounded-sm">
        À RENSEIGNER
      </p>
    )
  }

  if (value === 0) {
    return (
      <div className="flex items-center gap-1">
        <div className="size-5 bg-neutral-500 text-white rounded-full flex md:hidden justify-center items-center text-xs">
          !
        </div>
        <p className="text-xs md:text-xxs font-bold text-neutral-500">
          Pas de capacité
        </p>
      </div>
    )
  }
}
