// components/StoresTable.tsx
import React from "react"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import StoreRow from "./StoreRow"
import { Store } from "@/utils/__generated__/graphql"
import { Schedule } from "@/types"

interface StoresTableProps {
  stores: Store[]
  days: string[]
  schedule: Schedule
  selectedSupplier: string
  suppliers: { supplierId: string; supplierName: string }[]
  onCheckboxChange: (
    supplierId: string,
    storeId: string,
    day: string,
    numberOfOrders: number,
  ) => void
  onSetArrivalDay: (
    supplierId: string,
    storeId: string,
    orderDay: string,
    arrivalDay: string,
    numberOfOrders: number,
  ) => void
  onAddOrder: (storeId: string, day: string) => void
  openPopovers: Record<string, boolean>
  setOpenPopovers: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  getPopoverKey: (storeId: string, day: string, orderNumber: number) => string
  selectedOrderNumber: Record<string, number>
  setSelectedOrderNumber: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >
}

const StoresTable: React.FC<StoresTableProps> = ({
  stores,
  days,
  schedule,
  selectedSupplier,
  suppliers,
  onCheckboxChange,
  onSetArrivalDay,
  onAddOrder,
  openPopovers,
  setOpenPopovers,
  getPopoverKey,
  selectedOrderNumber,
  setSelectedOrderNumber,
}) => {
  return (
    <div className="overflow-auto h-full">
      <Table className="w-full">
        <TableHeader className="sticky top-0 bg-white shadow z-10">
          <TableRow>
            <TableHead className="w-48 min-w-[200px]">Magasin</TableHead>
            {days.map((day) => (
              <TableHead
                key={day}
                className="w-32 min-w-[100px] bg-white text-center"
              >
                {day}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {stores.map((store) => (
            <StoreRow
              key={store.id}
              store={store}
              days={days}
              schedule={schedule}
              selectedSupplier={selectedSupplier}
              suppliers={suppliers}
              onCheckboxChange={onCheckboxChange}
              onSetArrivalDay={onSetArrivalDay}
              onAddOrder={onAddOrder}
              openPopovers={openPopovers}
              setOpenPopovers={setOpenPopovers}
              getPopoverKey={getPopoverKey}
              selectedOrderNumber={selectedOrderNumber}
              setSelectedOrderNumber={setSelectedOrderNumber}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default StoresTable
