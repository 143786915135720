import { type MercurialeInfo } from "./__generated__/graphql"

export function getUnit(unit: MercurialeInfo["unit"]): string {
  switch (unit?.toLowerCase()) {
    case "kg":
    case "kilo":
      return "kg"
    case "pc":
    case "piece":
    case "pce":
      return "pce"
    default:
      return ""
  }
}
