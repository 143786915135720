import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers/rootReducer"
import { type PersistConfig, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import LogRocket from "logrocket"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { type StateType } from "./types"
import mercurialeSaga from "./sagas/mercurialeSaga"

const sagaMiddleware = createSagaMiddleware()
const logRocketMiddleware = LogRocket.reduxMiddleware()

const persistConfig: PersistConfig<StateType> = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["authReducer", "connectionReducer"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.DEV,
  middleware: [thunk, sagaMiddleware, logRocketMiddleware],
})

sagaMiddleware.run(mercurialeSaga)

export const persistor = persistStore(store)
