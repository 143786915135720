import { Img } from "react-image"
import { twMerge } from "tailwind-merge"
import { usePermissions } from "../hooks/usePermissions"

interface MinimalLogoProps {
  testMode?: boolean
  className?: string
  companyId?: string | null
  franchise?: string | null
}

function IdaLogo({ testMode, className }: MinimalLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 27"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6394 0.00781242C16.6784 2.66618 15.3862 4.12922 13.3185 4.69804C12.6855 4.87212 11.5349 5.02806 10.7851 4.81786C10.7376 1.1499 13.0616 -0.112867 16.6394 0.00781242Z"
        fill="#4BD575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05339 5.14308C9.29477 5.12271 10.8191 5.07854 11.8121 6.32419C13.1794 8.03938 12.7358 10.4773 11.0589 11.5279C10.4215 11.9273 9.73144 12.0586 8.66245 12.0586C8.02276 12.0586 7.25041 12.1344 6.69392 12.0072C5.18209 11.6616 3.52323 10.1368 3.9551 8.01883C4.0222 7.69 4.12046 7.3735 4.2461 7.09448C4.59513 6.31923 5.30911 5.68879 6.11192 5.36561C6.39744 5.25075 6.74527 5.26906 7.05339 5.14308Z"
        fill={testMode ? "#FFDE3B" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9745 5.19478C12.378 5.19478 13.7819 5.19478 15.1854 5.19478C15.9119 5.19478 16.7924 5.11124 17.4107 5.26325C18.4244 5.51265 19.1401 6.08472 19.6189 6.8723C19.8784 7.29922 20.2444 8.02689 20.1324 8.77236C19.8852 10.4189 19.2021 11.3977 17.8558 11.922C17.1926 12.1803 16.2099 12.1274 15.2881 12.1274C13.7763 12.1274 12.2638 12.1274 10.752 12.1274C10.7576 12.1159 10.7634 12.1046 10.7691 12.0932C12.2954 11.6791 13.5772 9.23179 12.7889 7.23177C12.3658 6.1585 11.8073 5.83155 10.9745 5.19478Z"
        fill={testMode ? "#1A0000" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13382 12.418C5.22765 12.4294 7.32216 12.4407 9.41598 12.4522C9.40452 12.4522 9.39322 12.4522 9.38175 12.4522C9.17634 12.5835 8.97093 12.7146 8.76552 12.8459C8.19961 13.2737 7.77115 13.9065 7.49881 14.6261C6.76857 16.5555 8.0565 18.8718 9.45022 19.3164C9.45022 19.3278 9.45022 19.3391 9.45022 19.3506C7.89269 19.3506 6.33464 19.3506 4.77711 19.3506C3.64393 19.3506 2.60215 19.3693 1.88424 18.974C0.252244 18.0753 -0.71764 15.7653 0.651768 13.8387C1.07218 13.2471 1.72675 12.7716 2.50047 12.5378C2.71153 12.4979 2.92276 12.4579 3.13382 12.418Z"
        fill={testMode ? "#FFDE3B" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8368 12.4176C13.0239 12.3976 14.5758 12.3507 15.5441 13.5645C16.9146 15.2822 16.5458 17.7241 14.8252 18.8025C13.9955 19.3225 13.1156 19.3331 11.744 19.3331C11.2676 19.3331 10.7623 19.3605 10.3746 19.2647C8.51563 18.8047 6.97847 16.7295 7.97814 14.4375C8.33127 13.6278 9.042 12.9674 9.87819 12.6401C10.169 12.5263 10.5206 12.5445 10.8368 12.4176Z"
        fill={testMode ? "#1A0000" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6357 12.4175C16.0791 12.4175 17.5231 12.4175 18.9665 12.4175C19.7111 12.4175 20.626 12.3288 21.2603 12.486C22.2401 12.7287 22.9451 13.2729 23.4171 14.0266C23.7228 14.5148 24.1192 15.3001 23.9648 16.2005C23.693 17.7856 22.8414 18.8108 21.4143 19.2303C20.8802 19.3873 20.1603 19.3501 19.48 19.3501C17.8369 19.3501 16.4259 19.2144 14.7898 19.2645C14.7954 19.2531 14.8013 19.2418 14.8069 19.2303C16.1982 18.7462 17.2816 16.3407 16.5358 14.4716C16.2498 13.7549 15.7554 13.1428 15.1664 12.7256C14.9896 12.6229 14.8126 12.5202 14.6357 12.4175Z"
        fill={testMode ? "#FFDE3B" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17311 19.623C9.21558 19.623 11.2587 19.623 13.3012 19.623C13.2956 19.6345 13.2897 19.6458 13.2841 19.6573C12.951 19.7627 12.7081 20.0087 12.4624 20.205C10.7693 21.5578 10.7043 24.5813 12.4282 25.9566C12.6846 26.1611 12.9404 26.4256 13.2841 26.5386C13.2841 26.5442 13.2841 26.55 13.2841 26.5557C11.7266 26.5557 10.1685 26.5557 8.61099 26.5557C7.39238 26.5557 6.34342 26.5666 5.59829 26.1277C4.00361 25.1883 3.16296 22.8578 4.51988 21.0096C4.94782 20.4267 5.60736 19.9719 6.3857 19.7429C6.64811 19.703 6.91069 19.6629 7.17311 19.623Z"
        fill={testMode ? "#1A0000" : "#FF4755"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9096 19.6237C17.0315 19.603 18.4591 19.6323 19.3944 20.8049C19.6767 21.1587 19.9475 21.5741 20.0963 22.0544C20.6887 23.9675 19.5808 25.4879 18.4187 26.1627C17.7787 26.5343 16.9369 26.5564 15.9024 26.5564C15.336 26.5564 14.6945 26.6033 14.2249 26.4879C12.469 26.0567 10.8264 24.0858 11.7429 21.8148C12.1095 20.9064 12.8285 20.2047 13.7456 19.8463C13.9909 19.7834 14.2364 19.7208 14.4817 19.658C14.6243 19.6465 14.767 19.6352 14.9096 19.6237Z"
        fill={testMode ? "#FFDE3B" : "#FF4755"}
      />
    </svg>
  )
}

const MinimalLogo = ({
  companyId,
  testMode = false,
  className,
  franchise,
}: MinimalLogoProps): JSX.Element => {
  const mergedClassName = twMerge("w-6 lg:w-8", className)
  const { isReadOnly } = usePermissions()

  const link =
    typeof franchise === "string"
      ? `https://storage.googleapis.com/companies_assets/${companyId}/${franchise}/logo`
      : `https://storage.googleapis.com/companies_assets/${companyId}/logo`

  return !testMode || isReadOnly ? (
    <Img
      src={link}
      className={mergedClassName}
      unloader={<IdaLogo testMode={testMode} className={mergedClassName} />}
    />
  ) : (
    <IdaLogo testMode={testMode} className={mergedClassName} />
  )
}

export default MinimalLogo
