import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment, ReactNode, useEffect, useState } from "react"
import { twJoin, twMerge } from "tailwind-merge"

interface ModalProps {
  title?: ReactNode
  children?: ReactNode
  open: boolean
  onClose?: (value: boolean) => void
  hideCloseButton?: boolean
  icon?: ReactNode
  className?: string
  border?: "solid" | "gradient"
  fullScreen?: boolean
}

export function Modal({
  title,
  children,
  open,
  onClose,
  hideCloseButton = false,
  icon,
  className,
  border,
  fullScreen = false,
}: ModalProps) {
  const [displayContent, setDisplayContent] = useState(false)

  useEffect(() => {
    if (open) {
      setDisplayContent(true)
      return
    }
    setTimeout(() => {
      setDisplayContent(false)
    }, 250)
  }, [open])

  if (!displayContent) return <></>

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(value) => onClose?.(value)}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={twJoin(
              "flex min-h-full items-center justify-center md:p-4 text-center",
              fullScreen && "h-full",
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={twMerge("w-full max-w-sm h-full rounded", className)}
              >
                <div
                  className={twJoin(
                    "bg-white rounded md:p-4 flex flex-col gap-2 transform overflow-hidden h-full",
                    fullScreen && border !== undefined ? "p-1" : "p-4",
                  )}
                >
                  {icon && (
                    <div className="flex justify-center items-center mb-2">
                      {icon}
                    </div>
                  )}
                  {typeof title === "string" &&
                    (hideCloseButton || typeof onClose !== "function") && (
                      <DialogTitle
                        as="h1"
                        className="text-lg font-medium leading-6 text-gray-900 mb-2 md:mb-6 text-center"
                      >
                        {title}
                      </DialogTitle>
                    )}
                  {typeof title === "string" &&
                    !hideCloseButton &&
                    typeof onClose === "function" && (
                      <div className="flex items-start justify-between gap-2">
                        <h1 className="text-start font-black text-sm text-zinc-800">
                          {title}
                        </h1>
                        <button onClick={() => onClose(false)}>
                          <XMarkIcon className="w-6 h-6" />
                        </button>
                      </div>
                    )}
                  {children}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
