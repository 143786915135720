import { type CombinedState, combineReducers } from "redux"
import trainingModeReducer, {
  defaultTrainingModeReducerState,
} from "./trainingModeReducer"
import storeReducer, { defaultStoreReducerState } from "./storeReducer"
import connectionReducer, {
  defaultConnectionReducerState,
} from "./connectionReducer"
import mercurialReducer, {
  defaultMercurialReducerState,
} from "./mercurialReducer"
import userInterfaceReducer, {
  defaultUserInterfaceReducerState,
} from "./userInterfaceReducer"
import authReducer, { defaultAuthReducerState } from "./authReducer"
import redirectReducer, { defaultRedirectReducerState } from "./redirectReducer"
import { type StateType } from "../types"
import updateReducer, { defaultUpdateReducerState } from "./updateReducer"

const allReducers = combineReducers<StateType>({
  storeReducer,
  mercurialReducer,
  connectionReducer,
  userInterfaceReducer,
  trainingModeReducer,
  authReducer,
  redirectReducer,
  updateReducer,
})

export interface RootReducerAction {
  type: "resetState"
}

const rootReducer = (
  state: StateType | undefined,
  action: RootReducerAction,
): CombinedState<StateType> => {
  if (action.type === "resetState") {
    return allReducers(
      {
        storeReducer: defaultStoreReducerState,
        mercurialReducer: defaultMercurialReducerState,
        connectionReducer: defaultConnectionReducerState,
        userInterfaceReducer: defaultUserInterfaceReducerState,
        trainingModeReducer: defaultTrainingModeReducerState,
        authReducer: defaultAuthReducerState,
        redirectReducer: defaultRedirectReducerState,
        updateReducer: defaultUpdateReducerState,
      },
      action,
    )
  }
  return allReducers(state, action)
}

export default rootReducer
