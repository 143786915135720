interface PredictionRowProps {
  orderHistoricalQuantity: number | undefined
  predictionHistoricalQuantity: number | undefined
}

export function PredictionRow({
  predictionHistoricalQuantity,
  orderHistoricalQuantity,
}: PredictionRowProps) {
  const roundedOrder = Math.round(orderHistoricalQuantity ?? 0)
  const roundedPrediction = Math.round(predictionHistoricalQuantity ?? 0)

  if (
    orderHistoricalQuantity === undefined &&
    predictionHistoricalQuantity === undefined
  ) {
    return <p className="w-full flex justify-center items-center"> - </p>
  }

  return (
    <p className="w-full flex justify-center items-center gap-2">
      {roundedOrder}
      {roundedOrder !== roundedPrediction && (
        <span className="text-gray-500">
          <span className="line-through text-gray-500">
            {roundedPrediction}
          </span>
        </span>
      )}
    </p>
  )
}
