import { Button } from "@/components/ui/button"
import { GroupedPromotion } from "../EngagementPromoPage"

interface EngagementPromoDateSelectorProps {
  groupedPromotions: GroupedPromotion[]
}

export function EngagementPromoDateSelector({
  groupedPromotions,
}: EngagementPromoDateSelectorProps) {
  return (
    <div className="mx-2 md:mx-7 lg:mx-14 bg-white shadow border rounded-lg p-1 flex gap-1 overflow-x-auto">
      {groupedPromotions?.map((groupedPromotion, i) => {
        return (
          <Button key={i} variant="ghost" asChild>
            <a href={`#${i}`} className="flex-1">
              {Intl.DateTimeFormat("fr-FR").format(
                new Date(groupedPromotion.start_date),
              )}{" "}
              au{" "}
              {Intl.DateTimeFormat("fr-FR").format(
                new Date(groupedPromotion.end_date),
              )}
            </a>
          </Button>
        )
      })}
    </div>
  )
}
