import { ReactNode } from "react"

interface FilterButtonProps {
  category: string
  onClick: (category: string) => void
  active: boolean
  sideElement?: ReactNode
  first?: boolean
  disabled?: boolean
}

const FilterButton = ({
  category,
  onClick,
  active,
  sideElement,
  first = false,
  disabled = false,
}: FilterButtonProps) => {
  const textColor =
    first && active
      ? "text-green-800"
      : active
        ? "text-green-500"
        : "text-gray-500 hover:text-green-300"
  const borderBottomClass =
    first && active
      ? "border-b-2 lg:border-b-4 border-green-800"
      : active
        ? "border-b-2 lg:border-b-4 border-green-500"
        : "border-b-2 lg:border-b-4 border-transparent"
  const textWeight = active ? "font-black" : "font-normal"

  return (
    <button
      className={`flex items-center transition px-2 py-3 lg:px-4 focus:outline-none text-sm uppercase disabled:text-gray-500 disabled:border-transparent disabled:font-normal ${textColor} ${borderBottomClass} ${textWeight}`}
      onClick={() => {
        if (disabled === true) return
        onClick(category)
      }}
      disabled={disabled}
    >
      {category}
      {sideElement}
    </button>
  )
}

export default FilterButton
