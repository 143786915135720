import { twJoin } from "tailwind-merge"

interface StockRowProps {
  modifiedValue: number | undefined
  inventoryHistoricalQuantity: number | undefined
  isTooHigh: boolean
  isTooLow: boolean
}

export function StockRow({
  modifiedValue,
  inventoryHistoricalQuantity,
  isTooHigh,
  isTooLow,
}: StockRowProps) {
  return (
    <>
      {modifiedValue !== undefined &&
      Math.round(modifiedValue) !==
        Math.round(inventoryHistoricalQuantity ?? 0) ? (
        <p className="w-full flex justify-center items-center gap-1">
          <span>{Math.round(modifiedValue ?? 0)}</span>
          <span
            className={twJoin(
              "line-through decoration-black",
              isTooHigh && "text-custom-orange opacity-75",
              !isTooHigh && isTooLow && "text-custom-yellow opacity-75",
              !isTooHigh && !isTooLow && "text-gray-400 opacity-75",
            )}
          >
            {Math.round(inventoryHistoricalQuantity ?? 0)}
          </span>
        </p>
      ) : inventoryHistoricalQuantity === undefined ? (
        <p className="w-full flex justify-center items-center gap-1">-</p>
      ) : (
        <p
          className={twJoin(
            "w-full flex justify-center items-center gap-1",
            isTooHigh && "text-custom-orange",
            !isTooHigh && isTooLow && "text-custom-yellow",
          )}
        >
          {Math.round(inventoryHistoricalQuantity ?? 0)}
        </p>
      )}
    </>
  )
}
