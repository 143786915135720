import { type MercurialReducerState } from "../../reducers/mercurialReducer"
import { LastPerformingTableDesktop } from "./LastPerformingTableDesktop"
import { useCallback, useMemo } from "react"
import { getDiffBetweenDates } from "../../utils/getDiffBetweenDates"
import { toFixedNumber } from "../../utils/toFixedNumber"

interface LastPerformingTableProps {
  colisage: MercurialReducerState["mercurialAndStoreInventories"][number]["colisage"]
  saleHistoricalQuantities: MercurialReducerState["mercurialAndStoreInventories"][number]["sale_historical_quantities"]
  inventoryHistoricalField: MercurialReducerState["mercurialAndStoreInventories"][number]["inventory_historical_field"]
  deliveryHistoricalQuantities: MercurialReducerState["mercurialAndStoreInventories"][number]["delivery_historical_quantities"]
  orderExpectedReceptionDate: MercurialReducerState["mercurialAndStoreInventories"][number]["order_expected_reception_date"]
  orderNextExpectedReceptionDate: MercurialReducerState["mercurialAndStoreInventories"][number]["order_next_expected_reception_date"]
  viewSalesFirst: boolean
  isIdaAdmin: boolean
  predictionHistoricalField: MercurialReducerState["mercurialAndStoreInventories"][number]["prediction_historical_field"]
}

export const LastPerformingTable = ({
  colisage,
  saleHistoricalQuantities,
  deliveryHistoricalQuantities,
  inventoryHistoricalField,
  predictionHistoricalField,
  orderExpectedReceptionDate,
  orderNextExpectedReceptionDate,
  viewSalesFirst,
  isIdaAdmin,
}: LastPerformingTableProps): JSX.Element => {
  const expectedReceptionDate = useMemo(() => {
    const date =
      typeof orderExpectedReceptionDate === "string"
        ? new Date(orderExpectedReceptionDate)
        : undefined
    date?.setHours(0, 0, 0, 0) // reset time to only compare date
    return date
  }, [orderExpectedReceptionDate])

  const nextExpectedReceptionDate = useMemo(() => {
    const date =
      typeof orderNextExpectedReceptionDate === "string"
        ? new Date(orderNextExpectedReceptionDate)
        : undefined
    date?.setHours(0, 0, 0, 0) // reset time to only compare date
    return date
  }, [orderNextExpectedReceptionDate])

  // Generate an array of 8 days from today
  const dates = useMemo(() => {
    const numberOfDays = 8
    const dateReference = new Date()
    const startDateDiff = numberOfDays - 1
    dateReference.setDate(dateReference.getDate() - startDateDiff)

    return [...Array(numberOfDays)].map((_, i) => {
      const date = new Date(dateReference)
      date.setDate(date.getDate() + i)
      date.setHours(0, 0, 0, 0) // reset time to only compare date
      return date
    })
  }, [])

  const isHighlighted = useCallback(
    (date: Date, index: number): boolean => {
      if (expectedReceptionDate === undefined) return false

      const expectedReceptionDay = expectedReceptionDate.getDay()
      if (nextExpectedReceptionDate === undefined) {
        return date.getDay() === expectedReceptionDay
      }
      const daysBetweenDates = getDiffBetweenDates(
        expectedReceptionDate,
        nextExpectedReceptionDate,
      )
      const expectedReceptionDayIndex = dates.findIndex(
        (date) => expectedReceptionDay === date.getDay(),
      )

      return (
        index >= expectedReceptionDayIndex &&
        index < expectedReceptionDayIndex + daysBetweenDates
      )
    },
    [dates, expectedReceptionDate, nextExpectedReceptionDate],
  )

  const data = useMemo(() => {
    return dates.map((date, index) => {
      const predictionHistorical = predictionHistoricalField?.find(
        (predictionHistoricalField) => {
          const predictionHistoricalFieldDate = new Date(
            predictionHistoricalField.prediction_date,
          )
          predictionHistoricalFieldDate.setHours(0, 0, 0, 0) // reset time to only compare date
          return predictionHistoricalFieldDate.getTime() === date.getTime()
        },
      )
      const saleHistorical = saleHistoricalQuantities?.find(
        (saleHistoricalQuantity) => {
          const saleHistorialQuantityDate = new Date(
            saleHistoricalQuantity.date,
          )
          saleHistorialQuantityDate.setHours(0, 0, 0, 0) // reset time to only compare date

          return saleHistorialQuantityDate.getTime() === date.getTime()
        },
      )
      const inventoryHistorical = inventoryHistoricalField?.find(
        (inventoryHistoricalField) => {
          const inventoryHistoricalFieldDate = new Date(
            inventoryHistoricalField.inventory_date,
          )
          inventoryHistoricalFieldDate.setHours(0, 0, 0, 0) // reset time to only compare date

          return inventoryHistoricalFieldDate.getTime() === date.getTime()
        },
      )

      const deliveryHistorical = deliveryHistoricalQuantities?.find(
        (deliveryHistoricalQuantity) => {
          const saleHistorialQuantityDate = new Date(
            deliveryHistoricalQuantity.date,
          )
          saleHistorialQuantityDate.setHours(0, 0, 0, 0) // reset time to only compare date

          return saleHistorialQuantityDate.getTime() === date.getTime()
        },
      )

      const _isHighlighted = isHighlighted(date, index)
      const colisageValue = colisage ?? 1
      const saleHistoricalQuantity =
        saleHistorical?.quantity !== undefined &&
        saleHistorical?.quantity !== null &&
        saleHistorical?.quantity > 0
          ? toFixedNumber(saleHistorical.quantity / colisageValue)
          : undefined

      const inventoryHistoricalQuantity =
        inventoryHistorical?.total_inventory_qty !== undefined &&
        inventoryHistorical?.total_inventory_qty !== null &&
        inventoryHistorical?.total_inventory_qty > 0
          ? toFixedNumber(
              inventoryHistorical.total_inventory_qty / colisageValue,
            )
          : undefined

      const predictionHistoricalQuantity =
        predictionHistorical?.predicted_quantity !== undefined &&
        predictionHistorical?.predicted_quantity !== null &&
        predictionHistorical?.predicted_quantity > 0
          ? toFixedNumber(
              predictionHistorical.predicted_quantity / colisageValue,
            )
          : undefined

      const OrderHistoricalQuantity =
        predictionHistorical?.ordered_quantity !== undefined &&
        predictionHistorical?.ordered_quantity !== null &&
        predictionHistorical?.ordered_quantity > 0
          ? Math.round(predictionHistorical.ordered_quantity / colisageValue)
          : undefined

      const deliveryHistoricalQuantity =
        deliveryHistorical?.quantity !== undefined &&
        deliveryHistorical?.quantity !== null &&
        deliveryHistorical?.quantity > 0
          ? Math.round(deliveryHistorical.quantity / colisageValue)
          : undefined

      const isTooHigh = inventoryHistorical?.stock_too_high_flag ?? false
      const isTooLow = inventoryHistorical?.stock_too_low_flag ?? false
      const isPromoToday = inventoryHistorical?.is_promo_today ?? false
      const userUpdatedValue =
        inventoryHistorical?.user_updated_total_inventory_qty

      const modifiedValue =
        userUpdatedValue !== undefined && userUpdatedValue !== null
          ? toFixedNumber(userUpdatedValue / colisageValue)
          : undefined

      return {
        date,
        inventoryHistoricalQuantity:
          (inventoryHistoricalQuantity ?? 0) > 0
            ? inventoryHistoricalQuantity
            : undefined,
        saleHistoricalQuantity:
          (saleHistoricalQuantity ?? 0) > 0
            ? saleHistoricalQuantity
            : undefined,
        deliveryHistoricalQuantity:
          (deliveryHistoricalQuantity ?? 0) > 0
            ? deliveryHistoricalQuantity
            : undefined,
        predictionHistoricalQuantity:
          (predictionHistoricalQuantity ?? 0) > 0
            ? predictionHistoricalQuantity
            : undefined,
        OrderHistoricalQuantity:
          (OrderHistoricalQuantity ?? 0) > 0
            ? OrderHistoricalQuantity
            : undefined,
        isHighlighted: _isHighlighted,
        isTooHigh,
        isTooLow,
        isPromoToday,
        modifiedValue,
      }
    })
  }, [
    colisage,
    dates,
    deliveryHistoricalQuantities,
    isHighlighted,
    saleHistoricalQuantities,
    inventoryHistoricalField,
    predictionHistoricalField,
  ])

  return (
    <LastPerformingTableDesktop
      data={data}
      isIdaAdmin={isIdaAdmin}
      viewSalesFirst={viewSalesFirst}
    />
  )
}
