// src/components/storeScheduler/BulkUpsertDialog.tsx

"use client"

import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { OrderConfig } from "@/types"
import { toast } from "sonner"

interface BulkUpsertDialogProps {
  suppliers: { supplierId: string; supplierName: string }[]
  selectedSupplier: string
  days: string[]
  onApply: (orders: OrderConfig[]) => void
}

export default function BulkUpsertDialog({
  selectedSupplier,
  days,
  onApply,
}: BulkUpsertDialogProps) {
  const [open, setOpen] = useState(false)
  const [orderConfigs, setOrderConfigs] = useState<OrderConfig[]>([
    { orderDay: "", deliveryDay: "", numberOfOrder: 1 }, // Initialized as number
  ])

  const handleAddOrder = () => {
    if (orderConfigs.length >= 2) {
      // Limit to 2 orders
      toast.error("Limite atteinte", {
        description: "Vous ne pouvez pas ajouter plus de deux commandes.",
      })
      return
    }
    setOrderConfigs([
      ...orderConfigs,
      { orderDay: "", deliveryDay: "", numberOfOrder: orderConfigs.length + 1 },
    ])
  }

  const handleRemoveOrder = (index: number) => {
    const updatedConfigs = [...orderConfigs]
    updatedConfigs.splice(index, 1)
    setOrderConfigs(updatedConfigs)
  }

  const handleChange = (
    index: number,
    field: keyof OrderConfig,
    value: string | number,
  ) => {
    const updatedConfigs = [...orderConfigs]
    if (field === "numberOfOrder") {
      // Ensure it's a number and within 1 or 2 or 3
      if (
        typeof value === "number" &&
        (value === 1 || value === 2 || value === 3)
      ) {
        updatedConfigs[index] = {
          ...updatedConfigs[index],
          [field]: value,
        }
      } else {
        toast.error("Numéro de Commande Invalide", {
          description: "Le numéro de commande doit être 1, 2 ou 3.",
        })
      }
    } else {
      updatedConfigs[index] = {
        ...updatedConfigs[index],
        [field]: value,
      }
    }
    setOrderConfigs(updatedConfigs)
  }

  const handleApply = () => {
    // Validate all order configurations
    for (const [index, config] of orderConfigs.entries()) {
      if (!config.orderDay || !config.deliveryDay) {
        toast.error("Attention", {
          description: `Veuillez sélectionner à la fois le jour de commande et le jour de livraison pour la commande n°${index + 1}.`,
        })
        return
      }

      if (config.orderDay === config.deliveryDay) {
        toast.error("Attention", {
          description: `Le jour de commande et le jour de livraison ne peuvent pas être identiques pour la commande n°${index + 1}.`,
        })
        return
      }

      if (
        !config.numberOfOrder ||
        !(
          config.numberOfOrder === 1 ||
          config.numberOfOrder === 2 ||
          config.numberOfOrder === 3
        )
      ) {
        toast.error("Attention", {
          description: `Le numéro de commande doit être 1, 2 ou 3 pour la commande n°${index + 1}.`,
        })
        return
      }
    }

    onApply(orderConfigs)
    setOrderConfigs([{ orderDay: "", deliveryDay: "", numberOfOrder: 1 }])
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="default" disabled={!selectedSupplier}>
          Appliquer à tous les magasins
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>
            Planifier les commandes pour tous les magasins
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {orderConfigs.map((config, index) => (
            <div key={index} className="border p-4 rounded-md">
              <div className="flex justify-between items-center mb-2">
                <h4 className="font-semibold">Commande {index + 1}</h4>
                {orderConfigs.length > 1 && (
                  <Button
                    variant="ghost"
                    color="destructive"
                    size="sm"
                    onClick={() => handleRemoveOrder(index)}
                  >
                    Supprimer
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Order Day */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Jour de commande
                  </label>
                  <Select
                    onValueChange={(value) =>
                      handleChange(index, "orderDay", value)
                    }
                    value={config.orderDay}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Sélectionner un jour" />
                    </SelectTrigger>
                    <SelectContent>
                      {days.map((day) => (
                        <SelectItem key={day} value={day}>
                          {day}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Delivery Day */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Jour d&apos;arrivée
                  </label>
                  <Select
                    onValueChange={(value) =>
                      handleChange(index, "deliveryDay", value)
                    }
                    value={config.deliveryDay}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Sélectionner un jour" />
                    </SelectTrigger>
                    <SelectContent>
                      {days.map((day) => (
                        <SelectItem key={day} value={day}>
                          {day}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Number of Order */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Numéro de Commande
                  </label>
                  <Select
                    onValueChange={(value) =>
                      handleChange(index, "numberOfOrder", Number(value))
                    }
                    value={config.numberOfOrder.toString()}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Sélectionner" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="1">1</SelectItem>
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          ))}

          {orderConfigs.length < 2 && (
            <Button onClick={handleAddOrder} variant="outline">
              Ajouter une commande
            </Button>
          )}
        </div>
        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button
            onClick={handleApply}
            className="bg-orange-500 hover:bg-orange-600"
          >
            Appliquer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
