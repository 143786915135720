// src/components/storeScheduler/BulkUpsertPlanner.tsx

import React from "react"
import BulkUpsertDialog from "./BulkUpsertDialog"

import { OrderConfig } from "@/types"

interface BulkUpsertPlannerProps {
  suppliers: { supplierId: string; supplierName: string }[]
  selectedSupplier: string
  days: string[]
  onApply: (orders: OrderConfig[]) => void
}

const BulkUpsertPlanner: React.FC<BulkUpsertPlannerProps> = ({
  suppliers,
  selectedSupplier,
  days,
  onApply,
}) => {
  return (
    <div className="mb-6">
      <BulkUpsertDialog
        suppliers={suppliers}
        selectedSupplier={selectedSupplier}
        days={days}
        onApply={onApply}
      />
    </div>
  )
}

export default BulkUpsertPlanner
