import { Table } from "@/components/ui/table"
import { StateType } from "@/types"
import { useGetPerformancesQuery } from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { add } from "date-fns"
import { useState } from "react"
import { DateRange } from "react-day-picker"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { DateSelector } from "./DateSelector"
import { PerformancesTableHeader } from "./PerformancesTableHeader"
import { PerformancesTableBody } from "./PerformancesTableBody"
import { PerformancesTableFooter } from "./PerformancesTableFooter"
import { Spinner } from "@/ui/Spinner"

export function PerformancesPage() {
  const params = useParams<{ companyId: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined
  const storeCurrency = useSelector(
    (state: StateType) => state.storeReducer.storeCurrency,
  )
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to
    ? dateRange?.to
    : dateRange?.from
      ? dateRange?.from
      : defaultEndDate

  const { data, loading } = useGetPerformancesQuery({
    variables: {
      input: {
        company_id: params.companyId!,
        franchise_name: franchiseParam,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        store_id: storeId!,
      },
    },
  })

  return (
    <>
      <DateSelector
        fromDate={fromDate}
        toDate={toDate}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setSearchParams={setSearchParams}
        franchiseParam={franchiseParam}
      />
      <div className="h-full overflow-y-auto">
        {!loading ? (
          <Table>
            <PerformancesTableHeader />
            <PerformancesTableBody
              performances={data?.getPerformances.performances}
              storeCurrency={storeCurrency}
            />
            <PerformancesTableFooter
              performances={data?.getPerformances.performances}
              storeCurrency={storeCurrency}
            />
          </Table>
        ) : (
          <div className="h-full flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}
