import { StateType } from "@/types"
import { CalendarAlertIcon } from "@/ui/AlertIcons"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"
import {
  OrderPlanningUpsertItem,
  useUpsertOrderPlanningMutation,
} from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { captureException } from "@sentry/react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "sonner"

interface MissingPlanningsModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  refreshApplication: () => void
}

export function MissingPlanningsModal({
  isOpen,
  setIsOpen,
  refreshApplication,
}: MissingPlanningsModalProps) {
  const { storeId, storeSuppliers } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { mercurialAndStoreInventories, dimMercuriales } = useSelector(
    (state: StateType) => state.mercurialReducer,
  )

  const [upsertOrderPlanningMutation, { loading: upsertOrderPlanningLoading }] =
    useUpsertOrderPlanningMutation()

  const [customOrderReceptionDates, setCustomOrderReceptionDates] = useState<
    Record<string, string>
  >({})

  useEffect(() => {
    const orderReceptionDates = mercurialAndStoreInventories.reduce<
      Record<string, string>
    >((acc, curr) => {
      if (
        typeof curr.supplier_id !== "string" ||
        typeof curr.mercuriale_reception_date !== "string" ||
        acc[curr.supplier_id] !== undefined
      ) {
        return acc
      }

      return {
        ...acc,
        [curr.supplier_id ?? ""]: curr.mercuriale_reception_date ?? "",
      }
    }, {})
    setCustomOrderReceptionDates(orderReceptionDates)
  }, [mercurialAndStoreInventories, setCustomOrderReceptionDates])

  return (
    <Modal
      icon={<CalendarAlertIcon />}
      title="Dates de réception manquantes"
      open={isOpen}
      hideCloseButton
      onClose={() => setIsOpen(false)}
    >
      <div className="mt-4 flex flex-col items-center gap-2">
        {storeSuppliers
          ?.filter((supplier) =>
            mercurialAndStoreInventories.some(
              (mercurialeInfo) => mercurialeInfo.supplier_id === supplier.id,
            ),
          )
          .map((supplier) => {
            return (
              <div key={supplier.id}>
                <p>{supplier?.supplier_name}</p>
                <input
                  type="date"
                  className="w-fit h-[44px] mt-2 p-2 border border-gray-200 rounded shadow"
                  value={customOrderReceptionDates[supplier.id] ?? ""}
                  onChange={(e) =>
                    setCustomOrderReceptionDates(
                      (prevCustomOrderReceptionDates) => ({
                        ...prevCustomOrderReceptionDates,
                        [supplier.id]: e.target.value,
                      }),
                    )
                  }
                />
              </div>
            )
          })}
      </div>
      <Button
        onClick={async () => {
          const orderDate = formatDateToYYYYMMDD(new Date())

          try {
            const planningItems = dimMercuriales?.reduce<
              OrderPlanningUpsertItem[]
            >((acc, dimMercuriale) => {
              const suppliersIds = storeSuppliers
                ?.map((supplier) => supplier.id)
                .filter(
                  (supplierId) =>
                    customOrderReceptionDates[supplierId] !== undefined &&
                    customOrderReceptionDates[supplierId] !== "",
                )
              return [
                ...acc,
                ...(suppliersIds?.map((supplierId) => ({
                  order_date: orderDate,
                  reception_date: customOrderReceptionDates[supplierId],
                  supplier_id: supplierId,
                  dim_mercuriale_id: dimMercuriale.dimMercurialeId,
                })) ?? []),
              ]
            }, [])
            if (planningItems === undefined || planningItems.length === 0)
              return

            const upsertOrderPlanningResult = await upsertOrderPlanningMutation(
              {
                variables: {
                  input: {
                    store_id: storeId!,
                    planning_item_upsert_input: planningItems,
                  },
                },
              },
            )
            if (
              upsertOrderPlanningResult.data?.upsertOrderPlanning.success !==
              true
            ) {
              throw "La création du planning a échouée"
            }
            refreshApplication()
          } catch (error) {
            console.error(error)
            captureException(error)
            toast.error("La création du planning a échouée")
          }
        }}
        disabled={
          Object.values(customOrderReceptionDates).filter(
            (value) => value !== "",
          ).length <= 0
        }
        loading={upsertOrderPlanningLoading}
        className="w-full h-11 mt-8"
      >
        Valider
      </Button>
    </Modal>
  )
}
