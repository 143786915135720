import { type IconType } from "./types"

export function ThreeQuartersIcon({ className }: IconType): JSX.Element {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.33402 10.628H2.82202V9.172C2.85002 9.144 2.87802 9.13 2.90602 9.102L5.16002 7.128H1.25402V5.14H8.00202V6.946L5.81802 8.864C7.21802 9.284 8.35202 10.404 8.35202 12.168C8.35202 14.352 6.58802 15.682 4.48802 15.682C2.47202 15.682 0.778016 14.45 0.778016 12.112H3.01802C3.01802 13.134 3.63402 13.68 4.54402 13.68C5.42602 13.68 6.02802 13.078 6.02802 12.154C6.02802 11.328 5.55202 10.628 4.33402 10.628Z"
        fill="currentColor"
      />
      <path
        d="M21.514 21.666H20.24V23.5H18.098V21.666H12.946V19.678L17.412 13.14H20.24V19.678H21.514V21.666ZM15.354 19.678H18.098V15.646L15.354 19.678Z"
        fill="currentColor"
      />
      <line
        x1="4.82757"
        y1="24.955"
        x2="17.8276"
        y2="4.95501"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}
