import { TruckIcon } from "@heroicons/react/24/solid"
import { twMerge } from "tailwind-merge"

export function Delivery({
  date,
  className,
}: {
  date: string
  className?: string
}) {
  const mergedClassname = twMerge(
    "shadow rounded px-2 py-1 bg-stone-50 text-neutral-700 text-xxs font-bold flex items-center gap-1",
    className,
  )

  return (
    <div className={mergedClassname}>
      <TruckIcon className="w-3 h-3" />
      <span className="hidden lg:inline-block">Livré le</span>
      <span className="whitespace-nowrap">
        {new Intl.DateTimeFormat("fr-FR", {
          weekday: "short",
          day: "2-digit",
        }).format(new Date(date))}
      </span>
    </div>
  )
}
