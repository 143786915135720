import { useEffect, useState } from "react"
import { Button } from "../ui/Button"
import { HelpIcon } from "../ui/icons/HelpIcon"

const ContactUsBar = (): JSX.Element => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  // Effect to update window height when the window is resized
  useEffect(() => {
    const handleResize = (): void => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <div
      className={`px-2 md:px-4 flex items-center justify-between ${
        windowHeight > 700 ? "py-6" : "py-2"
      }`}
    >
      <div className="flex flex-col">
        <h4 className="text-xl md:text-3xl text-[#323232] font-normal m-0">
          Une question ?
        </h4>
        <p className=" text-xs md:text-sm text-[#323232] font-normal">
          Contactez-nous !
        </p>
      </div>
      <Button
        color="ghost"
        leftIcon={<HelpIcon />}
        onClick={() => {
          window.location.href = "mailto:contact@ai-ida.com"
        }}
        className="text-gray-400 h-12"
      >
        Nous contacter
      </Button>
    </div>
  )
}

export default ContactUsBar
