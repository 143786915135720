import { type IconType } from "./types"

export function HalfIcon({ className }: IconType): JSX.Element {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.578 7.17H1.226V5.14H5.748V15.5H3.578V7.17Z"
        fill="currentColor"
      />
      <path
        d="M22.3 25.472L14.908 25.5V23.82L18.324 20.978C19.43 20.068 19.892 19.424 19.892 18.57C19.892 17.52 19.374 16.96 18.45 16.96C17.484 16.96 16.924 17.618 16.924 18.78H14.67C14.67 16.456 16.154 14.944 18.436 14.944C20.802 14.944 22.216 16.218 22.216 18.43C22.216 19.844 21.362 20.992 20.074 22.07L18.52 23.372H22.3V25.472Z"
        fill="currentColor"
      />
      <line
        x1="4.91156"
        y1="24.955"
        x2="17.9116"
        y2="4.95501"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}
