// components/StoreRow.tsx
import React from "react"
import { TableCell, TableRow } from "@/components/ui/table"
import DayCell from "./dayCell"
import { Store } from "@/utils/__generated__/graphql"

import { Schedule } from "@/types"
interface StoreRowProps {
  store: Store
  days: string[]
  schedule: Schedule
  selectedSupplier: string
  suppliers: { supplierId: string; supplierName: string }[]
  onCheckboxChange: (
    supplierId: string,
    storeId: string,
    day: string,
    numberOfOrders: number,
  ) => void
  onSetArrivalDay: (
    supplierId: string,
    storeId: string,
    orderDay: string,
    arrivalDay: string,
    numberOfOrders: number,
  ) => void
  onAddOrder: (storeId: string, day: string) => void
  openPopovers: Record<string, boolean>
  setOpenPopovers: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  getPopoverKey: (storeId: string, day: string, orderNumber: number) => string
  selectedOrderNumber: Record<string, number>
  setSelectedOrderNumber: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >
}

const StoreRow: React.FC<StoreRowProps> = ({
  store,
  days,
  schedule,
  selectedSupplier,
  suppliers,
  onCheckboxChange,
  onSetArrivalDay,
  onAddOrder,
  openPopovers,
  setOpenPopovers,
  getPopoverKey,
  selectedOrderNumber,
  setSelectedOrderNumber,
}) => {
  return (
    <TableRow>
      <TableCell className="font-medium">{store.store_name}</TableCell>
      {days.map((day) => (
        <DayCell
          key={day}
          storeId={store.id}
          day={day}
          days={days}
          schedule={schedule}
          selectedSupplier={selectedSupplier}
          suppliers={suppliers}
          onCheckboxChange={onCheckboxChange}
          onSetArrivalDay={onSetArrivalDay}
          onAddOrder={onAddOrder}
          openPopovers={openPopovers}
          setOpenPopovers={setOpenPopovers}
          getPopoverKey={getPopoverKey}
          selectedOrderNumber={selectedOrderNumber}
          setSelectedOrderNumber={setSelectedOrderNumber}
        />
      ))}
    </TableRow>
  )
}

export default StoreRow
