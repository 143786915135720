import { twMerge } from "tailwind-merge"

interface IconType {
  className?: string
}

export function ArrowNextIcon({ className }: IconType): JSX.Element {
  const mergedClassName = twMerge("w-6 h-6", className)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={mergedClassName}
    >
      <path
        d="M8.25 4.5L15.75 12L8.25 19.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
