export function isNewWeek(date: Date): boolean {
  const today = new Date()

  // Rendre les dates comparables en réinitialisant les heures, minutes, secondes et millisecondes
  date.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  // Trouver le lundi de la semaine pour la date stockée
  const storedWeekStart = new Date(date)
  const storedDayOfWeek = date.getDay() === 0 ? 6 : date.getDay() - 1 // Lundi = 0, Dimanche = 6
  storedWeekStart.setDate(storedWeekStart.getDate() - storedDayOfWeek)

  // Trouver le lundi de la semaine pour la date actuelle
  const currentWeekStart = new Date(today)
  const currentDayOfWeek = today.getDay() === 0 ? 6 : today.getDay() - 1
  currentWeekStart.setDate(currentWeekStart.getDate() - currentDayOfWeek)

  // Comparer les semaines
  return storedWeekStart.getTime() !== currentWeekStart.getTime()
}
