import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"
import { Img } from "react-image"
import { useSelector } from "react-redux"

interface DescriptionProps {
  product: AllMercurialInfo
  isOnline: boolean
  noTruncate?: boolean
}

export const ProductImgAndName = ({ product, isOnline }: DescriptionProps) => {
  const { companyId } = useSelector((state: StateType) => state.storeReducer)

  return (
    <div className="flex items-center gap-4">
      {isOnline ? (
        <Img
          className="lg:block w-8 h-8 md:w-12 md:h-12 rounded object-cover bg-white border border-gray-300"
          src={`https://storage.googleapis.com/references_images/${companyId}/${product?.photo_id}.jpeg`}
          alt={product?.mercuriale_name ?? ""}
          unloader={
            <div className="hidden lg:block w-8 h-8 md:w-12 md:h-12 rounded object-cover" />
          }
        />
      ) : (
        <div className="hidden lg:block w-8 h-8 md:w-12 md:h-12 rounded object-cover" />
      )}
      <p className="flex flex-col items-start">
        <span className="text-left text-md sm:text-sm md:text-md uppercase text-black font-bold line-clamp-2">
          {product?.mercuriale_name ?? ""}
        </span>
      </p>
    </div>
  )
}
