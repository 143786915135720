import Header from "../components/Header"
import { Outlet } from "react-router-dom"
import ContactUsBar from "../components/ContactUsBar"

const Account = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-between">
      <Header />
      <Outlet />
      <hr />
      <ContactUsBar />
    </div>
  )
}

export default Account
