import { useWindowSize } from "@/hooks/useWindowSize"
import { getQuarterValue } from "@/utils/getQuarterValue"

const renderBadge = (
  isToVerify = false,
  isTooHigh = false,
  isTooLow = false,
) => {
  if (isToVerify) {
    return (
      <p className="text-red-700 md:text-white md:bg-red-700 md:px-1 md:py-1 md:rounded-sm">
        À VÉRIFIER
      </p>
    )
  }
  if (isTooHigh) {
    return (
      <p className="text-custom-orange md:text-white md:bg-custom-orange md:px-1 md:py-1 md:rounded-sm">
        STOCK ÉLEVÉ
      </p>
    )
  }
  if (isTooLow) {
    return (
      <p className="text-custom-lightOrange md:text-white md:bg-custom-lightOrange md:px-1 md:py-1 md:rounded-sm">
        STOCK FAIBLE
      </p>
    )
  }
  return <></>
}

interface BackInventoryExtraElementProps {
  value: number
  floorInventoryQuantity?: number
  isToVerify?: boolean
  isTooHigh?: boolean
  isTooLow?: boolean
  unit: string
}

export function BackInventoryExtraElement({
  value,
  floorInventoryQuantity = 0,
  isToVerify,
  isTooHigh,
  isTooLow,
  unit,
}: BackInventoryExtraElementProps) {
  const { isMD } = useWindowSize()

  const totalValue = value + floorInventoryQuantity
  const quarterValue = getQuarterValue(totalValue, "size-4")

  return (
    <div className="w-full flex items-center flex-col gap-2">
      <div className="h-[10px] md:h-[20px] text-xxs md:text-xxxs font-bold text-center">
        {renderBadge(isToVerify, isTooHigh, isTooLow)}
      </div>
      {!isMD && (
        <p className="w-fit bg-gray-100 border px-2 py-1 rounded text-xxs text-gray-400 text-center flex flex-col gap-1">
          Stock total
          <span className="ml-1 text-xs text-gray-900 font-bold flex justify-center items-center">
            {(totalValue === 0 || (quarterValue.value ?? 0) >= 1) &&
              quarterValue.value}
            {quarterValue.icon} {unit}
          </span>
        </p>
      )}
    </div>
  )
}
