import { TokenData } from "../utils/jwt"

export interface AuthReducerState {
  // Only used to ensure the authentication. If you want to know if a user is authenticated, use `const { isAuthenticated } = useAuth0()` hook.
  isAuthenticated: boolean
  redirectPath: string | undefined
  tokenData: TokenData | undefined
}

export const defaultAuthReducerState: AuthReducerState = {
  isAuthenticated: false,
  redirectPath: undefined,
  tokenData: undefined,
}

interface SetAuthAction {
  type: "setAuth"
  payload: AuthReducerState
}

interface SetIsAuthenticatedAction {
  type: "setIsAuthenticated"
  payload: AuthReducerState["isAuthenticated"]
}

interface SetRedirectPathAction {
  type: "setRedirectPath"
  payload: AuthReducerState["redirectPath"]
}

interface SetTokenDataAction {
  type: "setTokenData"
  payload: AuthReducerState["tokenData"]
}

export type AuthReducerAction =
  | SetAuthAction
  | SetIsAuthenticatedAction
  | SetRedirectPathAction
  | SetTokenDataAction

const authReducer = (
  state: AuthReducerState = defaultAuthReducerState,
  action: AuthReducerAction,
): AuthReducerState => {
  switch (action.type) {
    case "setAuth":
      return {
        ...state,
        ...action.payload,
      }
    case "setIsAuthenticated":
      return {
        ...state,
        isAuthenticated: action.payload,
      }
    case "setRedirectPath":
      return {
        ...state,
        redirectPath: action.payload,
      }
    case "setTokenData":
      return {
        ...state,
        tokenData: action.payload,
      }
    default:
      return state
  }
}

export default authReducer
