// components/DayCell.tsx
import React from "react"
import { TableCell } from "@/components/ui/table"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Label } from "@/components/ui/label"

import { Button } from "@/components/ui/button"
import { CirclePlusIcon } from "lucide-react"
import { Schedule } from "@/types"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select"
import { toast } from "sonner"

interface DayCellProps {
  storeId: string
  day: string
  schedule: Schedule
  selectedSupplier: string
  suppliers: { supplierId: string; supplierName: string }[]
  onCheckboxChange: (
    supplierId: string,
    storeId: string,
    day: string,
    numberOfOrders: number,
  ) => void
  onSetArrivalDay: (
    supplierId: string,
    storeId: string,
    orderDay: string,
    arrivalDay: string,
    numberOfOrders: number,
  ) => void
  onAddOrder: (storeId: string, day: string) => void
  openPopovers: Record<string, boolean>
  setOpenPopovers: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  getPopoverKey: (storeId: string, day: string, orderNumber: number) => string
  selectedOrderNumber: Record<string, number>
  setSelectedOrderNumber: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >
  days: string[]
}

const DayCell: React.FC<DayCellProps> = ({
  storeId,
  day,
  schedule,
  selectedSupplier,
  onCheckboxChange,
  onSetArrivalDay,
  openPopovers,
  setOpenPopovers,
  getPopoverKey,
  selectedOrderNumber,
  setSelectedOrderNumber,
  days,
}) => {
  const ordersForCell = schedule.filter(
    (entry) =>
      entry.storeId === storeId &&
      entry.receptionDate === day &&
      entry.supplierId === selectedSupplier,
  )
  const getAbbreviatedDay = (day: string) => {
    return day.slice(0, 3) // Takes the first 3 letters of the day
  }

  return (
    <TableCell className="text-center p-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        {ordersForCell.map((order) => {
          const popoverKey = getPopoverKey(storeId, day, order.numberOfOrders)
          return (
            <div
              key={order.numberOfOrders}
              className="flex items-center justify-center space-x-2"
            >
              {/* Order Checkbox */}
              <Checkbox
                checked={order.selected}
                onCheckedChange={() =>
                  onCheckboxChange(
                    order.supplierId!,
                    storeId,
                    day,
                    order.numberOfOrders,
                  )
                }
              />
              <p>n°{order.numberOfOrders}</p>
              {/* Arrival Day Selection */}
              <Popover
                open={openPopovers[popoverKey] || false}
                onOpenChange={(isOpen) => {
                  setOpenPopovers((prev) => ({
                    ...prev,
                    [popoverKey]: isOpen,
                  }))
                }}
              >
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="text-xs p-1 h-auto"
                    onClick={() => {
                      if (!selectedSupplier) {
                        toast.error("Attention", {
                          description:
                            "Oups ! Vous devez d&apos;abord sélectionner un fournisseur.",
                        })
                      } else {
                        setOpenPopovers((prev) => ({
                          ...prev,
                          [popoverKey]: true,
                        }))
                      }
                    }}
                  >
                    {order.arrivalDay}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="flex flex-col">
                    {days.map((arrivalDay) => (
                      <Button
                        key={arrivalDay}
                        onClick={() => {
                          onSetArrivalDay(
                            selectedSupplier,
                            storeId,
                            day,
                            arrivalDay,
                            order.numberOfOrders,
                          )
                          // Close the popover after selecting the arrival day
                          setOpenPopovers((prev) => ({
                            ...prev,
                            [popoverKey]: false,
                          }))
                        }}
                        variant="ghost"
                        className="text-left w-full"
                      >
                        {getAbbreviatedDay(arrivalDay)}
                      </Button>
                    ))}
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          )
        })}

        {/* Popover for adding a new order */}
        <Popover
          open={openPopovers[`${storeId}-${day}-add-order`] || false}
          onOpenChange={(isOpen) => {
            setOpenPopovers((prev) => ({
              ...prev,
              [`${storeId}-${day}-add-order`]: isOpen,
            }))
          }}
        >
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="text-xs p-1 h-auto"
              onClick={() => {
                if (!selectedSupplier) {
                  toast.error("Attention", {
                    description:
                      "Oups ! Vous devez d'abord sélectionner un fournisseur.",
                  })
                } else {
                  setOpenPopovers((prev) => ({
                    ...prev,
                    [`${storeId}-${day}-add-order`]: true,
                  }))
                }
              }}
            >
              <CirclePlusIcon className="w-3 h-3" />
            </Button>
          </PopoverTrigger>

          <PopoverContent className="w-80">
            <div className="flex flex-col space-y-2">
              <h4 className="flex font-bold">Ajouter une commande</h4>
              <p className="flex text-sm text-muted-foreground">
                Choisissez le numéro de commande et la date de réception.
              </p>
            </div>
            <div className="grid gap-2 py-1">
              <label className="flex block text-sm font-bold">
                Numéro de Commande
              </label>
              <Select
                value={
                  selectedOrderNumber[`${storeId}-${day}`]?.toString() ?? ""
                }
                onValueChange={(value) =>
                  setSelectedOrderNumber((prev) => ({
                    ...prev,
                    [`${storeId}-${day}`]: Number(value),
                  }))
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="1" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="1">1</SelectItem>
                  <SelectItem value="2">2</SelectItem>
                  <SelectItem value="3">3</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="grid gap-2 py-1">
              <Label className="flex text-sm font-bold">
                Date de Réception
              </Label>
              <div className="grid grid-cols-7 gap-2">
                {days.map((arrivalDay) => {
                  const addOrderPopoverKey = `${storeId}-${day}-add-order`
                  return (
                    <Button
                      key={arrivalDay}
                      onClick={() => {
                        const orderNumber =
                          selectedOrderNumber[`${storeId}-${day}`] || 1
                        onSetArrivalDay(
                          selectedSupplier,
                          storeId,
                          day,
                          arrivalDay,
                          orderNumber,
                        )
                        // Close the add order popover after selecting the arrival day
                        setOpenPopovers((prev) => ({
                          ...prev,
                          [addOrderPopoverKey]: false,
                        }))
                      }}
                      variant="default"
                      className="h-8 w-8 p-0"
                    >
                      {getAbbreviatedDay(arrivalDay)}
                    </Button>
                  )
                })}
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </TableCell>
  )
}

export default DayCell
