import { getQuarterValue } from "@/utils/getQuarterValue"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreReducerState } from "@/reducers/storeReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import { useWindowSize } from "@/hooks/useWindowSize"
import { InventoriesInputsDesktopLayout } from "./InventoriesInputsDesktopLayout"
import { InventoriesInputsMobileLayout } from "./InventoriesInputsMobileLayout"
import { Maybe } from "@/utils/__generated__/graphql"

export interface InventoriesInputsProps {
  firstReference: AllMercurialInfo
  reference: Partial<AllMercurialInfo>
  referenceIndex: number
  storeSettings: StoreReducerState["storeSettings"]
  isOrderDisabled: boolean
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  isOnline: boolean
  hasBigBreakage: boolean
}

export function InventoriesInputs({
  firstReference,
  reference,
  referenceIndex,
  storeSettings,
  isOrderDisabled,
  updateInventory,
  isOnline,
  hasBigBreakage,
}: InventoriesInputsProps) {
  const { isMD } = useWindowSize()

  const getInventoryEquivalent = (
    quantity: Maybe<number> | undefined,
    colisage: Maybe<number> | undefined,
    useKgPce: Maybe<boolean> | undefined,
  ) => {
    return useKgPce === true
      ? (quantity ?? 0)
      : (quantity ?? 0) * (colisage ?? 1)
  }

  const backInventoryEquivalent = getInventoryEquivalent(
    firstReference.back_inventory_qty,
    firstReference.colisage,
    storeSettings?.use_kg_pce,
  )
  const floorInventoryEquivalent = getInventoryEquivalent(
    firstReference.floor_inventory_qty,
    firstReference.colisage,
    storeSettings?.use_kg_pce,
  )
  const shelfFloorSizeEquivalent = getInventoryEquivalent(
    firstReference.shelf_floor_size,
    firstReference.colisage,
    storeSettings?.use_kg_pce,
  )
  const shelfFloorSizeQuarterValue = getQuarterValue(
    reference.shelf_floor_size ?? 0,
    "size-3",
  )

  return isMD ? (
    <InventoriesInputsDesktopLayout
      backInventoryEquivalent={backInventoryEquivalent}
      floorInventoryEquivalent={floorInventoryEquivalent}
      shelfFloorSizeEquivalent={shelfFloorSizeEquivalent}
      shelfFloorSizeQuarterValue={shelfFloorSizeQuarterValue}
      referenceIndex={referenceIndex}
      isOrderDisabled={isOrderDisabled}
      updateInventory={updateInventory}
      isOnline={isOnline}
      reference={reference}
      firstReference={firstReference}
      storeSettings={storeSettings}
      hasBigBreakage={hasBigBreakage}
    />
  ) : (
    <InventoriesInputsMobileLayout
      backInventoryEquivalent={backInventoryEquivalent}
      floorInventoryEquivalent={floorInventoryEquivalent}
      shelfFloorSizeEquivalent={shelfFloorSizeEquivalent}
      shelfFloorSizeQuarterValue={shelfFloorSizeQuarterValue}
      referenceIndex={referenceIndex}
      isOrderDisabled={isOrderDisabled}
      updateInventory={updateInventory}
      reference={reference}
      storeSettings={storeSettings}
      hasBigBreakage={hasBigBreakage}
      isOnline={isOnline}
    />
  )
}
