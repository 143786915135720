import * as jose from "jose"

type Token = string | null | undefined

export interface TokenData {
  aud: string[]
  azp: string
  exp: number
  iat: number
  iss: string
  permissions: string[]
  scope: string
  sub: string
  email: string
}

export const decodeToken = (token: Token): null | TokenData => {
  try {
    if (
      typeof token !== "string" ||
      (typeof token === "string" && token === "")
    )
      return null
    const payload = jose.decodeJwt(token) as unknown
    const data = payload as TokenData
    return data
  } catch (error) {
    return null
  }
}
