import { getTotalStock } from "./getStockQuantity"

interface getOrderQuantityParams {
  backQuantity: number
  floorQuantity: number
  predictedQuantityArray: number[]
}

export function getOrderQuantity({
  backQuantity,
  floorQuantity,
  predictedQuantityArray,
}: getOrderQuantityParams): number {
  const totalStock = getTotalStock({
    backQuantity,
    floorQuantity,
  })
  const index = Math.round(totalStock * 4)
  const predictedQuantity = predictedQuantityArray[index]
  return predictedQuantity ?? 0
}
