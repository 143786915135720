export function getDiffBetweenDates(date1: Date, date2: Date): number {
  // Reset hours to get differences in days
  date1.setHours(0, 0, 0, 0)
  date2.setHours(0, 0, 0, 0)

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = date2.getTime() - date1.getTime()

  // Convert the difference to days
  return Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24))
}
