import { useDispatch, useSelector } from "react-redux"

import { Search } from "lucide-react"
import { Input } from "@/components/ui/input"
import { DispatchActionType, StateType } from "@/types"

const SearchBar = () => {
  const dispatch = useDispatch<DispatchActionType>()
  const searchTerm = useSelector(
    (state: StateType) => state.userInterfaceReducer.recapPage.searchTerm,
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "setSearchBar",
      payload: {
        page: "recapPage",
        searchTerm: e.target.value,
      },
    })
  }

  return (
    <div className="flex items-center justify-center w-full h-11">
      <div className="relative w-full">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <Search color="gray" size={20} />
        </span>
        <Input
          type="text"
          onChange={handleSearchChange}
          placeholder="Rechercher"
          value={searchTerm}
          className="focus:ring-0 focus:border-transparent bg-gray-100 pl-10 w-full"
        />
      </div>
    </div>
  )
}

export default SearchBar
