import { RefreshAlertIcon } from "@/ui/AlertIcons"
import { Modal } from "@/ui/Modal"
import { Spinner } from "@/ui/Spinner"

interface LoadingRefreshModalProps {
  isOpen: boolean
}

export function LoadingRefreshModal({ isOpen }: LoadingRefreshModalProps) {
  return (
    <Modal
      icon={<RefreshAlertIcon />}
      title="Synchronisation des données."
      open={isOpen}
      hideCloseButton
    >
      <p className="text-[#667085] text-sm font-normal text-center">
        Veuillez patienter pendant la mise à jour de vos données. Cette fenêtre
        se fermera toute seule une fois la mise à jour terminée.
      </p>
      <div className="flex justify-center mt-4 md:mt-8">
        <Spinner />
      </div>
    </Modal>
  )
}
