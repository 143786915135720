import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useWindowSize } from "../hooks/useWindowSize"
import { useGetCalendarQuery } from "../utils/__generated__/graphql"
import { StateType } from "../types"
import CalendarMobile from "../components/calendar/CalendarMobile"
import Calendar from "../components/calendar/Calendar"
import { DateTime } from "luxon"
import { getFullCalendarMonth } from "../utils/date"

const CalendarPage = () => {
  const [currentDate, setCurrentDate] = useState(DateTime.now())
  const { storeId, companyName, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const [lastDateFetched, setLastDateFetched] = useState(DateTime.now())
  const { isLG } = useWindowSize()

  const allDays = getFullCalendarMonth(currentDate)

  useEffect(() => {
    if (currentDate.hasSame(lastDateFetched, "day")) return
    setLastDateFetched(currentDate)
  }, [currentDate, lastDateFetched])

  const { data } = useGetCalendarQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
        start_date: allDays[0].toFormat("yyyy-MM-dd"),
        end_date: allDays[allDays.length - 1].toFormat("yyyy-MM-dd"),
      },
    },
  })

  return (
    <div className="py-6 px-2 grow">
      {isLG ? (
        <Calendar
          companyName={companyName}
          data={data}
          storeCurrency={storeCurrency}
          currentDate={currentDate}
          allDays={allDays}
          setCurrentDate={setCurrentDate}
        />
      ) : (
        <CalendarMobile
          companyName={companyName}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          data={data}
          allDays={allDays}
          storeCurrency={storeCurrency}
        />
      )}
    </div>
  )
}

export default CalendarPage
