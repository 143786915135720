import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { Spinner } from "../ui/Spinner"

interface RedirectToPageProps {
  to: string
}

export function RedirectToPage({ to }: RedirectToPageProps): JSX.Element {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const stringifiedSearchParams =
      searchParams.size > 0 ? `?${searchParams.toString()}` : ""

    navigate(`${to}${stringifiedSearchParams}`, { replace: true })
  }, [navigate, searchParams, to])

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner invertColors className="w-10 h-10" />
    </div>
  )
}
