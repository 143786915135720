import { AllMercurialInfo } from "../reducers/mercurialReducer"
import { StoreSettings } from "./__generated__/graphql"

export function customNullLastComparator(
  valueA: number | string | null | undefined,
  valueB: number | string | null | undefined,
): number {
  if (valueA === null || valueA === undefined) return 1
  if (valueB === null || valueB === undefined) return -1
  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}

export function customBooleanComparator(
  valueA: boolean | null | undefined,
  valueB: boolean | null | undefined,
): number {
  if (valueA === valueB) return 0
  if (valueA === true) return -1
  return 1
}

type Order = "asc" | "desc"

export function alphabeticalSort(a: string, b: string, order: Order = "asc") {
  if (order === "asc") {
    if (a < b) return -1
    if (a > b) return 1
    return 0
  }
  if (a < b) return 1
  if (a > b) return -1
  return 0
}

export function numericalSort(a: number, b: number, order: Order = "asc") {
  if (order === "asc") {
    return a - b
  }
  return b - a
}

export function categoriesSort(
  a: Partial<AllMercurialInfo>,
  b: Partial<AllMercurialInfo>,
  categoriesOrder: StoreSettings["categories_orders"],
) {
  if (
    categoriesOrder === null ||
    categoriesOrder === undefined ||
    categoriesOrder.length === 0
  )
    return 0
  const familyNameA = a.family_name ?? "autres"
  const familyNameB = b.family_name ?? "autres"
  const familyOrderA =
    categoriesOrder.findIndex((order) => order.name === familyNameA) ?? -1
  const familyOrderB =
    categoriesOrder.findIndex((order) => order.name === familyNameB) ?? -1

  if (familyOrderA !== familyOrderB) {
    return familyOrderA - familyOrderB
  }

  const subFamilyOrderA =
    categoriesOrder[familyOrderA]?.children.findIndex(
      (child) => child === a.sub_family_name,
    ) ?? -1
  const subFamilyOrderB =
    categoriesOrder[familyOrderB]?.children.findIndex(
      (child) => child === b.sub_family_name,
    ) ?? -1

  return subFamilyOrderA - subFamilyOrderB
}

export function bestSellerSort(
  a: Partial<AllMercurialInfo>,
  b: Partial<AllMercurialInfo>,
  hasPromoAndNewReferencesFirst: boolean,
) {
  if (hasPromoAndNewReferencesFirst) {
    if (a.promotion === true && a.promotion === b.promotion) return 0
    if (a.promotion === true) return -1
    if (b.promotion === true) return 1
    if (a.new_reference === true && a.new_reference === b.new_reference)
      return 0
    if (a.new_reference === true) return -1
    if (b.new_reference === true) return 1
  }

  // 1. Sort by `last_weeks_sales` in descending order
  if ((a.last_weeks_sales ?? 0) !== (b.last_weeks_sales ?? 0)) {
    return (b.last_weeks_sales ?? 0) - (a.last_weeks_sales ?? 0)
  }

  // 2. Prioritize references with a non-empty `quantity_predicted_array`
  const hasQuantityPredictedA = (a.quantity_predicted_array ?? []).length > 0
  const hasQuantityPredictedB = (b.quantity_predicted_array ?? []).length > 0

  if (hasQuantityPredictedA !== hasQuantityPredictedB) {
    return hasQuantityPredictedA ? -1 : 1
  }

  // 3. Sort by `delivery_historical_quantities` (those with more receptions first)
  const quantityReceptionsA =
    a.delivery_historical_quantities?.reduce(
      (acc, curr) => acc + (curr.quantity ?? 0) / (a.colisage ?? 1),
      0,
    ) ?? 0
  const quantityReceptionsB =
    b.delivery_historical_quantities?.reduce(
      (acc, curr) => acc + (curr.quantity ?? 0) / (b.colisage ?? 1),
      0,
    ) ?? 0

  if (quantityReceptionsA !== quantityReceptionsB) {
    return quantityReceptionsB - quantityReceptionsA
  }

  // 4. Sort by `sold_but_not_active` (those with `sold_but_not_active` first)
  if ((a.sold_but_not_active ?? false) !== (b.sold_but_not_active ?? false)) {
    return a.sold_but_not_active ? -1 : 1
  }

  // 5. Move inactive items (`active = false`) to the end
  const aActive = a.active ?? true
  const bActive = b.active ?? true

  if (aActive !== bActive) {
    return aActive ? -1 : 1
  }

  // If all else is equal, return 0 (meaning they're equal)
  return 0
}
