import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Listbox, Transition } from "@headlessui/react"
import { type StateType, type DispatchActionType } from "../../types"
import { SortOption } from "../../reducers/userInterfaceReducer"
import { Check } from "lucide-react"

interface FilterSelectProps {
  page: "inventoryPage"
}

const FilterSelect = ({ page }: FilterSelectProps): JSX.Element => {
  const { storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { sortOption } = useSelector(
    (state: StateType) => state.userInterfaceReducer[page],
  )

  const options: Record<SortOption, string> = {
    [SortOption.BestSeller]: "Meilleures ventes",
    [SortOption.Categories]: "Ordre personnalisé",
    [SortOption.Alphabetical]: "A → Z",
    [SortOption.Breakage]: "Pires démarques",
    [SortOption.Promotion]: "Promotion",
    [SortOption.QuantityActual]: "Quantité commandée",
    [SortOption.StockQuantity]: "Quantité en stock",
    [SortOption.SortIndex]: "Ordre de la mercuriale",
    [SortOption.Typology]: "Typologie",
  }

  const dispatch = useDispatch<DispatchActionType>()

  const handleChange = (event: SortOption): void => {
    dispatch({
      type: "setSortOption",
      payload: {
        page,
        sortOption: event,
      },
    })
  }

  return (
    <Listbox value={sortOption} onChange={handleChange}>
      <Listbox.Button className="relative h-full w-full cursor-default rounded-lg bg-white py-2 lg:py-2 px-2 lg:px-3 text-left shadow focus:outline-none text-xs lg:text-base flex items-center border-2 border-white focus:border-green-500">
        <span className="pointer-events-none flex items-center lg:mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            fill="none"
            className="w-4 h-4 lg:w-[18px] lg:h-[18px]"
          >
            <path
              d="M9 3.75V14.25M9 14.25L14.25 9M9 14.25L3.75 9"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="hidden lg:block truncate flex-auto font-medium">
          {options[sortOption]}
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-base z-50">
          {Object.entries(options)
            .filter(([key]) => {
              return (
                key !== SortOption.Typology ||
                storeSettings?.typologies !== null
              )
            })
            .map(([key, value]) => (
              <Listbox.Option
                key={key}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-green-100 text-green-900" : "text-gray-900"
                  }`
                }
                value={key}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`text-xs lg:text-base block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {value}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-500">
                        <Check
                          className="h-4 w-4 lg:h-5 lg:w-5"
                          aria-hidden="true"
                        />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  )
}

export default FilterSelect
