import { type IconType } from "./types"

export function OneQuarterIcon({ className }: IconType): JSX.Element {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.16101 7.17H0.809008V5.14H5.33101V15.5H3.16101V7.17Z"
        fill="currentColor"
      />
      <path
        d="M21.181 21.666H19.907V23.5H17.765V21.666H12.613V19.678L17.079 13.14H19.907V19.678H21.181V21.666ZM15.021 19.678H17.765V15.646L15.021 19.678Z"
        fill="currentColor"
      />
      <line
        x1="4.49456"
        y1="24.955"
        x2="17.4946"
        y2="4.95501"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  )
}
