import {
  ArrowPathIcon,
  CalendarDaysIcon,
  CloudArrowUpIcon,
  WifiIcon,
} from "@heroicons/react/24/outline"

export function WifiAlertIcon() {
  return (
    <div className="w-fit bg-blue-50 border-blue-20 border-8 text-blue-700 rounded-full p-2">
      <WifiIcon className="w-6 h-6" />
    </div>
  )
}

export function RefreshAlertIcon() {
  return (
    <div className="w-fit bg-blue-50 border-blue-20 border-8 text-blue-700 rounded-full p-2">
      <ArrowPathIcon className="w-6 h-6" />
    </div>
  )
}

export function CalendarAlertIcon() {
  return (
    <div className="w-fit bg-blue-50 border-blue-20 border-8 text-blue-700 rounded-full p-2">
      <CalendarDaysIcon className="w-6 h-6" />
    </div>
  )
}

export function SaveAlertIcon() {
  return (
    <div className="w-fit bg-yellow-50 border-yellow-20 border-8 text-yellow-800 rounded-full p-2">
      <CloudArrowUpIcon className="w-6 h-6" />
    </div>
  )
}
