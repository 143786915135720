// components/SupplierSelection.tsx
import React from "react"
import { Button } from "@/components/ui/button"

interface Supplier {
  supplierId: string
  supplierName: string
}

interface SupplierSelectionProps {
  suppliers: Supplier[]
  selectedSupplier: string
  onSelect: (supplierId: string) => void
}

const SupplierSelection: React.FC<SupplierSelectionProps> = ({
  suppliers,
  selectedSupplier,
  onSelect,
}) => {
  return (
    <div className="flex flex-wrap space-x-2 mb-4">
      {suppliers.map((supplier) => (
        <Button
          key={supplier.supplierId}
          variant={
            selectedSupplier === supplier.supplierId ? "default" : "outline"
          }
          onClick={() => onSelect(supplier.supplierId)}
          className={selectedSupplier === supplier.supplierId ? "" : ""}
        >
          {supplier.supplierName}
        </Button>
      ))}
    </div>
  )
}

export default SupplierSelection
