import { type IconType } from "./types"
import { twMerge } from "tailwind-merge"

export function ArrowRightIcon({ className }: IconType): JSX.Element {
  const mergedClassName = twMerge("w-6 h-6", className)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={mergedClassName}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
      />
    </svg>
  )
}
