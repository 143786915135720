import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { AlertTriangleIcon } from "../../assets/AlertTriangle"
import { Button } from "../../ui/Button"
import { MercurialReducerState } from "../../reducers/mercurialReducer"

interface MultipleMercurialesModalProps {
  isOpen: boolean
  onClose: () => void
  onSelect: (dimMercurialeId: string) => void
  dimMercuriales: MercurialReducerState["dimMercuriales"]
}

export function MultipleMercurialesModal({
  isOpen,
  onClose,
  onSelect,
  dimMercuriales,
}: MultipleMercurialesModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col gap-4">
                <div className="flex justify-center items-center">
                  <AlertTriangleIcon />
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {dimMercuriales?.length} mercuriales sont disponibles
                </Dialog.Title>
                <p className="text-center text-gray-500 text-sm">
                  Choisissez sur quelle mercuriale vous voulez commander.
                </p>
                <div className="w-full grid grid-cols-2 items-center gap-3 justify-center">
                  {dimMercuriales?.map((dimMercuriale) => (
                    <Button
                      key={dimMercuriale.dimMercurialeId}
                      color="ghost"
                      onClick={() => {
                        onSelect(dimMercuriale.dimMercurialeId)
                      }}
                      className="w-full h-[44px] text-[16px]"
                    >
                      {dimMercuriale.orderPickupTime
                        .slice(0, -3)
                        .replace(":", "h")}
                    </Button>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
