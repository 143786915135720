const Logo = (): JSX.Element => {
  return (
    <svg
      width="108"
      height="38"
      viewBox="0 0 657 235"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M276.087 93.3158C294.743 93.3158 307.676 83.1711 307.676 67.0721C307.676 50.9728 294.743 40.8281 276.087 40.8281C257.43 40.8281 244.498 50.9728 244.498 67.0721C244.498 83.1711 257.43 93.3158 276.087 93.3158ZM304.708 101.476H247.466V224.976H304.708V101.476ZM382.721 97.0648C346.044 97.0648 321.451 121.544 321.451 163.226C321.451 204.907 346.044 229.387 382.721 229.387C399.681 229.387 415.158 224.314 426.606 210.2V224.976H483.847V61.7791H426.606V116.252C415.158 102.137 399.681 97.0648 382.721 97.0648ZM400.105 187.485C383.993 187.485 375.301 177.781 375.301 163.226C375.301 148.671 383.993 138.967 400.105 138.967C409.222 138.967 418.974 142.054 426.606 152.199V174.253C418.974 184.398 409.222 187.485 400.105 187.485ZM575.377 96.6239C555.661 96.6239 529.583 100.152 512.199 107.43L521.74 143.819C537.004 137.203 552.269 135.218 563.292 135.218C584.492 135.218 596.152 142.716 596.152 154.625V162.123C587.249 153.081 572.197 147.788 554.176 147.788C530.008 147.788 499.902 157.713 499.902 188.588C499.902 219.463 530.008 229.387 554.176 229.387C572.197 229.387 587.249 224.094 596.152 215.052V215.714C596.152 219.022 597.637 222.55 599.757 224.976H657C653.608 217.919 652.123 210.862 652.123 202.04V155.507C652.123 117.795 623.714 96.6239 575.377 96.6239ZM597.002 189.249C590.216 196.748 580.041 198.732 572.62 198.732C564.141 198.732 555.023 196.086 555.023 188.588C555.023 181.089 564.141 178.443 572.62 178.443C580.041 178.443 590.216 180.428 597.002 187.926V189.249Z"
        fill="#4BD575"
      />
      <rect
        y="108.214"
        width="208.218"
        height="60.2231"
        rx="30.1115"
        fill="#FF4755"
      />
      <rect
        x="65.0635"
        y="106.588"
        width="79.1721"
        height="63.4765"
        rx="31.7382"
        fill="#FF4755"
        stroke="white"
        strokeWidth="3.2534"
      />
      <rect
        x="43.0422"
        y="45.4838"
        width="131.794"
        height="60.2231"
        rx="30.1115"
        fill="#FF4755"
      />
      <rect
        x="31.6824"
        y="43.8571"
        width="79.1721"
        height="63.4765"
        rx="31.7382"
        fill="#FF4755"
        stroke="white"
        strokeWidth="3.2534"
      />
      <rect
        x="33.3091"
        y="170.947"
        width="139.004"
        height="60.2231"
        rx="30.1115"
        fill="#FF4755"
      />
      <rect
        x="98.3726"
        y="169.32"
        width="79.1721"
        height="63.4765"
        rx="31.7382"
        fill="#FF4755"
        stroke="white"
        strokeWidth="3.2534"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.792 0.000244141C138.702 0.142663 141.606 0.289398 144.516 0.431818C145.04 27.5778 127.447 46.1873 93.7084 42.2945C93.3543 21.1344 102.002 8.42888 119.882 2.58969C125.184 1.72654 130.49 0.863391 135.792 0.000244141Z"
        fill="#4BD575"
      />
    </svg>
  )
}

export default Logo
