import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { persistor } from "../store"
import { Spinner } from "../ui/Spinner"
import { useAuth0 } from "@auth0/auth0-react"

export function LogoutPage() {
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  const handleLogout = useCallback(async () => {
    dispatch({ type: "resetState" })
    await persistor.flush()
    await persistor.purge()
    logout({ logoutParams: { returnTo: window.location.origin } })
  }, [dispatch, logout])

  useEffect(() => {
    handleLogout()
  }, [handleLogout])

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner invertColors className="w-10 h-10" />
    </div>
  )
}
