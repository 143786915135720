import { ProductCard } from "./ProductCard"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { ProductAlertSection } from "./ProductAlertSection"

interface BoxProductsProps {
  boxProductIds: string[] | undefined
  boxProducts: AllMercurialInfo[]
  allItems: AllMercurialInfo[]
}

export const BoxProducts = ({
  boxProductIds,
  boxProducts,
  allItems,
}: BoxProductsProps) => (
  <ProductAlertSection
    title={`Produits en box : ${boxProducts.length}`}
    message="Attention ces références sont commandées par box"
    isAllFixed={boxProducts.length === 0}
  >
    {(boxProductIds ?? []).map((productId, index) => {
      const product = allItems.find((item) => item.mercuriale_id === productId)
      if (!product) return null

      const isFix = boxProducts.includes(product)
      return <ProductCard key={index} product={product} isFix={isFix} />
    })}
  </ProductAlertSection>
)
