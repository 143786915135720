import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { type StateType } from "../types"
import { getDiffBetweenDates } from "../utils/getDiffBetweenDates"
import { filteredMercurialeReducerSelector } from "../selectors/mercurialeSelectors"
import { MapPinIcon } from "@heroicons/react/24/solid"

const getWeatherIcon = (weather?: string): JSX.Element => {
  if (weather === undefined) return <></>
  const lowerCaseWeather = weather.toLowerCase()

  if (lowerCaseWeather.includes("light rain"))
    return (
      <img
        src="/weather/rain.png"
        alt="light rain"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("snow"))
    return (
      <img src="/weather/snow.png" alt="snow" className="w-[148px] h-[106px]" />
    )
  if (lowerCaseWeather.includes("extreme"))
    return (
      <img
        src="/weather/wind.png"
        alt="extreme"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("clear"))
    return (
      <img
        src="/weather/clear.png"
        alt="clear"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("rain"))
    return (
      <img src="/weather/rain.png" alt="rain" className="w-[148px] h-[106px]" />
    )
  if (lowerCaseWeather.includes("mist"))
    return (
      <img
        src="/weather/clouds.png"
        alt="mist"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("thunderstorm"))
    return (
      <img
        src="/weather/thunderstorm.png"
        alt="thunderstorm"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("drizzle"))
    return (
      <img
        src="/weather/rain.png"
        alt="drizzle"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("fog"))
    return (
      <img
        src="/weather/clouds.png"
        alt="fog"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("clouds"))
    return (
      <img
        src="/weather/clouds.png"
        alt="clouds"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("overcast"))
    return (
      <img
        src="/weather/clouds.png"
        alt="overcast"
        className="w-[148px] h-[106px]"
      />
    )
  if (lowerCaseWeather.includes("partially cloudy"))
    return (
      <img
        src="/weather/partial-clouds.png"
        alt="partially cloudy"
        className="w-[148px] h-[106px]"
      />
    )

  return (
    <img
      src="/weather/clear.png"
      alt="light rain"
      className="w-[148px] h-[106px]"
    />
  )
}

const getFrenchWeather = (weather?: string): string => {
  if (weather === undefined) return ""
  const lowerCaseWeather = weather.toLowerCase()

  if (lowerCaseWeather.includes("light rain")) return "Faible pluie"
  if (lowerCaseWeather.includes("snow")) return "Neige"
  if (lowerCaseWeather.includes("extreme")) return "Tempête"
  if (lowerCaseWeather.includes("clear")) return "Dégagé"
  if (lowerCaseWeather.includes("rain")) return "Pluie"
  if (lowerCaseWeather.includes("mist")) return "Brumeux"
  if (lowerCaseWeather.includes("thunderstorm")) return "Orage"
  if (lowerCaseWeather.includes("drizzle")) return "Bruine"
  if (lowerCaseWeather.includes("fog")) return "Nuageux"
  if (lowerCaseWeather.includes("clouds")) return "Nuageux"
  if (lowerCaseWeather.includes("overcast")) return "Couvert"
  if (lowerCaseWeather.includes("partially cloudy"))
    return "Partiellement nuageux"

  return weather
}

export function Weather(): JSX.Element {
  const { storeCity, storeCountry } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const { mercurialAndStoreInventories } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const mercurialeId = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.selectedInventory?.mercurialeId,
  )
  const [selectedDay, setSelectedDay] = useState(0)
  const [weatherData, setWeatherData] = useState<
    { conditions: string; temperature: number }[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const currentDate = useMemo(() => new Date(), [])
  const dates = useMemo(() => {
    return new Array(7).fill(null).map((_, i) => {
      const date = new Date()
      date.setDate(currentDate.getDate() + i)
      return date
    })
  }, [currentDate])

  const getWeather = useCallback(async () => {
    setIsLoading(true)
    const apiKey = "43ZQCYJPLMGUC5V7LE436N83Z"

    // Call the weather API using the coordinates of Paris
    const weatherUrl = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${storeCity ?? "Paris"},${storeCountry ?? "France"}?unitGroup=metric&key=${apiKey}`

    try {
      const weatherResponse = await fetch(weatherUrl)

      const weatherData = await weatherResponse.json()
      // Log the current temperature in Paris

      if (weatherData.days === undefined || weatherData.days.length === 0) {
        throw new Error()
      }
      setWeatherData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        weatherData.days.map((day: any) => ({
          conditions: day.conditions,
          temperature: day.tempmax.toFixed(0),
        })),
      )
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [storeCity, storeCountry])

  useEffect(() => {
    if (!online) return
    void getWeather()
  }, [currentDate, getWeather, online])

  useEffect(() => {
    const reference = mercurialAndStoreInventories?.find(
      (mercurialeInfos) => mercurialeInfos.mercuriale_id === mercurialeId,
    )
    const referenceReceptionDate =
      reference?.order_expected_reception_date ??
      reference?.mercuriale_reception_date
    if (reference !== undefined && typeof referenceReceptionDate === "string") {
      const receptionDate = new Date(referenceReceptionDate)
      const dayIndex = getDiffBetweenDates(currentDate, receptionDate)
      const selectedDayInded = dayIndex >= 0 && dayIndex <= 6 ? dayIndex : 0
      setSelectedDay(selectedDayInded)
      return
    }
    setSelectedDay(0)
  }, [
    currentDate,
    dates,
    getWeather,
    mercurialAndStoreInventories,
    mercurialeId,
  ])

  return (
    <div className="bg-white shadow rounded py-2 px-4 text-neutral-500 relative flex flex-col gap-2 overflow-hidden">
      <div className="absolute -top-4 -right-4 h-full w-full flex justify-end">
        {!isLoading && getWeatherIcon(weatherData[selectedDay]?.conditions)}
      </div>
      <div className="relative flex items-center gap-4">
        <p className="text-3xl pr-4 border-r border-zinc-100">
          {!isLoading ? (
            `${weatherData[selectedDay]?.temperature}°`
          ) : (
            <span className="w-8 h-6 bg-gray-300 animate-pulse rounded-full" />
          )}
        </p>
        <div>
          <p className="text-xs">
            {!isLoading ? (
              getFrenchWeather(weatherData[selectedDay]?.conditions)
            ) : (
              <span className="w-28 h-4 bg-gray-300 animate-pulse rounded-full" />
            )}
          </p>
          <div className="flex items-center gap-0.5">
            <MapPinIcon className="w-4 h-4" />
            <p className="text-xxs font-black">{storeCity}</p>
          </div>
        </div>
      </div>
      <div className="relative flex gap-1">
        {dates.map((date, i) => (
          <button
            key={i}
            disabled={!online}
            onClick={() => {
              setSelectedDay(i)
            }}
            className={`${selectedDay === i ? "bg-zinc-500 text-white font-bold" : ""} flex-1 p-1 text-xs rounded-sm capitalize`}
          >
            {i === 0
              ? "Auj."
              : new Intl.DateTimeFormat("fr-FR", { weekday: "short" }).format(
                  date,
                )}
          </button>
        ))}
      </div>
    </div>
  )
}
