import { AllMercurialInfo } from "../reducers/mercurialReducer"
import { addZeroBeforeNumber } from "./addZeroBeforeNumber"

export function computeSortIndex(data: Partial<AllMercurialInfo>) {
  if (data?.sub_family_key === undefined || data?.sub_family_key === null) {
    return null
  }
  const ifls = addZeroBeforeNumber(data.order_code ?? 0)

  return parseInt(`${data.sub_family_key}${ifls}`)
}

export function computeQuantityActual(
  data: Partial<AllMercurialInfo>,
  useKgPce?: boolean | null,
) {
  const quantity = data?.quantity_actual ?? 0
  return useKgPce === true ? quantity : quantity * (data?.colisage ?? 1)
}

export function computeStockQuantity(
  data: Partial<AllMercurialInfo>,
  useKgPce?: boolean | null,
  separateFloorInventory?: boolean | null,
) {
  const quantity =
    (data?.back_inventory_qty ?? 0) +
    (separateFloorInventory === true ? (data?.floor_inventory_qty ?? 0) : 0)
  return useKgPce === true ? quantity : quantity * (data?.colisage ?? 1)
}
