import { useMemo, type AnchorHTMLAttributes, type ReactNode } from "react"
import { twMerge } from "tailwind-merge"

export interface NavLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  leftIcon?: ReactNode
  url: string
  label: string
  color?:
    | "green"
    | "blue"
    | "red"
    | "ghost"
    | "yellow"
    | "lightGreen"
    | "darkGreen"
    | "black"
    | "blackGhost"
    | "green2"
  size?: "sm" | "md" | "lg" | "custom"
}

export const NavLink = ({
  leftIcon,
  url,
  label,
  color = "ghost",
  size = "md",
  className,
  ...props
}: NavLinkProps): JSX.Element => {
  const colorClassName = useMemo(() => {
    switch (color) {
      case "green":
        return "bg-green-500 hover:bg-green-600 text-white"
      case "blue":
        return "bg-blue-500 hover:bg-blue-700 text-white"
      case "red":
        return "bg-red-500 hover:bg-red-700 text-white "
      case "ghost":
        return "border text-black "
      case "yellow":
        return "bg-yellow-500 hover:bg-yellow-600 text-white"
      case "lightGreen":
        return "bg-green-20 hover:bg-green-30 text-green-900"
      case "darkGreen":
        return "bg-green-900 text-white"
      case "black":
        return "bg-neutral-700 text-white"
      case "blackGhost":
        return "border-2 border-black text-black"
      case "green2":
        return "bg-green-500 hover:bg-green-600 text-white  shadow-sm shadow-green-500/50 rounded-lg text-center"
      default:
        return ""
    }
  }, [color])

  const sizeClassName = useMemo(() => {
    switch (size) {
      case "sm":
        return "text-xs px-3 py-2 h-8"
      case "md":
        return "text-base px-4 h-[35px]"
      case "lg":
        return "text-lg xl:text-xl 2xl:text-2xl px-5 py-3.5"
      case "custom":
        return ""
      default:
        return ""
    }
  }, [size])

  const mergedClassName = twMerge(
    `${colorClassName} ${sizeClassName} flex justify-center items-center transition ease-in duration-200 rounded-md shadow-sm touch-manipulation disabled:bg-gray-300 disabled:text-white `,
    className,
  )

  return (
    <a href={url} {...props} className={mergedClassName}>
      {leftIcon}
      <span className="flex-grow truncate">{label}</span>
    </a>
  )
}
