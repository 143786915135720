import { HalfIcon } from "../ui/icons/HalfIcon"
import { OneQuarterIcon } from "../ui/icons/OneQuarterIcon"
import { ThreeQuartersIcon } from "../ui/icons/ThreeQuartersIcon"

export interface GetQuarterValueResult {
  value?: number
  decimal?: number
  icon?: JSX.Element
}

export function getQuarterValue(
  value?: number,
  iconClassName?: string,
): GetQuarterValueResult {
  if (value === undefined) return {}

  const valueToQuarter = Math.round(value * 4) / 4
  let decimal = valueToQuarter - Math.floor(valueToQuarter)

  // Special case to return 1/4 instead of 0 when the value is for exemple 0.10
  if (value > 0 && Math.floor(valueToQuarter) < 1 && decimal === 0) {
    decimal = 0.25
  }
  switch (decimal) {
    case 0.25:
      return {
        value: Math.floor(valueToQuarter),
        decimal,
        icon: <OneQuarterIcon className={iconClassName} />,
      }
    case 0.5:
      return {
        value: Math.floor(valueToQuarter),
        decimal,
        icon: <HalfIcon className={iconClassName} />,
      }
    case 0.75:
      return {
        value: Math.floor(valueToQuarter),
        decimal,
        icon: <ThreeQuartersIcon className={iconClassName} />,
      }
    default:
      return {
        value: Math.floor(valueToQuarter),
      }
  }
}
