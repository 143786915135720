import PotentialRevenue from "./PotentialRevenue"
import UpdateInventoryPad from "./UpdateInventoryPad"
import OrderButton from "../button/OrderButton"
import { Weather } from "../Weather"
import { twMerge } from "tailwind-merge"
import { Dispatch, SetStateAction } from "react"
import { Button } from "../../ui/Button"
import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import { getTimeAgo } from "../../utils/getTimeAgo"

interface SiderbarProps {
  mercurialInfoLoading?: boolean
  mercurialAdditionalInfoLoading?: boolean
  batchUpdateLoading?: boolean
  updateInventory?: (value: string) => Promise<void>
  hideWeather?: boolean
  hidePotentialRevenue?: boolean
  hideOrderButton?: boolean
  className?: string
  setIsOrderReceptionDateModalOpen: Dispatch<SetStateAction<boolean>>
  companyName: string
  previousOrderInventoryComputeDate: string | undefined
}

export function Sidebar({
  mercurialInfoLoading = false,
  mercurialAdditionalInfoLoading = false,
  batchUpdateLoading = false,
  hideWeather = false,
  hidePotentialRevenue = false,
  hideOrderButton = false,
  updateInventory,
  className,
  setIsOrderReceptionDateModalOpen,
  companyName,
  previousOrderInventoryComputeDate,
}: SiderbarProps): JSX.Element {
  const mergedClassName = twMerge(
    "grid grid-cols-1 gap-8 items-center",
    className,
  )
  return (
    <div className={mergedClassName}>
      {previousOrderInventoryComputeDate !== undefined && (
        <p className="text-[#8a8a8a] text-sm text-center">
          Mise à jour des stocks :{" "}
          <span className="font-bold">
            {getTimeAgo(new Date(previousOrderInventoryComputeDate))}
          </span>
        </p>
      )}
      {!hideWeather && <Weather />}
      {!hidePotentialRevenue && (
        <PotentialRevenue
          isLoading={mercurialInfoLoading || mercurialAdditionalInfoLoading}
        />
      )}
      {updateInventory !== undefined && (
        <UpdateInventoryPad
          isLoading={
            mercurialInfoLoading ||
            mercurialAdditionalInfoLoading ||
            batchUpdateLoading
          }
          updateInventory={updateInventory}
        />
      )}
      {!hideOrderButton && (
        <div className="w-full flex items-center justify-center gap-1">
          <OrderButton
            isLoading={
              mercurialInfoLoading ||
              mercurialAdditionalInfoLoading ||
              batchUpdateLoading
            }
          />
          {companyName === "biomonde" && (
            <Button
              onClick={() => setIsOrderReceptionDateModalOpen(true)}
              className="text-green-900 p-0 size-14"
              color="ghost"
            >
              <CalendarDaysIcon className="size-6" />
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
