export function removeDuplicates(array: string[]): string[] {
  const uniqueValues = new Set()
  return array.filter((value) => {
    const keyValue = value.toLowerCase()

    if (uniqueValues.has(keyValue)) {
      return false // Duplicate found, exclude from the result
    }

    uniqueValues.add(keyValue)
    return true // Unique value, include in the result
  })
}

export function removeDuplicatesValues<
  T extends Record<string | number | symbol, unknown>,
>(array: T[], key: keyof T): T[] {
  const uniqueValues = new Set()

  return array.filter((value) => {
    const keyValue = value[key]

    if (uniqueValues.has(keyValue)) {
      return false // Duplicate found, exclude from the result
    }

    uniqueValues.add(keyValue)
    return true // Unique value, include in the result
  })
}
