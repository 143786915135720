import { Label } from "./Label"
import { ReceiptPercentIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import BreakageLabel from "../components/inventory/BreakageLabel"
import { StoreReducerState } from "../reducers/storeReducer"
import { CubeIcon } from "@heroicons/react/24/outline"
import { Delivery } from "./Delivery"
import { ShoppingCart, Tag, TrophyIcon } from "lucide-react"
import { Badge } from "@/components/ui/badge"

interface BadgesProps {
  expanded?: boolean
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  isBestSeller?: boolean
  storeSuppliers: StoreReducerState["storeSuppliers"]
  hideBreakageLabel?: boolean
  hide?: boolean
  isFirstRow?: boolean
  storeCurrency: string | null
  companyId: string | null
}

export function Badges({
  expanded = false,
  data,
  isBestSeller = false,
  storeSuppliers,
  hideBreakageLabel = false,
  hide = false,
  isFirstRow = false,
  storeCurrency,
  companyId,
}: BadgesProps) {
  const supplier =
    (storeSuppliers ?? []).length >= 2
      ? storeSuppliers?.find((supplier) => supplier.id === data.supplier_id)
      : undefined

  const breakagePercent =
    typeof data?.breakage_percentage === "number"
      ? Math.round(data.breakage_percentage)
      : 0

  // Add multiplier for Naturalia
  const breakageAmount =
    companyId === "b83fc9aa-c3e3-420c-849f-4cd758e53d1d" &&
    typeof data?.breakage_amount === "number"
      ? Math.round(data.breakage_amount * 1.6)
      : Math.round(data?.breakage_amount ?? 0)

  const hasSupplier = supplier !== undefined
  const hasSignificantBreakageAmount = breakageAmount >= 1
  const isPromotionWithoutOffer =
    data?.promotion === true &&
    (typeof data?.message_promotion !== "string" ||
      data.message_promotion.toLowerCase() === "sans offre")
  const isPromotionWithOffer =
    typeof data?.message_promotion === "string" &&
    data.message_promotion.toLowerCase() !== "sans offre"
  const isMercurialeNameBox =
    data.mercuriale_name?.toLowerCase().includes("box") === true
  const isExpanded = expanded
  const isNewReference = data.new_reference === true
  const isBestSellerInFirstRow = isBestSeller && isFirstRow
  const isEngagementPromo = data.engagement_promo_flag === true

  const shouldShowLabels =
    hasSupplier ||
    hasSignificantBreakageAmount ||
    isPromotionWithoutOffer ||
    isPromotionWithOffer ||
    isMercurialeNameBox ||
    isExpanded ||
    isNewReference ||
    isBestSellerInFirstRow ||
    isEngagementPromo

  if (!shouldShowLabels || hide) {
    return <></>
  }

  return (
    <div className="flex gap-2">
      {isEngagementPromo === true && (
        <Badge
          variant="default"
          className={
            "bg-gradient-to-r from-orange-500 to-red-500 transition-colors text-xs font-medium text-white"
          }
        >
          <Tag className="w-3.5 h-3.5 mr-1.5" />
          ENGAGEMENT PROMO
        </Badge>
      )}
      {data.new_reference === true && !isEngagementPromo && (
        <Label
          color="yellow"
          variant="filled"
          leftIcon={<SparklesIcon className="w-4 h-4" />}
        >
          Nouveau
        </Label>
      )}
      {isBestSeller && isFirstRow && (
        <Label
          color="gray"
          variant="outline"
          leftIcon={<TrophyIcon className="w-4 h-4 text-red-700" />}
        >
          TOP VENTE
        </Label>
      )}
      {supplier !== undefined && (
        <Label color="gray">{supplier?.supplier_name}</Label>
      )}
      {!hideBreakageLabel && breakageAmount >= 1 && (
        <BreakageLabel
          shrinkage={breakagePercent}
          amount={breakageAmount}
          expanded={expanded}
          storeCurrency={storeCurrency}
        />
      )}
      {/* If 'promotion' is true and 'message_promotion' is null, display 'PROMOTION' */}
      {data?.promotion === true &&
        (typeof data?.message_promotion !== "string" ||
          data.message_promotion.toLowerCase() === "sans offre") && (
          <Label
            color="blue"
            variant="filled"
            leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
          >
            PROMO
            <span className={`${expanded ? "inline" : "hidden"} md:inline`}>
              TION
            </span>
          </Label>
        )}

      {/* If 'message_promotion' is not null, display the message along with the end date */}
      {typeof data?.message_promotion === "string" &&
        data.message_promotion.toLowerCase() !== "sans offre" && (
          <div className="flex items-center">
            <Label
              color="blue"
              variant="filled"
              leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
            >
              {data.message_promotion.toUpperCase()}
            </Label>
            {typeof data?.promotion_end_date === "string" && (
              <p className="ml-1 text-cyan-900 text-xxs md:text-xs leading-[18px] font-bold whitespace-nowrap">
                Fin le{" "}
                {new Intl.DateTimeFormat("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                }).format(new Date(data?.promotion_end_date))}
              </p>
            )}
          </div>
        )}
      {data.mercuriale_name?.toLowerCase().includes("box") === true && (
        <Label color="gray" leftIcon={<CubeIcon className="w-4 h-4" />}>
          BOX
        </Label>
      )}
      {!data.typology_flag && (
        <Badge
          variant="secondary"
          className="text-xs font-medium bg-amber-100 text-amber-800 border border-amber-200  whitespace-nowrap hover:bg-amber-100"
        >
          <ShoppingCart className="w-4 h-4 mr-2" />
          GAMME COMPLEMENTAIRE
        </Badge>
      )}
      {(typeof data.order_expected_reception_date === "string" ||
        typeof data.mercuriale_reception_date === "string") && (
        <Delivery
          date={
            data.order_expected_reception_date ??
            data.mercuriale_reception_date ??
            ""
          }
          className="lg:hidden"
        />
      )}
    </div>
  )
}
