import { Button } from "@/components/ui/button"
import { Popover, PopoverContent } from "@/components/ui/popover"
import { DispatchActionType } from "@/types"
import { PopoverAnchor } from "@radix-ui/react-popover"
import { useDispatch, useSelector } from "react-redux"
import { useResetPrediction } from "./useResetPrediction"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { getUpdatedReferenceSelector } from "@/selectors/mercurialeSelectors"
import { getOrderQuantity } from "@/utils/getOrderQuantity"
import { useMemo } from "react"
import { TriangleAlert } from "lucide-react"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { StoreSettings } from "@/utils/__generated__/graphql"

interface ResetPredictionPopoverProps {
  reference: MercurialReducerState["mercurialAndStoreInventories"][number]
  disabled?: boolean
  hasBigBreakage: boolean
  storeSettings: StoreSettings | null
}

export function ResetPredictionPopover({
  reference,
  disabled,
  hasBigBreakage,
  storeSettings,
}: ResetPredictionPopoverProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const updatedReference = useSelector(
    getUpdatedReferenceSelector(reference.mercuriale_id ?? ""),
  )
  const { reset, isDisabled } = useResetPrediction({ reference, disabled })

  const predictedOrderQuantity = getOrderQuantity({
    backQuantity: reference.back_inventory_qty ?? 0,
    floorQuantity: reference.floor_inventory_qty ?? 0,
    predictedQuantityArray: reference.quantity_predicted_array ?? [],
  })

  const showBreakageConfirmOrder = useMemo(() => {
    const isSettingEnable = storeSettings?.show_breakage_confirm_order ?? true

    const isQuantityGreaterThanPrediction =
      (updatedReference?.orderInventoryQuantity ?? 0) > predictedOrderQuantity

    return isSettingEnable && hasBigBreakage && isQuantityGreaterThanPrediction
  }, [
    hasBigBreakage,
    predictedOrderQuantity,
    storeSettings?.show_breakage_confirm_order,
    updatedReference?.orderInventoryQuantity,
  ])

  const showLowSalesConfirmOrder = useMemo(() => {
    const isSettingEnable = storeSettings?.show_low_sales_confirm_order ?? false
    if (!isSettingEnable) return false

    const todayDate = new Date()
    const deliveryHistorycalQuantities =
      reference?.delivery_historical_quantities?.filter(
        (deliveryHistorycalQuantity) => {
          return (
            deliveryHistorycalQuantity.date !== formatDateToYYYYMMDD(todayDate)
          )
        },
      ) ?? []
    const totalDeliveriesQuantity = deliveryHistorycalQuantities.reduce(
      (totalDeliveriesQuantity, deliveryHistorycalQuantity) => {
        return (
          totalDeliveriesQuantity + (deliveryHistorycalQuantity.quantity ?? 0)
        )
      },
      0,
    )
    if (totalDeliveriesQuantity <= 0) return false

    const saleHistorycalQuantities =
      reference?.sale_historical_quantities?.filter(
        (saleHistorycalQuantity) => {
          return saleHistorycalQuantity.date !== formatDateToYYYYMMDD(todayDate)
        },
      ) ?? []
    const totalSalesQuantity = saleHistorycalQuantities.reduce(
      (totalSalesQuantity, saleHistorycalQuantity) => {
        return totalSalesQuantity + (saleHistorycalQuantity.quantity ?? 0)
      },
      0,
    )
    if (totalSalesQuantity === 0 && totalDeliveriesQuantity < 3) return false

    const percentSold = (totalSalesQuantity * 100) / totalDeliveriesQuantity
    const isQuantityGreaterThanPrediction =
      (updatedReference?.orderInventoryQuantity ?? 0) > predictedOrderQuantity

    return percentSold <= 40 && isQuantityGreaterThanPrediction
  }, [
    predictedOrderQuantity,
    reference?.delivery_historical_quantities,
    reference?.sale_historical_quantities,
    storeSettings?.show_low_sales_confirm_order,
    updatedReference?.orderInventoryQuantity,
  ])

  const isOpen = useMemo(() => {
    const isOrderConfirmed = updatedReference?.isOrderConfirmed ?? false
    const isOrderInventoryQuantityUpdated =
      updatedReference?.isOrderInventoryQuantityUpdated === true

    return (
      !isDisabled &&
      (showBreakageConfirmOrder || showLowSalesConfirmOrder) &&
      isOrderConfirmed === false &&
      isOrderInventoryQuantityUpdated
    )
  }, [
    isDisabled,
    showBreakageConfirmOrder,
    showLowSalesConfirmOrder,
    updatedReference?.isOrderConfirmed,
    updatedReference?.isOrderInventoryQuantityUpdated,
  ])

  function getContent() {
    if (showBreakageConfirmOrder) {
      return "Démarque supérieure à 10%"
    }

    return "Ventes < à 40% des dernières livraisons"
  }

  return (
    <Popover open={isOpen}>
      <PopoverAnchor />
      <PopoverContent side="top" className="flex flex-col gap-2">
        <p className="text-sm flex items-center  gap-2">
          <TriangleAlert className="size-4 text-orange-700" />
          {getContent()}
        </p>
        <div className="flex gap-2">
          <Button
            size="sm"
            className="flex-1 py-1 h-fit"
            onClick={() => reset()}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            className="flex-1 py-1 h-fit"
            onClick={() => {
              dispatch({
                type: "updateReference",
                payload: {
                  mercurialeId: reference.mercuriale_id!,
                  isOrderConfirmed: true,
                },
              })
            }}
          >
            Confirmer
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
