import { useState, useEffect } from "react"

export enum Sizes {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
}

interface WindowSize {
  /**
   * window.innerWidth
   */
  width: number
  /**
   * window.innerHeight
   */
  height: number
  /**
   * width >= 640
   */
  isSM: boolean
  /**
   * width >= 768
   */
  isMD: boolean
  /**
   * width >= 1024
   */
  isLG: boolean
  /**
   * width >= 1280
   */
  isXL: boolean
}

function computeSize(width: number, height: number): WindowSize {
  return {
    width,
    height,
    isSM: width >= Sizes.SM,
    isMD: width >= Sizes.MD,
    isLG: width >= Sizes.LG,
    isXL: width >= Sizes.XL,
  }
}

export function useWindowSize(): WindowSize {
  const [size, setSize] = useState<WindowSize>(
    computeSize(window.innerWidth, window.innerHeight),
  )

  useEffect(() => {
    const handleResize = () => {
      setSize(computeSize(window.innerWidth, window.innerHeight))
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return size
}
