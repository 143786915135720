export function getTimeAgo(date: Date) {
  // Get the current date and time
  const now = new Date()

  // Calculate the difference in milliseconds
  const diffInMs = now.getTime() - date.getTime()

  // Convert the difference to minutes, hours, or days as appropriate
  const diffInSeconds = Math.floor(diffInMs / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)
  const diffInWeeks = Math.floor(diffInDays / 7)
  const diffInMonths = Math.floor(diffInWeeks / 4)
  const diffInYears = Math.floor(diffInDays / 365)

  let value: number
  let unit: Intl.RelativeTimeFormatUnit

  if (diffInYears > 0) {
    value = diffInYears
    unit = "year"
  } else if (diffInMonths > 0) {
    value = diffInMonths
    unit = "month"
  } else if (diffInWeeks > 0) {
    value = diffInWeeks
    unit = "week"
  } else if (diffInDays > 0) {
    value = diffInDays
    unit = "day"
  } else if (diffInHours > 0) {
    value = diffInHours
    unit = "hour"
  } else if (diffInMinutes > 0) {
    value = diffInMinutes
    unit = "minute"
  } else {
    value = diffInSeconds
    unit = "second"
  }

  // Use Intl.RelativeTimeFormat to format the relative time
  const rtf = new Intl.RelativeTimeFormat("fr", { numeric: "auto" })
  const relativeTime = rtf.format(-value, unit)

  return relativeTime
}
