import { getUnit } from "../utils/getUnit"
import { StoreReducerState } from "../reducers/storeReducer"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import { addZeroBeforeNumber } from "../utils/addZeroBeforeNumber"

interface ReferenceDetailsProps {
  reference: MercurialReducerState["mercurialAndStoreInventories"][number]
  storeCurrency: StoreReducerState["storeCurrency"]
  storeSettings: StoreReducerState["storeSettings"]
  bestMargin: number | null
}

export function ReferenceDetails({
  bestMargin,
  reference,
  storeCurrency,
  storeSettings,
}: ReferenceDetailsProps) {
  const margin =
    typeof reference.pa === "number" &&
    reference.pa > 0 &&
    typeof reference.pv === "number" &&
    reference.pv > 0
      ? (reference.pv - reference.pa) / reference.pv
      : null

  const formattedMargin =
    margin !== null
      ? new Intl.NumberFormat("fr-FR", {
          maximumFractionDigits: 0,
          style: "percent",
        }).format(margin)
      : ""

  const isBestMargin = bestMargin !== null && margin === bestMargin

  const formatCurrency = (amount: number, currency = "EUR") => {
    return new Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency,
    }).format(amount)
  }

  const price = reference?.pa ?? 0
  const shouldDisplayPrice = storeSettings?.view_pa && price > 0

  const hasPvChange =
    reference.last_pv !== null && reference.last_pv !== reference.pv

  return (
    <div className="flex flex-col text-zinc-500 text-xs md:text-sm font-medium">
      <div className="flex items-center gap-2">
        {reference?.order_code !== null &&
          reference?.order_code !== "10000000" && (
            <p className="font-light">
              {addZeroBeforeNumber(
                (reference?.order_code ?? "").split(/[|;]/).slice(0, 1)[0],
              )}
            </p>
          )}
        {reference?.colisage !== null && (
          <p>
            {reference?.colisage} {getUnit(reference.unit)}
          </p>
        )}
        {reference?.origin !== null && <p>{reference?.origin}</p>}
        {reference?.typology !== null && storeSettings?.typologies !== null && (
          <p>Typologie : {reference?.typology}</p>
        )}
      </div>
      <div className="flex items-center gap-2">
        {shouldDisplayPrice && (
          <div className="flex items-center gap-1">
            <p className="text-xs">PA : </p>
            <p>{formatCurrency(price, storeCurrency ?? "EUR")}</p>
          </div>
        )}
        {typeof reference.pv === "number" &&
          reference.pv > 0 &&
          storeSettings?.view_pv && (
            <div className="flex items-center gap-1">
              <span className="text-xs">PV :</span>
              {hasPvChange && (
                <p className="line-through">
                  {new Intl.NumberFormat("fr-FR", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: storeCurrency ?? "EUR",
                  }).format(reference?.last_pv ?? 0)}
                </p>
              )}
              <p className={hasPvChange ? "font-bold text-[#323232]" : ""}>
                {new Intl.NumberFormat("fr-FR", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(reference?.pv ?? 0)}
              </p>
            </div>
          )}
        {storeSettings?.view_pv && storeSettings?.view_pa && (
          <p className={isBestMargin ? "text-green-800 font-bold" : ""}>
            {formattedMargin}
          </p>
        )}
      </div>
    </div>
  )
}
