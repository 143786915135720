import { FormEventHandler, useEffect, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "../../styles/inventory-page.css"
import InventoryNav from "./InventoryNav"

import { type DispatchActionType, type StateType } from "../../types"
import { type AllMercurialInfo } from "../../reducers/mercurialReducer"
import NewLoading from "../loading/NewLoading"
import { Sidebar } from "./Sidebar"
import FilterSelect from "./FilterSelect"
import { Input } from "../../ui/Input"
import { SearchIcon } from "../../ui/icons/SearchIcon"
import { ItemContent, VirtuosoHandle } from "react-virtuoso"
import { Button } from "../../ui/Button"
import { ChevronUpIcon } from "@heroicons/react/24/outline"
import { useWindowSize } from "../../hooks/useWindowSize"
import { MobileInventoryRecap } from "./MobileInventoryRecap"
import { useOutletContext } from "react-router-dom"
import { InventoryContext } from "./InventoryRoot"
import { InventoryTable } from "./InventoryTable"
import { SaveDataModalState } from "./modals/RetrySaveModal"
import { InventoryModals } from "./modals/InventoryModals"

type Data = Partial<AllMercurialInfo>[]

interface InventoryPageTemplateProps {
  page: "inventoryPage"
  loading?: boolean
  rawData: Data
  dataLength: number
  rowContent: ItemContent<unknown, unknown>
  updateInventory: (value: string) => Promise<void>
  searchedAmount: number
  filteredAmount: number
  unfilteredAmount: number
  currentIndex: number | null
  deduplicateFilteredMercurialeInfos: Data
}

export function InventoryPageTemplate({
  page,
  loading = false,
  rawData,
  dataLength,
  rowContent,
  updateInventory,
  searchedAmount,
  filteredAmount,
  unfilteredAmount,
  currentIndex,
}: InventoryPageTemplateProps): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const [{ synchronisationLoading }] = useOutletContext<InventoryContext>()

  const companyName = useSelector(
    (state: StateType) => state.storeReducer.companyName,
  )
  const {
    searchTerm,
    displayShelfFloorSize,
    suppliersIds,
    familyName,
    subFamilyName,
  } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const previousSearchTerm = useRef(searchTerm)

  const { mercurialAndStoreInventories } = useSelector(
    (state: StateType) => state.mercurialReducer,
  )

  const virtuoso = useRef<VirtuosoHandle>(null)
  const oldScrollTop = useRef<number>(0)

  const { height, isMD, isLG } = useWindowSize()

  const [isOfflineRefreshModalOpen, setIsOfflineRefreshModalOpen] =
    useState(false)
  const [isDataSynchronizeModalOpen, setIsDataSynchronizeModalOpen] =
    useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const [saveDataModalState, setSaveDataModalState] =
    useState<SaveDataModalState>({ isOpen: false, backToHome: false })
  const [isOrderReceptionDateModalOpen, setIsOrderReceptionDateModalOpen] =
    useState(false)

  const [isScrollingDown, setIsScrollingDown] = useState(false)

  // Prevent refesh when iternet connection is offline
  useEffect(() => {
    const beforeUnloadListener: EventListenerOrEventListenerObject = (
      event,
    ) => {
      if (window.navigator.onLine) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = false
    }

    window.addEventListener("beforeunload", beforeUnloadListener)

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener)
    }
  }, [])

  const handleSearchTerm: FormEventHandler<HTMLInputElement> = (event) => {
    virtuoso.current?.scrollToIndex({
      index: 0,
      offset: -50,
    })
    dispatch({
      type: "setSearchBar",
      payload: { page, searchTerm: event.currentTarget.value },
    })
  }

  useEffect(() => {
    if (
      previousSearchTerm.current !== "" &&
      searchTerm === "" &&
      currentIndex !== null
    ) {
      virtuoso.current?.scrollToIndex({
        index: currentIndex ?? 0,
        align: "start",
        offset: -50,
      })
    }

    previousSearchTerm.current = searchTerm
  }, [searchTerm, currentIndex])

  useEffect(() => {
    if ((isMD && !isLG) || !displayShelfFloorSize) return
    dispatch({
      type: "toggleDisplayShelfFloorSize",
      payload: { page: "inventoryPage" },
    })
  }, [dispatch, displayShelfFloorSize, isLG, isMD, page])

  useEffect(() => {
    setIsScrollingDown(false)
  }, [rawData])

  const hiddenReferences = useMemo(() => {
    if (
      suppliersIds === undefined &&
      familyName === undefined &&
      subFamilyName === undefined
    ) {
      return unfilteredAmount - filteredAmount
    }
    return searchedAmount - filteredAmount
  }, [
    familyName,
    filteredAmount,
    searchedAmount,
    subFamilyName,
    suppliersIds,
    unfilteredAmount,
  ])
  const mercurialeDate = rawData?.[0]?.date_integration

  return (
    <div className="h-svh mh:h-screen relative bg-gray-40 overflow-hidden flex flex-col">
      <InventoryModals
        isLoading={loading}
        saveDataModalState={saveDataModalState}
        isOfflineRefreshModalOpen={isOfflineRefreshModalOpen}
        isDataSynchronizeModalOpen={isDataSynchronizeModalOpen}
        isRefreshModalOpen={isRefreshModalOpen}
        isOrderReceptionDateModalOpen={isOrderReceptionDateModalOpen}
        setSaveDataModalState={setSaveDataModalState}
        setIsOfflineRefreshModalOpen={setIsOfflineRefreshModalOpen}
        setIsDataSynchronizeModalOpen={setIsDataSynchronizeModalOpen}
        setIsRefreshModalOpen={setIsRefreshModalOpen}
        setIsOrderReceptionDateModalOpen={setIsOrderReceptionDateModalOpen}
        updateInventory={updateInventory}
      />
      <InventoryNav
        page={page}
        isLoading={synchronisationLoading}
        onRefreshButtonClick={() => setIsOfflineRefreshModalOpen(true)}
        setSaveDataModalState={setSaveDataModalState}
      />
      <main className="flex-1 h-full flex flex-col relative z-0">
        {/* Page title & actions */}
        <div className="flex flex-1 justify-between h-full relative">
          {/* Left Column */}
          <div className="flex-1 flex flex-col lg:gap-1 relative">
            <div
              className={`absolute z-20 top-0 left-0 w-full origin-top bg-gray-40 flex items-center p-2 xl:px-4 ${!isMD && isScrollingDown ? "scale-y-0" : "scale-y-100"} transition-all duration-500`}
            >
              <div className="w-fit mr-2">
                <FilterSelect page={page} />
              </div>
              <div className="flex-1 mr-2">
                <Input
                  name="search"
                  type="text"
                  placeholder={`Rechercher${familyName !== undefined && subFamilyName === undefined && familyName !== "autres" ? ` parmi "${familyName}"` : ""}${subFamilyName !== undefined ? ` parmi "${subFamilyName}"` : ""}`}
                  icon={<SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />}
                  value={searchTerm}
                  onChange={handleSearchTerm}
                />
              </div>
              <p className="hidden lg:inline w-[100px] text-[#323232] text-xs font-bold">
                RÉSERVE
              </p>
              <p className="hidden lg:inline w-[90px] text-[#323232] text-xs font-bold">
                RAYON
              </p>
              <p className="hidden lg:inline w-[120px] text-[#323232] text-xs font-bold">
                TOTAL
              </p>
              <p className="hidden lg:inline text-[#323232] text-xs font-bold mr-[50px]">
                COMMANDE
              </p>
            </div>
            <div className="h-full w-full">
              {loading ? (
                <NewLoading />
              ) : (
                <InventoryTable
                  ref={virtuoso}
                  onScroll={(e) => {
                    const target = e.target as HTMLElement
                    if (
                      target.scrollHeight -
                        target.scrollTop -
                        target.clientHeight <
                        100 ||
                      target.scrollTop < 100
                    )
                      return
                    setIsScrollingDown(oldScrollTop.current < target.scrollTop)
                    oldScrollTop.current = target.scrollTop
                  }}
                  totalCount={dataLength}
                  header={() => <div className="h-14 lg:h-16" />}
                  footer={() => (
                    <div className="flex justify-center pt-2 pb-40 md:pb-12">
                      {hiddenReferences > 0 && (
                        <button
                          className="underline"
                          onClick={() => {
                            if (
                              suppliersIds === undefined &&
                              familyName === undefined &&
                              subFamilyName === undefined
                            ) {
                              dispatch({
                                type: "setSearchBar",
                                payload: {
                                  page: "inventoryPage",
                                  searchTerm: "",
                                },
                              })
                              return
                            }
                            dispatch({
                              type: "setFilters",
                              payload: {
                                page: "inventoryPage",
                                supplierId: undefined,
                                familyName: undefined,
                                subFamilyName: undefined,
                              },
                            })
                          }}
                        >
                          {hiddenReferences} référence
                          {hiddenReferences > 1 ? "s" : ""} cachée
                          {hiddenReferences > 1 ? "s" : ""} par les filtres
                          actuels
                        </button>
                      )}
                    </div>
                  )}
                  itemContent={rowContent}
                />
              )}
            </div>
          </div>
          {/* Right column */}
          <div className="hidden md:block w-[280px] h-full relative">
            <Sidebar
              hideWeather={(isMD && height < 750) || (isLG && height < 800)}
              updateInventory={updateInventory}
              setIsOrderReceptionDateModalOpen={
                setIsOrderReceptionDateModalOpen
              }
              className="mt-2 mr-2 gap-2"
              companyName={companyName ?? ""}
              previousOrderInventoryComputeDate={
                mercurialAndStoreInventories.find(
                  (mercurialeInfo) =>
                    typeof mercurialeInfo.previous_order_inventory_compute_date ===
                    "string",
                )?.previous_order_inventory_compute_date ?? undefined
              }
            />
          </div>
        </div>
        <section className="absolute bottom-0 z-20 w-full md:w-[calc(100%-280px)] pointer-events-none">
          <div className="flex justify-end p-2">
            <Button
              className="w-10 h-10 lg:w-8 lg:h-8 rounded-full p-0 bg-white hover:bg-slate-100 shadow text-green-500 pointer-events-auto"
              onClick={() => {
                virtuoso.current?.scrollTo({ top: 0 })
                setIsScrollingDown(false)
              }}
            >
              <ChevronUpIcon className="w-6 h-6 lg:w-5 lg:h-5" />
            </Button>
          </div>
          {!isMD && !loading && (
            <MobileInventoryRecap
              mercurialeDate={mercurialeDate ?? undefined}
              isLoading={synchronisationLoading}
              hide={isScrollingDown}
              setSaveDataModalState={setSaveDataModalState}
            />
          )}
        </section>
      </main>
    </div>
  )
}
