import { captureException } from "@sentry/react"
import {
  Exact,
  InventoryType,
  LogsModificationUpdateMutation,
  ModificationLogsInput,
  StoreSettings,
} from "./__generated__/graphql"
import {
  AllMercurialInfo,
  MercurialReducerState,
  UpdatedReference,
} from "../reducers/mercurialReducer"
import { convertInventoryTypeToEventType } from "./convertEnum"
import { getOrderQuantity } from "./getOrderQuantity"
import { FetchResult, MutationFunctionOptions } from "@apollo/client"
import { DispatchActionType } from "../types"

function getQuantity(
  inventoryType: InventoryType,
  mercurialeInfo: AllMercurialInfo,
  updatedReference?: UpdatedReference,
): number {
  switch (inventoryType) {
    case InventoryType.Back:
      return (
        updatedReference?.backInventoryQuantity ??
        mercurialeInfo.back_inventory_qty ??
        0
      )
    case InventoryType.Floor:
      return (
        updatedReference?.floorInventoryQuantity ??
        mercurialeInfo.floor_inventory_qty ??
        0
      )
    case InventoryType.Shelf:
      return (
        updatedReference?.shelfFloorSize ?? mercurialeInfo.shelf_floor_size ?? 0
      )
    case InventoryType.Order:
      return (
        updatedReference?.orderInventoryQuantity ??
        mercurialeInfo.quantity_actual ??
        0
      )
  }
}

interface computeModificationObjectParams {
  inventoryType: InventoryType
  modifiedValue: number
  mercurialeInfo: AllMercurialInfo
  updatedReference?: UpdatedReference
}

export function computeModificationObject({
  inventoryType,
  mercurialeInfo,
  updatedReference,
  modifiedValue,
}: computeModificationObjectParams): MercurialReducerState["modifications"][number] {
  const previousValue = getQuantity(
    inventoryType,
    mercurialeInfo,
    updatedReference,
  )

  return {
    event_type: convertInventoryTypeToEventType(inventoryType),
    colisage: mercurialeInfo.colisage ?? 1,
    mercuriale_id: mercurialeInfo.mercuriale_id!,
    modification_extra_info:
      inventoryType === InventoryType.Order
        ? {
            quantity_predicted: getOrderQuantity({
              backQuantity:
                updatedReference?.backInventoryQuantity ??
                mercurialeInfo.back_inventory_qty ??
                0,
              floorQuantity:
                updatedReference?.floorInventoryQuantity ??
                mercurialeInfo.floor_inventory_qty ??
                0,
              predictedQuantityArray:
                mercurialeInfo.quantity_predicted_array ?? [],
            }),
            quantity_predicted_array:
              mercurialeInfo.quantity_predicted_array ?? [],
            back_inventory_qty:
              updatedReference?.backInventoryQuantity ??
              mercurialeInfo.back_inventory_qty ??
              0,
            floor_inventory_qty:
              updatedReference?.floorInventoryQuantity ??
              mercurialeInfo.floor_inventory_qty ??
              0,
          }
        : undefined,
    modified_value: modifiedValue,
    previous_value: previousValue,
    order_id: mercurialeInfo.order_id ?? "",
    sale_id: mercurialeInfo.sale_id ?? "",
    user_updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
  }
}

export interface sendLogsModificationUpdateParams {
  inventoryType: InventoryType
  modifiedValue: number
  mercurialeInfo: AllMercurialInfo
  updatedReference: UpdatedReference
  storeSettings: StoreSettings
  storeId: string
  logsModificationUpdate: (
    options?:
      | MutationFunctionOptions<
          LogsModificationUpdateMutation,
          Exact<{
            input: ModificationLogsInput
          }>
        >
      | undefined,
  ) => Promise<FetchResult<LogsModificationUpdateMutation>>
  dispatch: DispatchActionType
}

export async function sendLogsModificationUpdate({
  inventoryType,
  modifiedValue,
  mercurialeInfo,
  updatedReference,
  storeId,
  logsModificationUpdate,
  dispatch,
}: sendLogsModificationUpdateParams): Promise<void> {
  if (typeof mercurialeInfo.mercuriale_id !== "string") return

  const modification = computeModificationObject({
    inventoryType,
    mercurialeInfo,
    updatedReference,
    modifiedValue,
  })

  if (!modification) return

  try {
    const logsModificationResult = await logsModificationUpdate({
      variables: {
        input: {
          modifications_logs_items: [modification],
          store_id: storeId,
        },
      },
    })
    if (logsModificationResult.data?.logsModificationUpdate?.error !== null) {
      throw logsModificationResult.data?.logsModificationUpdate?.error
    }
  } catch (error) {
    console.error(error)
    const errorMesssage =
      error instanceof Error ? error.message : "Données non sauvegardées"
    captureException(new Error(errorMesssage))
    dispatch({
      type: "addModification",
      payload: modification,
    })
  }
}
