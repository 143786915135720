import { StateType } from "@/types"
import {
  Promotions,
  useGetEngagementPromoQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { EngagementPromoHeader } from "./components/EngagementPromoHeader"
import { EngagementPromoDateSelector } from "./components/EngagagementPromoDateSelector"
import { Button } from "@/components/ui/button"
import { ArrowUp } from "lucide-react"
import { EngagementPromoContent } from "./components/EngagementPromoContent"
import { Spinner } from "@/ui/Spinner"

export interface GroupedPromotion {
  operation_name: string
  start_date: string
  end_date: string
  promotions: Promotions[]
}

export function EngagementPromoPage() {
  const { storeId } = useSelector((state: StateType) => state.storeReducer)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const { data, loading } = useGetEngagementPromoQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })

  const groupedPromotions = useMemo(() => {
    return (
      data?.getEngagementPromo.promotions?.reduce<GroupedPromotion[]>(
        (groupedPromotions, promotion) => {
          const foundPromotionIndex = groupedPromotions.findIndex(
            (groupedPromotion) =>
              groupedPromotion.operation_name === promotion.operation_name &&
              groupedPromotion.start_date === promotion.start_date &&
              groupedPromotion.end_date === promotion.end_date,
          )
          if (foundPromotionIndex !== -1) {
            groupedPromotions[foundPromotionIndex].promotions.push(promotion)
            return groupedPromotions
          }

          groupedPromotions.push({
            operation_name: promotion.operation_name,
            start_date: promotion.start_date,
            end_date: promotion.end_date,
            promotions: [promotion],
          })
          return groupedPromotions
        },
        [],
      ) ?? []
    )
  }, [data])

  return (
    <div className="h-screen flex flex-col gap-2 md:gap-7">
      <EngagementPromoHeader isTestMode={isTestMode} />
      {/* Engagements promo is loading */}
      {loading && (
        <div className="w-full flex-1 flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {/* No data found */}
      {!loading && groupedPromotions.length === 0 && (
        <p className="text-center">
          Aucune promotion n&apos;est disponible pour le moment
        </p>
      )}
      {/* Engagements promo content */}
      {!loading && groupedPromotions.length > 0 && (
        <>
          <EngagementPromoDateSelector groupedPromotions={groupedPromotions} />
          <EngagementPromoContent groupedPromotions={groupedPromotions} />
        </>
      )}
      <Button
        className="fixed z-10 bottom-2 right-2 size-10 p-0"
        variant="outline"
        asChild
      >
        <a href="#0" className="flex-1">
          <ArrowUp className="size-4" />
        </a>
      </Button>
    </div>
  )
}
