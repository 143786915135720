import { useMemo } from "react"
import { useGetStoresQuery } from "../../utils/__generated__/graphql"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import { Link } from "react-router-dom"
import MinimalLogo from "../../assets/MinimalLogo"
import { usePermissions } from "../../hooks/usePermissions"
import { CalendarIcon, ChartAreaIcon } from "lucide-react"

interface Company {
  id: string
  name: string
  franchise?: string | null
  storesNbr: number
}

export function CompaniesPage(): JSX.Element {
  const { data, loading } = useGetStoresQuery()
  const { isIdaAdmin } = usePermissions()

  const companies = useMemo<Company[]>(() => {
    return (
      data?.getStores?.stores?.reduce<Company[]>((companies, store) => {
        const companyIndex = companies.findIndex(
          (company) =>
            company.id === store.company_id &&
            company.franchise === store.franchise,
        )
        if (companyIndex === -1) {
          const company: Company = {
            id: store.company_id,
            name: store.company_name,
            franchise: store.franchise,
            storesNbr: 1,
          }
          return [...companies, company]
        }
        companies[companyIndex].storesNbr += 1
        return [...companies]
      }, []) ?? []
    )
  }, [data?.getStores?.stores])

  return (
    <div className="flex flex-col gap-4 py-4 px-2 flex-1">
      <h1 className="text-2xl font-black mb-2">Entreprises</h1>
      {!loading
        ? companies.map((company, i) => (
            <div
              key={i}
              className="w-full bg-white rounded-md shadow p-4 flex flex-col md:flex-row gap-2 justify-between md:items-center"
            >
              <div className="flex flex-col md:flex-row items-center gap-4">
                <div>
                  <MinimalLogo
                    companyId={company.id}
                    franchise={company.franchise}
                    className="w-12 lg:w-12"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h2 className="font-bold text-xl uppercase">
                    {company.franchise ?? company.name}
                  </h2>
                  {isIdaAdmin && (
                    <p className="text-gray-400 text-sm">{company.id}</p>
                  )}
                  <p>
                    <span className="font-bold">{company.storesNbr}</span>{" "}
                    magasin{company.storesNbr > 1 && "s"}
                  </p>
                </div>
              </div>
              <div className="flex justify-end items-center gap-x-4">
                <Link
                  className="w-full md:w-14 h-14 bg-green-500 hover:bg-green-600 transition text-white flex items-center justify-center rounded-md"
                  to={`${company.id}/performances${typeof company.franchise === "string" ? `?franchise=${company.franchise}` : ""}`}
                >
                  <ChartAreaIcon />
                </Link>
                <Link
                  className="w-full md:w-14 h-14 bg-green-500 hover:bg-green-600 transition text-white flex items-center justify-center rounded-md"
                  to={`${company.id}/store-scheduler${typeof company.franchise === "string" ? `?franchise=${company.franchise}` : ""}`}
                >
                  <CalendarIcon />
                </Link>
                <Link
                  className="w-full md:w-14 h-14 bg-green-500 hover:bg-green-600 transition text-white flex items-center justify-center rounded-md"
                  to={`${company.id}${typeof company.franchise === "string" ? `?franchise=${company.franchise}` : ""}`}
                >
                  <ArrowRightIcon />
                </Link>
              </div>
            </div>
          ))
        : new Array(3)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className="w-full h-32 bg-gray-200 animate-pulse rounded-md"
              />
            ))}
    </div>
  )
}
