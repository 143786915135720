interface FlaggedRefGroupProps {
  header: React.ReactNode
  children: React.ReactNode
  backgroundColor?: string
}

export const FlaggedRefGroup = ({
  header,
  children,
  backgroundColor = "bg-gray-100",
}: FlaggedRefGroupProps) => {
  return (
    <div
      className={`${backgroundColor} flex flex-col gap-4 rounded text-left p-4 lg:mr-12`}
    >
      <div>{header}</div>
      <div className="flex flex-col gap-2 lg:flex-row lg:flex-wrap">
        {children}
      </div>
    </div>
  )
}
