import { ProductImgAndName } from "@/components/molecules/productImgAndName"

import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { getOrderQuantity } from "@/utils/getOrderQuantity"

import { Card } from "@/components/ui/card"
import { ProductQuantitySelector } from "@/components/organisms/productQuantitySelector"
import { CircleCheck, TriangleAlert } from "lucide-react"

interface ProductRowProps {
  product: AllMercurialInfo
  isFix: boolean
}

export const ProductCard = ({ product, isFix }: ProductRowProps) => {
  const orderQuantity = getOrderQuantity({
    backQuantity: product.back_inventory_qty ?? 0,
    floorQuantity: product.floor_inventory_qty ?? 0,
    predictedQuantityArray: product.quantity_predicted_array ?? [],
  })

  const borderColor = isFix ? "border-red-400" : "border-green-400"
  return (
    <Card
      className={`flex flex-col w-full md:max-w-[350px] justify-between items-start p-2 gap-4 ${borderColor}`}
    >
      <div className="flex w-full items-center justify-between">
        <div className="max-w-64">
          <ProductImgAndName product={product} isOnline={true} />
        </div>
        {isFix ? (
          <TriangleAlert className="w-6 h-6  text-red-400" />
        ) : (
          <CircleCheck className="w-6 h-6 text-green-400" />
        )}
      </div>

      <div className="flex flex-row items-start gap-6 md:gap-6">
        <div className="flex flex-col gap items-start">
          <p className="text-gray-600 text-sm sm:text-sm">Votre commande</p>
          <ProductQuantitySelector product={product} />
        </div>
        <div className="flex flex-col items-start ">
          <p className="text-gray-600 text-sm sm:text-sm flex">Commande Ida</p>
          <p className="text-black text-base sm:text-base font-bold truncate">
            {orderQuantity} cs
          </p>
        </div>
      </div>
    </Card>
  )
}
