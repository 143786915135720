import { ProductQuantitySelector } from "@/components/organisms/productQuantitySelector"
import { Button } from "@/components/ui/button"
import {
  Drawer,
  DrawerContent,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"
import { Separator } from "@/components/ui/separator"

import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"
import { Trash2 } from "lucide-react"
import { Img } from "react-image"
import { useSelector } from "react-redux"

interface MobileTableRowProps {
  product: AllMercurialInfo
  online: boolean
  quantityActualInUnit: number
  handleDeleteProduct: (product: AllMercurialInfo) => void
  storeCurrency: string | null
  orderId: string | null
  isOrderConfirmed: boolean
}

export const MobileTableRow = ({
  product,
  online,
  handleDeleteProduct,
  orderId,
  isOrderConfirmed,
}: MobileTableRowProps) => {
  const { companyId } = useSelector((state: StateType) => state.storeReducer)

  const deleteProduct = () => {
    handleDeleteProduct(product)
  }

  return (
    <>
      <div className="flex items-center gap-4">
        {online && product?.photo_id ? (
          <Img
            className="w-16 h-16 rounded object-cover bg-white border border-gray-300"
            src={`https://storage.googleapis.com/references_images/${companyId}/${product.photo_id}.jpeg`}
            alt={product?.mercuriale_name ?? ""}
            unloader={
              <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
            }
          />
        ) : (
          <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
        )}

        <div className="flex flex-col justify-between gap-1 w-full">
          <div className="flex justify-between w-full">
            <p className="flex flex-col items-start">
              <span
                className={`text-left text-md sm:text-sm md:text-md uppercase text-black font-bold`}
              >
                {product?.mercuriale_name ?? ""}
              </span>
            </p>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            {orderId === null && !isOrderConfirmed ? (
              <ProductQuantitySelector
                product={product}
                disabled={!(orderId === null && !isOrderConfirmed)}
              />
            ) : (
              <>
                <p>{product.quantity_actual} cs</p>
              </>
            )}
            <Drawer>
              {orderId === null && !isOrderConfirmed && (
                <DrawerTrigger asChild>
                  <Trash2 className="cursor-pointer" />
                </DrawerTrigger>
              )}
              <DrawerContent aria-describedby={undefined}>
                <div className="mx-auto w-full max-w-sm">
                  <DrawerTitle className="text-lg font-bold text-center py-4">
                    Supprimer l&apos;article ?
                  </DrawerTitle>
                  <Separator />
                  <div className="flex flex-col gap-2 py-4">
                    <p className="mb-4">
                      Voulez-vous vraiment retirer {product.mercuriale_name} de
                      votre commander ?
                    </p>
                    <DrawerTrigger className="w-full">
                      <Button
                        onClick={deleteProduct}
                        className="bg-red-600 hover:bg-red-700 w-full"
                      >
                        Supprimer
                      </Button>
                    </DrawerTrigger>
                    <DrawerTrigger className="w-full">
                      <Button className="w-full" variant="outline">
                        Annuler
                      </Button>
                    </DrawerTrigger>
                  </div>
                </div>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  )
}
