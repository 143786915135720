import { getUnit } from "@/utils/getUnit"
import { toFixedNumber } from "@/utils/toFixedNumber"
import { twMerge } from "tailwind-merge"

interface EquivalentInventoryProps {
  value: number
  colisage: number
  unit: string
  useKgPce: boolean
  className?: string
  toVerify?: boolean
}

export function EquivalentInventory({
  value,
  colisage,
  unit,
  useKgPce,
  className,
  toVerify = false,
}: EquivalentInventoryProps) {
  const mergedClassName = twMerge("font-black text-sm", className)
  const computedValue = toFixedNumber(value ?? 0)

  if (value < 0 || toVerify) {
    return <p className={mergedClassName}>-</p>
  }

  const displayValue = useKgPce
    ? toFixedNumber(computedValue / colisage)
    : getUnit(unit) === "pce"
      ? Math.round(computedValue * colisage)
      : toFixedNumber(computedValue * colisage)

  const unitLabel = useKgPce ? "CS" : getUnit(unit)

  return (
    <p className={mergedClassName}>
      {displayValue} {unitLabel}
    </p>
  )
}
