import { Button } from "@/components/ui/button"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"
import { XIcon } from "lucide-react"
import { EngagementQuantityInput } from "./EngagementQuantityInput"
import { Promotions } from "@/utils/__generated__/graphql"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { twJoin } from "tailwind-merge"

interface EngagementPromoDrawerProps {
  storeId: string
  promotion: Promotions
  isTestMode: boolean
  dates: Date[]
}

export function EngagementPromoDrawer({
  storeId,
  promotion,
  isTestMode,
  dates,
}: EngagementPromoDrawerProps) {
  const todayDate = new Date()

  return (
    <Drawer direction="right">
      <DrawerTrigger asChild>
        <Button className="w-full bg-green-800 hover:bg-green-900">
          Engagement
        </Button>
      </DrawerTrigger>
      <DrawerContent
        className="h-screen top-0 right-0 left-auto mt-0 w-[340px] rounded-none"
        showDrag={false}
        aria-describedby={undefined}
      >
        <DrawerHeader className="flex justify-between items-center">
          <DrawerTitle>Répartir votre engagement</DrawerTitle>
          <DrawerClose>
            <XIcon className="size-4" />
          </DrawerClose>
        </DrawerHeader>
        <p className="text-center px-1 py-2">{promotion.order_name}</p>
        <div className="flex flex-col items-center gap-8 p-4 overflow-y-auto">
          {dates.map((date, i) => {
            const engagement = promotion.engagements.find(
              (engagement) =>
                getDiffBetweenDates(new Date(engagement.date), date) === 0,
            )

            const isOldDate = getDiffBetweenDates(todayDate, new Date(date)) < 0

            return (
              <div
                key={i}
                className="flex flex-col items-center gap-4 pb-8 border-b last:border-b-0"
              >
                <p className={twJoin(isOldDate && "text-gray-500")}>
                  {Intl.DateTimeFormat("fr-FR", {
                    weekday: "long",
                  }).format(new Date(date))}{" "}
                  {Intl.DateTimeFormat("fr-FR").format(new Date(date))}
                </p>
                <EngagementQuantityInput
                  storeId={storeId}
                  dimEngagementPromoId={promotion.id}
                  factEngagementPromoId={engagement?.id}
                  date={date}
                  engagementQuantity={engagement?.quantity ?? 0}
                  isTestMode={isTestMode}
                  disabled={isOldDate}
                />
              </div>
            )
          })}
        </div>
      </DrawerContent>
    </Drawer>
  )
}
