export interface RedirectReducerState {
  path: string | undefined
}

export const defaultRedirectReducerState: RedirectReducerState = {
  path: undefined,
}

interface SetRedirectAction {
  type: "setRedirect"
  payload: RedirectReducerState
}

interface SetRedirectPathAction {
  type: "setRedirectPath"
  payload: RedirectReducerState["path"]
}

export type RedirectReducerAction = SetRedirectAction | SetRedirectPathAction

const redirectReducer = (
  state: RedirectReducerState = defaultRedirectReducerState,
  action: RedirectReducerAction,
): RedirectReducerState => {
  switch (action.type) {
    case "setRedirect":
      return {
        ...state,
        ...action.payload,
      }
    case "setRedirectPath":
      return {
        ...state,
        path: action.payload,
      }
    default:
      return state
  }
}

export default redirectReducer
